import { Popover } from "@headlessui/react";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import { sumBy } from "lodash";

export default function ExperiencePoint({ id, dataXp = null, className = null }) {
  const { internalAPIClient } = useAuthenticatedHttpClient();

  const [levelStatus, setLevelStatus] = useState();
  const [xp, setXp] = useState();
  const [totalJoin, setTotalJoin] = useState(0);
  const [totalWin, setTotalWin] = useState(0);

  useEffect(() => {
    if (id) {
      if (id && dataXp && 0) {
        const amount = sumBy(dataXp, "amount");
        setXp(amount);
        setTotalJoin(dataXp.length);
        setLevelStatus(calculateLevel(amount));
      } else {
        internalAPIClient
          .get(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/experience-point/${id}`)
          .then((res) => {
            const amount = sumBy(res?.data?.data, "amount");
            setXp(amount);
            setTotalJoin(_.uniqBy(res?.data?.data, (x) => x.attr_1).length); 
            setTotalWin(_.filter(res?.data?.data, (x) => x.attr_3 === "FINALIST").length);
            setLevelStatus(calculateLevel(amount));
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [id, dataXp]);

  const calculateLevel = (totalXp) => {
    let currentLevel = 1;
    let xpNeeded = 100; // xp needed to reach level 2
    let currentDifficulties = 100;

    // loop until xpNeeded is greater than totalXp
    while (xpNeeded <= totalXp) {
      currentLevel++; // increment current level
      currentDifficulties *= 1.3; // increase xp needed by 30% from previous level's difficulties
      xpNeeded += currentDifficulties;
    }

    // currentLevel = floor(log((totalXp-100)/100*1.3 + 2, 1.3)) + 1
    return { currentLevel, xpNeeded };
  };

  return (
    <Popover className={`relative ${className}`}>
      <Popover.Button
        as="div"
        className="w-full bg-white/80 backdrop-blur border p-2 rounded flex items-center gap-2 cursor-pointer hover:shadow-xl hover:-translate-y-px transition-all active:scale-95"
      >
        <div className="shrink-0">
          <div className="w-9 h-9 rounded-full border-4 border-primary-800 text-primary-800 font-cursive flex items-center justify-center text-xl">
            {levelStatus?.currentLevel}
          </div>
        </div>
        <div className="flex-1">
          <div className="relative">
            <div className="w-full h-5 rounded-full bg-gray-300 text-xs px-2 font-bold text-primary-800 shadow-inner">{xp} XP</div>
            <div
              className="w-2/3 h-5 rounded-full bg-primary-800 text-xs px-2 font-bold text-white absolute top-0 left-0 overflow-hidden"
              style={{ width: `${(xp / levelStatus?.xpNeeded) * 100}%` }}
            >
              {xp} XP
            </div>
          </div>
          <div className="text-xs font-medium mt-px">
            {Math.round(levelStatus?.xpNeeded - xp)} XP to <strong>Level {levelStatus?.currentLevel + 1}</strong>
          </div>
        </div>
      </Popover.Button>
      <Popover.Panel className="absolute top-[3.6rem] z-10 bg-white p-3 w-full rounded">
        <div className="grid grid-cols-2 gap-2">
          <div className="flex items-center justify-center gap-1">
            <Icon icon="material-symbols:play-shapes-outline-rounded" className="w-4 h-5" />
            <h6 className="text-sm">{totalJoin} Joined</h6>
          </div>
          <div className="flex items-center justify-center gap-1">
            <Icon icon="solar:medal-ribbons-star-linear" className="w-4 h-5" />
            <h6 className="text-sm">{totalWin} Win</h6>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
}
