import { Icon } from "@iconify/react";
import { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import User from "../partials/miawwhalla/User";

function LayoutMiawhalla(props) {
  return (
    <>
      <Toaster />
      {/* Show on mobile */}
      {/* <div className="fixed inset-0 bg-white z-50 flex justify-center items-center lg:hidden">
        <div className="text-center px-4">
          <Icon icon="akar-icons:circle-x" className="w-10 h-10 mx-auto text-red-400" />
          <h2 className="font-cursive text-3xl mt-4">Sorry</h2>
          <p>Miawwhalla is only accessible via desktop</p>
          <Link to={`${process.env.PUBLIC_URL}/`} className="px-4 py-2 ml-2 mr-4 mt-6 bg-primary-800 text-white rounded inline-block font-cursive text-sm">
            Back to home
          </Link>
        </div>
      </div> */}

      {/* Show on desktop */}
      <div
        className={`min-h-screen bg-cover bg-center overflow-hidden ${props?.className}`}
        style={{ backgroundImage: `${props?.className ? "none" : `url('${process.env.PUBLIC_URL}/images/miawwhalla/bg2.webp')`}` }}
      >
        <div className="container mx-auto mb-8 px-4 h-28">
          <div className="relative z-10 flex justify-between items-center pt-6">
            <User />
            <Link to={`${process.env.PUBLIC_URL}/miawwhalla`}>
              <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/logo.webp`} alt="Logo" className="h-12 lg:h-24 2xl:h-32" />
            </Link>
          </div>
        </div>

        {props.children}
      </div>
    </>
  );
}

export default LayoutMiawhalla;
