import LayoutMiawhalla from "../../layouts/LayoutMiawhalla";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import { claimKGearsReward, isClaimed } from "../../web3/eth/kgears";
import moment from "moment";
import toast from "react-hot-toast";
import LoadingSpinner from "../../partials/LoadingSpinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function MiawwhallaEventClaim() {
  const params = useParams();
  const navigate = useNavigate();
  const metamaskAddress = localStorage.getItem("metamask_address");
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState();
  const [endTime, setEndTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [amount, setAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [alreadyClaimed, setAlreadyClaimed] = useState(0);
  const [assetCollections, setAssetCollections] = useState([]);
  const [showSummary, setShowSummary] = useState(true);
  const [claimReward, setClaimReward] = useState();

  useEffect(async () => {
    const eventData = await internalAPIClient.get(`/missions/${params?.id}`).catch((err) => {
      console.log(err);
      toast.error("Error occured");
      return;
    });

    setEvent(eventData?.data?.data);
    const getStartTime = moment.utc(eventData?.data?.data?.start_time).diff(moment.utc(moment.utc().local()));
    const getEndTime = moment.utc(eventData?.data?.data?.end_time).diff(moment.utc(moment.utc().local()));
    setStartTime(getStartTime);
    setEndTime(getEndTime);

    // redirect to event list when current event is not started || not ended
    if (getStartTime > 0 || getEndTime > 0) {
      return navigate(`${process.env.PUBLIC_URL}/miawwhalla/event`);
    }

    const rewardClaim = await internalAPIClient.get(`/missions/${eventData?.data?.data.id}/reward-claim/${metamaskAddress}`).catch((err) => {
      console.log(err);
      return;
    });

    await isClaimed(metamaskAddress, rewardClaim?.data?.data[0]?.token_id).then((res) => {
      console.log("is claimed", res);
      setAlreadyClaimed(res);
    });
  }, []);

  useEffect(async () => {
    setIsLoading(true);
    if (event) {
      await internalAPIClient
        .get(`/missions/${event?.id}/assets/${metamaskAddress}`)
        .then((res) => {
          setAssetCollections(res?.data?.data);

          if (res?.data?.data?.event_datas) {
            if (res?.data?.data?.event_datas.filter((i) => i.join_status === "JOINED").length > 0) {
              // setAmount(res?.data?.data && res?.data?.data.filter((i) => i.join_status === "JOINED").length);
            } else {
              return navigate(`${process.env.PUBLIC_URL}/miawwhalla/event`);
            }
          } else {
            if (res?.data?.data && res?.data?.data.filter((i) => i.join_status === "JOINED").length > 0) {
              // setAmount(res?.data?.data && res?.data?.data.filter((i) => i.join_status === "JOINED").length);
            } else {
              return navigate(`${process.env.PUBLIC_URL}/miawwhalla/event`);
            }
          }

        })
        .catch((err) => {
          console.log(err);
        });

      await internalAPIClient
        .get(`/missions/${event?.id}/reward-claim/${metamaskAddress}`)
        .then((res) => {
          setClaimReward(res?.data?.data[0]);
          setAmount(res?.data?.data[0]?.amount);
          setMaxAmount(res?.data?.data[0]?.amount);
        })
        .catch((err) => {
          console.log(err);
        });

      setIsLoading(false);
    }
  }, [event]);

  const handleChangeAmount = (val) => {
    if (val === "plus") {
      if (amount < claimReward?.amount) {
        setAmount(amount + 1);
      }
    } else {
      if (amount > 1) {
        setAmount(amount - 1);
      }
    }
  };

  const handleClaimReward = async () => {
    toast.dismiss();
    if (amount < 1) {
      return toast.error("Please at least 1 mint");
    }

    setIsLoading(true);
    const claim = await claimKGearsReward(metamaskAddress, claimReward?.token_id, amount, maxAmount, claimReward?.signature)
      .then((r) => {
        toast.success("Success claim your rewards");
        setTimeout(() => {
          return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Failed claim your rewards");
      });

    setIsLoading(false);
  };

  const handleClaimRewardDisabled = (status) => {
    toast.dismiss();
    if (status == "notStarted") {
      toast.error("Claim not started");
    } else if (status == "claimed") {
      toast.error("Already Claimed");
    }
  };

  return (
    <LayoutMiawhalla>
      {isLoading && <LoadingSpinner />}

      <div className="relative max-w-2xl mx-auto px-6 2xl:max-w-4xl mt-12 2xl:mt-24">
        <Link className="absolute z-20 top-4 right-10 transition-all" to={`${process.env.PUBLIC_URL}/miawwhalla/event`}>
          <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/close.webp`} alt="" className="w-8 h-8 hover:scale-105" />
        </Link>

        <div className={`bg-white/80 backdrop-blur-sm p-6 lg:p-12 rounded-xl ${showSummary ? "block" : "hidden"}`}>
          {assetCollections?.event_datas ? (
            <>
            {assetCollections?.event_datas?.filter((i) => i.disqualified_status === 0).length > 0 ? (
                <>
                  <div className="text-center font-cursive font-bold mb-12">
                    <h1 className="text-2xl">Congrats!</h1>
                    <p className="text-lg">Your Khuga Clear the Mission!</p>
                  </div>

                  <div className="h-80 overflow-auto mb-12">
                  {assetCollections?.event_squads.map((i, idx) => (
                    <div key={idx} className="mb-2">
                      <h2 className="font-bold text-black/60 font-cursive text-lg mb-1">{i.squad_name}</h2>
                      <div className="grid grid-cols-5 bg-black/10 p-2 md:p-3 rounded-md gap-3">
                        {i.squad_member.map((d) => (
                          <div className="relative" key={d.tokenId}>
                            {d.disqualified_status === 1 && (
                              <div className="w-full aspect-square absolute z-10 inset-0 backdrop-grayscale flex items-center justify-center">
                                <Icon icon="ph:x-light" className="w-full h-full text-red-500" />
                              </div>
                            )}
                            <img src={`https://khuga.io/khuga-preview/${d.tokenId}.webp`} alt="" className="w-full rounded-md" />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                  </div>

                  <button
                    onClick={() => setShowSummary(false)}
                    className="bg-[#439583] text-center p-3 text-lg rounded-md font-cursive block w-2/3 text-white mx-auto hover:contrast-125 hover:scale-[1.02] active:scale-[.98] transition-all"
                  >
                    <span>Claim Reward</span>
                  </button>
                </>
              ) : (
                <>
                  <div className="text-center font-cursive font-bold py-24">
                    <p className="text-lg mb-8">Seems like all your squad didn’t make it. See you in the next battle!</p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/miawwhalla`}
                      className="bg-[#439583] text-center p-3 text-lg rounded-md font-cursive block w-2/3 text-white mx-auto hover:contrast-125 hover:scale-[1.02] active:scale-[.98] transition-all"
                    >
                      Back to Home
                    </Link>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {assetCollections.filter((i) => i.disqualified_status === 0).length > 0 ? (
                <>
                  <div className="text-center font-cursive font-bold mb-12">
                    <h1 className="text-2xl">Congrats!</h1>
                    <p className="text-lg">Your Khuga Clear the Mission!</p>
                  </div>

                  <div className="h-52 overflow-auto mb-12">
                    {assetCollections.filter((i) => i.disqualified_status === 0).length <= 5 ? (
                      <div className="mt-12 flex gap-4 items-center justify-center">
                        {assetCollections
                          .filter((i) => i.disqualified_status === 0)
                          .map((i) => (
                            <div className="text-center group w-[20%] relative group overflow-hidden" key={i.id}>
                              <div className="w-full aspect-square overflow-hidden rounded-xl group-hover:shadow-xl transition-all">
                                <div className="w-full aspect-square rounded-xl mb-1 group-hover:scale-110 transition-all duration-300">
                                  <LazyLoadImage src={`https://khuga.io/khuga-preview/${i?.metadata.id}.webp`} alt="?" effect="blur" className="w-full" />
                                </div>
                              </div>
                              <div className="flex justify-center items-center mt-1">
                                <h3 className="text-xs text-black/80">
                                  <span>{i?.metadata.name}</span>
                                </h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className="grid grid-cols-3 md:grid-cols-5 gap-4 items-center justify-center">
                        {assetCollections
                          .filter((i) => i.disqualified_status === 0)
                          .map((i) => (
                            <div className="text-center group w-full relative group overflow-hidden" key={i.id}>
                              <div className="w-full aspect-square overflow-hidden rounded-xl group-hover:shadow-xl transition-all">
                                <div className="w-full aspect-square rounded-xl mb-1 group-hover:scale-110 transition-all duration-300">
                                  <LazyLoadImage src={`https://khuga.io/khuga-preview/${i?.metadata.id}.webp`} alt="?" effect="blur" className="w-full" />
                                </div>
                              </div>
                              <div className="flex justify-center items-center mt-1">
                                <h3 className="text-xs text-black/80">
                                  <span>{i?.metadata.name}</span>
                                </h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>

                  <button
                    onClick={() => setShowSummary(false)}
                    className="bg-[#439583] text-center p-3 text-lg rounded-md font-cursive block w-2/3 text-white mx-auto hover:contrast-125 hover:scale-[1.02] active:scale-[.98] transition-all"
                  >
                    <span>Claim Reward</span>
                  </button>
                </>
              ) : (
                <>
                  <div className="text-center font-cursive font-bold py-24">
                    <p className="text-lg mb-8">Seems like all your squad didn’t make it. See you in the next battle!</p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/miawwhalla`}
                      className="bg-[#439583] text-center p-3 text-lg rounded-md font-cursive block w-2/3 text-white mx-auto hover:contrast-125 hover:scale-[1.02] active:scale-[.98] transition-all"
                    >
                      Back to Home
                    </Link>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <div className={`bg-white/80 backdrop-blur-sm p-6 rounded-xl ${!showSummary ? "block" : "hidden"}`}>
          <h1 className="font-bold text-lg font-cursive text-center mb-4">
            <span className="text-2xl">Congrats!</span>
            <br />
            You've earned a K-Gear(s)!
          </h1>
          {/* <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/season1/sideq.webp`} alt="" className="w-1/2 h-auto mx-auto" /> */}
          <img src={event?.reward_image} alt="" className="w-1/2 h-auto mx-auto" />
          <div className="mb-6 text-center">
            <h2 className="font-cursive text-lg">{event?.reward_name}</h2>
            <p>{event?.reward_description}</p>
          </div>

          <div className="flex items-end gap-6">
            <div className="flex items-center justify-center">
              <div>
                <p className="text-sm mb-2">Total eligible rewards for minting</p>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleChangeAmount("minus")}
                    className="p-3 rounded-full bg-[#439583] text-white hover:scale-110 active:scale-100 transition-all"
                  >
                    <Icon icon="heroicons-outline:minus" />
                  </button>
                  <input type="text" value={amount} disabled className="w-full bg-gray-300 rounded cursor-not-allowed" />
                  <button
                    onClick={() => handleChangeAmount("plus")}
                    className="p-3 rounded-full bg-[#439583] text-white hover:scale-110 active:scale-100 transition-all"
                  >
                    <Icon icon="heroicons-outline:plus" />
                  </button>
                </div>
              </div>
            </div>
            {claimReward?.signature == null || alreadyClaimed ? (
              <button
                className="bg-[#439583] text-center p-3 text-lg rounded-md font-cursive block w-2/3 text-white mx-auto grayscale"
                onClick={() => handleClaimRewardDisabled(alreadyClaimed ? "claimed" : "notStarted")}
              >
                <span>Claim Reward</span>
              </button>
            ) : (
              <button
                onClick={() => handleClaimReward()}
                className="bg-[#439583] text-center p-3 text-lg rounded-md font-cursive block w-2/3 text-white mx-auto hover:contrast-125 hover:scale-[1.02] active:scale-[.98] transition-all"
              >
                <span>Claim Reward</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </LayoutMiawhalla>
  );
}

export default MiawwhallaEventClaim;
