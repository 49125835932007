import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Transaction, SystemProgram, LAMPORTS_PER_SOL, sendAndConfirmTransaction, PublicKey, PublicKeyInitData } from "@solana/web3.js";
import { ConnectionContextState, WalletContextState } from "@solana/wallet-adapter-react";


/**
 * 
 * @brief trigger SOL transfer with wallet
 * @param { ConnectionContextState } connection
 * @param { WalletContextState } wallet 
 * @param { PublicKeyInitData } destAddress 
 * @param { number } amount in SOL
 */
const invokeTransfer = async ( connection, wallet, destAddress, amount) => {
    const latestBlockHash = await connection.connection.getLatestBlockhash();
    const transaction = new Transaction().add(
        SystemProgram.transfer({
            fromPubkey: wallet.publicKey,
            toPubkey: new PublicKey(destAddress),
            lamports: amount * LAMPORTS_PER_SOL
        })
    );
    transaction.recentBlockhash = latestBlockHash.blockhash;
    transaction.feePayer = wallet.publicKey;
    
    const signedTransaction = await wallet.signTransaction(transaction).catch((e) => {
        throw e;
    });

    const signature = await connection.connection.sendRawTransaction(signedTransaction.serialize()).catch((e) => {
        throw e;
    });


    const confirmation = await connection.connection.confirmTransaction({
        blockhash: latestBlockHash.blockhash,
        lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        signature
    }).catch((e) => {
        throw e;
    });

    return {
        confirmation,
        signature
    };
}

export const useKhugaSolanaTransaction = () => {
    const connection = useConnection();
    const wallet = useWallet();

    return {
        invokeTransfer: async ( destAddress, amount ) => invokeTransfer(connection, wallet, destAddress, amount)
    }
}