import { BeaconWallet } from "@taquito/beacon-wallet";
import { TezosToolkit } from "@taquito/taquito";

/**
 * 
 * @param { TezosToolkit } TezosInstance 
 * @param { BeaconWallet } TezosWallet 
 */
const getOwnedNfts = async (TezosInstance, TezosWallet) => {
    const walletAddress = TezosWallet.pkh || (await TezosWallet.getPKH());
    // const walletAddress = "tz1UCn9JD1ZdiP6npnBPRLABbwRHNu4fkT9U";
    let queryParams = [];

    for (let i=0; i < process.env.REACT_APP_MIU_NFT_TOKEN_COUNT; i++){
        queryParams.push({owner: walletAddress, token_id: i.toString()});
    }

    const miuContract = await TezosInstance.wallet.at('KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5')
    const contractResp = await miuContract.views.balance_of(queryParams).read();

    let nftOwned = contractResp.map((item) => {
        if (item['balance'].toString() === "1"){
            return parseInt(item['request']['token_id']);
        }
    }).filter(x => x);

    return nftOwned;
}

export default function ({ TezosInstance, TezosWallet}) {
    return {
        getOwnedNfts: () => getOwnedNfts(TezosInstance, TezosWallet)
    };
}