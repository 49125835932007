import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import SideCart from "../partials/cart/SideCart";

function LayoutBase(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sideCartOpen, setSideCartOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* SideCart */}
      <SideCart sideCartOpen={sideCartOpen} setSideCartOpen={setSideCartOpen} />

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} onCartClicked={() => setSideCartOpen(!sideCartOpen)} />

        <main>{props.children}</main>
      </div>
    </div>
  );
}

export default LayoutBase;
