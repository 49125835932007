import toast, { Toaster } from "react-hot-toast";
import { useWallet } from "@solana/wallet-adapter-react";
import LayoutBase from "../layouts/LayoutBase";
import { useKhugaSolanaTransaction } from "../web3/solana/transaction";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useState } from "react";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";

function TransactionConfirm() {
  const wallet = useWallet();
  const { id } = useParams();
  const { invokeTransfer } = useKhugaSolanaTransaction();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [transaction, setTransaction] = useState();

  useEffect(async () => {
    await internalAPIClient
      .get(`/transactions/${id}`)
      .then((res) => {
        setTransaction(res?.data?.data);
      })
      .catch(async (e) => {
        console.log(e);
      });
  }, []);

  const handlePayment = async () => {
    const { confirmation, signature } = await invokeTransfer(
      transaction?.payment[0]?.payment_channel?.accounts[0]?.account_no,
      transaction?.payment[0]?.total_bill
    ).catch((e) => {
      toast.error("Error: " + e);
    });

    await internalAPIClient
      .post(`/web3/confirm-transaction-payment/${id}`, {
        transaction_hash: signature,
        wallet_address: transaction?.payment[0]?.payment_channel?.accounts[0]?.account_no,
        amount: transaction?.payment[0]?.total_bill,
      })
      .then((res) => {
        console.log(res?.data?.status);
        if (res?.data?.status == 'success') {
          toast.success('Payment Success!');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(async (e) => {
        toast.error("Error: " + e);
      });
  };

  const renderError = (message) => <p className="text-sm italic text-red-600">{message}</p>;

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-xl mb-4 font-cursive">Status: {transaction?.status}</h1>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-8">
            <div className="w-full bg-white p-4 md:p-6 rounded shadow">
              <h2 className="font-bold font-cursive text-lg mb-3">Personal Data</h2>
              <table className="table w-full border mb-12">
                <tbody>
                  <tr>
                    <td className="px-3 py-1.5">Name</td>
                    <td className="font-bold">{transaction?.contact?.name}</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-1.5">Email</td>
                    <td className="font-bold">{transaction?.contact?.name}</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-1.5">Phone</td>
                    <td className="font-bold">{transaction?.contact?.phone}</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-1.5">Discord ID</td>
                    <td className="font-bold">{transaction?.contact?.discord_id}</td>
                  </tr>
                </tbody>
              </table>

              <h2 className="font-bold font-cursive text-lg mb-3">Shipping Address</h2>
              <table className="table w-full border">
                <tbody>
                  <tr>
                    <td className="px-3 py-1.5">Country</td>
                    <td className="font-bold">{transaction?.shipping?.country_name}</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-1.5">Province</td>
                    <td className="font-bold">{transaction?.shipping?.province_name}</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-1.5">City</td>
                    <td className="font-bold">{transaction?.shipping?.city_name}</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-1.5">Address</td>
                    <td className="font-bold">{transaction?.shipping?.address}</td>
                  </tr>
                  <tr>
                    <td className="px-3 py-1.5">Postal Code</td>
                    <td className="font-bold">{transaction?.shipping?.postal_code}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-span-4">
            <div className="bg-white rounded shadow-lg divide-y divide-gray-200">
              <div className="p-4">
                <h2 className="font-bold text-xl font-cursive">Order Summary</h2>
              </div>
              <div className="p-4 text-gray-500">
                {/* <div className="flex justify-between items-center">
                  <span>Subtotal</span>
                  <span>${cartTotal}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span>Shipping</span>
                  <span>-</span>
                </div>
                <hr className="my-4" /> */}
                <div className="flex justify-between items-center text-gray-600">
                  <span>Total</span>
                  <h6 className="font-bold text-xl">
                    {transaction?.payment[0]?.total_bill} {transaction?.payment[0]?.payment_channel_unit}
                  </h6>
                </div>
              </div>
              {transaction?.status == "Awaiting Payment" && (
                <button className="font-bold w-full py-3 bg-primary-700 text-white font-cursive hover:opacity-90" onClick={handlePayment}>
                  Pay Now
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default TransactionConfirm;
