import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../partials/LoadingSpinner";
import { TezosWallet } from "../web3/tezos";

function DiscordCallback() {
  const navigate = useNavigate();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pubKey, setPubKey] = useState();
  const [address, setAddress] = useState();

  useEffect(async () => {
    if (TezosWallet) {
      const wallet = await TezosWallet.client.getActiveAccount();
      setPubKey(wallet?.publicKey);
      setAddress(wallet?.address);
    }
  }, [TezosWallet]);

	useEffect(async () => {
    if (address !== undefined) {
      await internalAPIClient
        .post(`/whitelist-discord-callback/${address}?code=${searchParams.get("code")}`, {
          // assets: ownedNfts,
          // pubkey: pubKey,
          // signature: signature,
          // message: wallet?.publicKey.toString(),
        })
        .then((res) => {
          if (res?.data?.status == 'success' && res?.data?.data?.discord !== undefined) {
            localStorage.setItem("miu-discord", JSON.stringify(res?.data?.data?.discord));
          } else {
            toast.error("Error: " + res?.data?.message);
          }
          setTimeout(() => {
            return navigate(`${process.env.PUBLIC_URL}/miu`);
          }, 2000);
        })
        .catch((e) => {
          toast.error("Error: " + e);
        });
    }
  }, [address]);

  return (
    <>
      <LoadingSpinner />
      <Toaster />
    </>
  );
}

export default DiscordCallback;
