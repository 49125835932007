import toast, { Toaster } from "react-hot-toast";
import { Icon } from "@iconify/react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import LayoutHome from "../layouts/LayoutHome";
import ImageScroller from "../partials/ImageScroller";
import { WalletModalProvider, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { KhugaTezosUtils, KhugaTezosNft, TezosWallet } from "../web3/tezos";
import LoadingSpinner from "../partials/LoadingSpinner";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";

export default function MIUHolder() {
  const wallet = useWallet();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const { connectWallet, isConnected, signMessage } = KhugaTezosUtils;
  const { getOwnedNfts } = KhugaTezosNft;
  const [connected, setConnected] = useState(false);
  const [ownedNfts, setOwnedNfts] = useState([]);
  const [address, setAddress] = useState();
  const [pubKey, setPubKey] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [discord, setDiscord] = useState();
  const [discordAuthUrl, setDiscordAuthUrl] = useState();

  useEffect(async () => {
    const getDiscord = JSON.parse(localStorage.getItem("miu-discord"));
    if (getDiscord !== undefined && getDiscord !== null && getDiscord !== '') {
      setDiscord(getDiscord);
    }

    await internalAPIClient
      .get(`/whitelist-discord-url`)
      .then((res) => {
        setDiscordAuthUrl(res?.data?.data?.discordAuthUrl);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(async () => {
    if (TezosWallet) {
      const wallet = await TezosWallet.client.getActiveAccount();
      setAddress(wallet?.address);
      setPubKey(wallet?.publicKey);
    }
    isConnected().then((e) => {
      getOwnedNfts().then((n) => {
        if (n.length > 0) {
          setOwnedNfts(n);
        }
      });
      setConnected(e);
    });
  }, [isConnected]);

  const handleConnectTezos = async () => {
    setIsLoading(true);
    const connect = await connectWallet().catch(() => {
      window.location.reload();
    });
  };

  const handleWhitelistRequest = async () => {
    setIsLoading(true);
    const signature = await signMessage(wallet?.publicKey.toString());
    const request = await internalAPIClient
      .post(`/web3/tezos/register-whitelist`, {
        assets: ownedNfts,
        pubkey: pubKey,
        signature: signature,
        message: wallet?.publicKey.toString(),
      })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        return toast.success("Success! We have processed your whitelist submission");
      })
      .catch((e) => {
        setIsLoading(false);
        return toast.error("Error: " + e);
      });
  };

  function logOut() {
    if (TezosWallet) {
      TezosWallet.disconnect();
      TezosWallet.client.clearActiveAccount();
      window.location.reload();
    }
  }

  return (
    <LayoutHome>
      {isLoading && <LoadingSpinner />}

      <div className="max-w-xl min-h-screen px-4 mx-auto pt-40">
        <div className="">
          {!connected ? (
            <div>
              <div className="text-center mb-8">
                <p className="text-black/70 tracking-wider">Welcome</p>
                <h2 className="font-cursive text-4xl text-primary-800">MIU Holders!</h2>
              </div>
              <div className="relative hover:opacity-80 hover:-translate-y-1 transition-all !p-0 cursor-pointer" onClick={() => handleConnectTezos()}>
                <img src={`/images/button/connect-wallet.webp`} alt="Menu" className="h-20 mx-auto" />
                <span className="absolute top-0 w-full text-center mt-6 text-white font-cursive text-lg">Connect With Your Wallet</span>
              </div>
            </div>
          ) : (
            <div>
              <div className="border-2 border-dashed p-2">
                <div className="flex justify-between items-center">
                  <span>Wallet Address:</span>
                  <button onClick={logOut} className="text-red-500">
                    Disconnect
                  </button>
                </div>
                <h2 className="font-bold break-words">{address}</h2>
              </div>
              {wallet.connected && (
                <div className="border-2 border-dashed p-2">
                  <div className="flex justify-between items-center">
                    <span>Solana Address:</span>
                    <button onClick={wallet.disconnect} className="text-red-500">
                      Disconnect
                    </button>
                  </div>
                  <h2 className="font-bold break-words">{wallet.publicKey.toString()}</h2>
                </div>
              )}
              {discord && (
                <div className="border-2 border-dashed p-2">
                  <div className="flex justify-between items-center">
                    <span>Discord Username:</span>
                    <button onClick={() => {
                      setDiscord(null);
                      localStorage.removeItem("miu-discord")
                    }} className="text-red-500">
                      Disconnect
                    </button>
                  </div>
                  <h2 className="font-bold break-words">{discord?.username}</h2>
                </div>
              )}

              {ownedNfts.length > 0 ? (
                <div className="text-center my-12">
                  <Icon icon="icon-park-outline:success-picture" className="w-12 h-12 mx-auto mb-2" />
                  <p className="text-lg mb-4">You have {ownedNfts.length} MIU Collection</p>

                  {wallet.connected ? (
                    <div className="flex flex-col space-y-3">
                      <button className="bg-primary-800 px-4 py-2.5 rounded-full text-white font-semibold hover:contrast-150" onClick={handleWhitelistRequest}>
                        Submit Whitelist Request
                      </button>

                      {discordAuthUrl && !discord && (
                        <a className="bg-primary-800 px-4 py-2.5 rounded-full text-white font-semibold hover:contrast-150" href={discordAuthUrl ?? "/"}>
                          Discord
                        </a>
                      )}
                    </div>
                  ) : (
                    <WalletModalProvider>
                      <div className="flex justify-center items-center">
                        <WalletMultiButton className="relative hover:opacity-80 hover:shadow hover:-translate-y-1 transition-all !p-0">
                          <img src={`${process.env.PUBLIC_URL}/images/button/connect-wallet.webp`} alt="Menu" className="h-14" />
                          <span className="absolute top-0 w-full text-center mt-3 text-white font-cursive text-sm">Connect Solana Wallet</span>
                        </WalletMultiButton>
                      </div>
                    </WalletModalProvider>
                  )}
                </div>
              ) : (
                <div className="text-center my-12">
                  <img src="/images/icon/not-found.png" alt="Not Found" className="w-12 mx-auto mb-2" />
                  <p className="text-lg">Sorry you don't have MIU Collection</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="absolute bottom-1 left-0 right-0 space-y-1 overflow-hidden z-10">
        <div className="scroller flex space-x-1 whitespace-nowrap text-xs text-gray-300">
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/61/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/62/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/63/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/64/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/65/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/40/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/67/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/68/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/69/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/70/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/71/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/72/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/73/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/74/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/75/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/76/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/77/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/78/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/79/thumb288" />
          <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/80/thumb288" />
        </div>
        {!connected && (
          <div className="scroller flex space-x-1 whitespace-nowrap text-xs text-gray-300">
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/41/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/42/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/43/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/44/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/45/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/46/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/47/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/48/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/49/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/50/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/51/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/52/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/53/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/54/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/55/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/56/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/57/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/58/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/59/thumb288" />
            <ImageScroller src="https://assets.objkt.media/file/assets-003/KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5/60/thumb288" />
          </div>
        )}
      </div>

      <Toaster />
    </LayoutHome>
  );
}
