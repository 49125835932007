import LayoutMiawhalla from "../../layouts/LayoutMiawhalla";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import SelectCollection from "../../partials/miawwhalla/SelectCollection";
import IllustrationSeason1 from "../../partials/miawwhalla/illustration/Season1";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import Lottie from "react-lottie";
import "react-tooltip/dist/react-tooltip.css";
import SelectSquadCollection from "../../partials/miawwhalla/SelectSquadCollection";
import toast from "react-hot-toast";

function MiawwhallaEventDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const metamaskAddress = localStorage.getItem("metamask_address");
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [showModal, setShowModal] = useState(false);
  const [event, setEvent] = useState();
  const [isStarted, setIsStarted] = useState(false);
  const [assetCollections, setAssetCollections] = useState([]);
  const [khugaRequirements, setKhugaRequirements] = useState([]);

  useEffect(async () => {
    await internalAPIClient
      .get(`/missions/${params?.id}`)
      .then((res) => {
        if (res?.data?.status === "error") {
          toast.error(res?.data?.message);
          setTimeout(() => {
            return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
          }, 3000);
        }

        setKhugaRequirements(res?.data?.data?.requirements?.filter((o) => o.collection == null) ?? []);
        setEvent(res?.data?.data);
        const getTime = moment.utc(res?.data?.data?.start_time).diff(moment.utc(moment.utc().local()));
        setIsStarted(getTime < 0);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occured");
      });
  }, []);

  useEffect(async () => {
    if (event) {
      await internalAPIClient
        .get(`/missions/${event?.id}/assets/${metamaskAddress}`)
        .then((res) => {
          setAssetCollections(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [event]);

  return (
    <LayoutMiawhalla className="bg-[#221F1F]">
      <div className="relative container mx-auto px-4">
        <div className="absolute right-4 top-24 md:top-36 2xl:top-48">
          <Link to={`${process.env.PUBLIC_URL}/miawwhalla/event`}>
            <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/close.webp`} alt="" className="w-8 h-8 hover:scale-105" />
          </Link>
        </div>

        <div className="min-h-screen -mt-36 grid grid-cols-1 lg:grid-cols-2 gap-6 items-center text-white">
          <div className="w-full h-full pt-48 md:pt-28 flex items-center">
            {event?.id === "595a361f-ae9c-45f8-af8f-05d8e6c1d343" ? (
              <IllustrationSeason1 />
            ) : (
              <>
                {event?.v_animation_url === null ? (
                  <img src={event?.background_image} className="w-full" />
                ) : (
                  <>
                    {event?.v_animation_url.split(".").pop() === "json" ? (
                      <Lottie
                        isClickToPauseDisabled={true}
                        options={{
                          loop: true,
                          autoplay: true,
                          // animationData: MotionE1,
                          path: event?.v_animation_url,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        style={{ width: `90%` }}
                        className="mx-auto"
                      />
                    ) : (
                      <img src={event?.v_animation_url} className="w-[90%] mx-auto" />
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="w-full max-w-xl mx-auto py-12">
            <h1 className="md:mt-16 font-bold text-lg">{event?.name}</h1>
            <p className="md:mt-4">{event?.description}</p>

            <div className="p-4 rounded-lg bg-[#434143] my-8">
              <p className="mb-4">{event?.event_mechanism}</p>
              <span>Reward:</span>
              <div
                className="bg-white/50 w-24 h-24 rounded-md mt-1"
                data-tooltip-id={event?.reward_name || "reward"}
                data-tooltip-content={event?.reward_name || "null"}
              >
                <Tooltip id={event?.reward_name || "reward"} />
                <img src={event?.reward_asset_url} alt="" className="w-full rounded-md" />
              </div>

              {khugaRequirements.length > 0 && (
                <div className="mt-4">
                  <span>Requirements: </span>
                  {khugaRequirements.map((i) => (
                    <div key={i.id}>
                      <p className="">
                        <span className="font-bold">- {i?.token_attribute}</span> {i?.list !== null && `(${i.list})`}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="space-y-3">
              {event && (
                <>
                  {isStarted && assetCollections?.filter((i) => i.join_status === "JOINED").length < 1 ? (
                    <button className="bg-[#439583] p-3 text-lg rounded-md font-cursive block w-10/12 mx-auto grayscale cursor-not-allowed">
                      <span>View Squad</span>
                    </button>
                  ) : (
                    <button
                      onClick={() => setShowModal(true)}
                      className="bg-[#439583] p-3 text-lg rounded-md font-cursive block w-10/12 mx-auto hover:contrast-125 hover:scale-[1.02] active:scale-[.98] transition-all"
                    >
                      <span>{isStarted ? "View Squad" : "Setup My Squad"}</span>
                    </button>
                  )}
                </>
              )}

              {isStarted && (
                <Link
                  to={`${process.env.PUBLIC_URL}/miawwhalla/event/${event?.id}/battle`}
                  className="text-center bg-[#439583] p-3 text-lg rounded-md font-cursive block w-10/12 mx-auto hover:contrast-125 hover:scale-[1.02] active:scale-[.98] transition-all"
                >
                  <span>View Progress</span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="absolute inset-0 bg-black/50 backdrop-blur"></div>
          <div className="relative w-full max-w-6xl mx-auto px-0 md:px-4 h-screen md:h-[calc(100vh_-_20%)]">
            <button className="absolute z-20 top-4 right-4 md:right-8 transition-all" onClick={() => setShowModal(false)}>
              <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/close.webp`} alt="" className="w-8 h-8 hover:scale-105" />
            </button>
            <div className="bg-white/80 rounded-none md:rounded-lg border border-gray-600 h-full">
              {event?.mode === "squad" ? <SelectSquadCollection event={event} /> : <SelectCollection event={event} />}
            </div>
          </div>
        </div>
      )}
    </LayoutMiawhalla>
  );
}

export default MiawwhallaEventDetail;
