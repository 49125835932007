import { useCart } from "react-use-cart";
import { createContext, useState, useEffect } from "react";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";
import LayoutBase from "../layouts/LayoutBase";
import PersonalInformation from "../partials/checkout/PersonalInformation";
import ShippingAddress from "../partials/checkout/ShippingAddress";
import Step from "../partials/checkout/Step";
import Stepper from "../partials/checkout/Stepper";
import Review from "../partials/checkout/Review";

export const CheckoutContext = createContext();

function Checkout() {
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const { items, cartTotal } = useCart();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formData, setFormData] = useState({});

  useEffect(async () => {
    await internalAPIClient
      .get(`/transaction-payment-channels`)
      .then((res) => {
        setFormData({ ...formData, ...{ defaultPayment: res.data?.data[0] ?? "" } });
      })
      .catch(async (e) => {
        console.log("Error: " + e);
      });
  }, []);

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-cursive">Checkout</h1>
        <div className="grid grid-cols-12 gap-6">
          <CheckoutContext.Provider value={{ activeStepIndex, setActiveStepIndex, formData, setFormData }}>
            <div className="col-span-8 bg-white p-4 md:p-6 rounded shadow">
              <Stepper items={["Personal Information", "Shipping Address", "Review"]} />
              <hr className="my-6" />
              <Step items={[<PersonalInformation />, <ShippingAddress />, <Review />]} />
            </div>
          </CheckoutContext.Provider>

          <div className="col-span-4">
            <div className="bg-white rounded shadow-lg divide-y divide-gray-200">
              <div className="p-4">
                <h2 className="font-bold text-xl font-cursive">Order Summary</h2>
              </div>
              <div className="p-4 text-gray-500">
                {items.map((item, index) => (
                  <div key={index} className="flex justify-between items-center">
                    <span>
                      {item.quantity}x {item.name}
                    </span>
                    <span>{item.quantity * item.price * (formData?.defaultPayment?.rate ?? 1)} SOL</span>
                  </div>
                ))}
              </div>
              <div className="p-4 text-gray-500">
                <div className="flex justify-between items-center">
                  <span>Subtotal</span>
                  <span>{cartTotal * (formData?.defaultPayment?.rate ?? 1)} SOL</span>
                </div>
                <div className="flex justify-between items-center">
                  <span>Shipping</span>
                  <span>{(formData?.shippingCost ?? 0) * (formData?.defaultPayment?.rate ?? 1)} SOL</span>
                </div>
                <hr className="my-4" />
                <div className="flex justify-between items-center text-gray-600">
                  <span>Total</span>
                  <h6 className="font-bold text-xl">
                    {(parseFloat(cartTotal) + parseFloat(formData?.shippingCost ?? 0)) * (formData?.defaultPayment?.rate ?? 1)} SOL
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutBase>
  );
}

export default Checkout;
