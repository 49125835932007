import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LayoutBase from "../layouts/LayoutBase";
import Chips from "../partials/actions/Chip";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";

function Transactions() {
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [transactions, setTransactions] = useState([]);
  const [activeTab, setActiveTab] = useState("View All");

  useEffect(async () => {
    await internalAPIClient
      .get(`/transactions`)
      .then((res) => {
        setTransactions(res?.data?.data ?? []);
      })
      .catch(async (e) => {
        toast.error("Error: " + e);
      });
  }, []);

  console.log(transactions);

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-cursive">Transactions</h1>
        {/* Filter */}
        <div className="space-x-2 space-y-2 mb-4">
          <Chips title="View All" isActive={activeTab === "View All"} onClick={() => setActiveTab("View All")} />
          <Chips title="Awaiting Payment" isActive={activeTab === "Awaiting Payment"} onClick={() => setActiveTab("Awaiting Payment")} />
          <Chips title="Processing Order" isActive={activeTab === "Processing Order"} onClick={() => setActiveTab("Processing Order")} />
          <Chips title="On Delivery" isActive={activeTab === "On Delivery"} onClick={() => setActiveTab("On Delivery")} />
          <Chips title="Done" isActive={activeTab === "Done"} onClick={() => setActiveTab("Done")} />
        </div>
        <div className="max-w-2xl bg-white p-4 md:p-6 rounded shadow space-y-2">
          {transactions.filter((i) => i.status === activeTab || activeTab === "View All").length > 0 ? (
            transactions
              .filter((i) => i.status === activeTab || activeTab === "View All")
              .map((item) => (
                <div className="border rounded-md p-4 flex justify-between hover:bg-gray-100" key={item.code}>
                  <div>
                    <h6 className="font-bold text-lg">{item.code}</h6>
                    <p className="text-sm text-gray-500">{item.createdAt}</p>
                    {item.status == "Awaiting Payment" && (
                      <Link
                        to={`${process.env.PUBLIC_URL}/transactions/${item.id}`}
                        className="px-3 py-1.5 bg-primary-600 text-white rounded mt-2 inline-block text-sm font-semibold hover:bg-primary-700"
                      >
                        <span>Confirm Payment</span>
                      </Link>
                    )}
                  </div>
                  <div>
                    {item.status == "Done" ? (
                      <span className="inline-block text-xs uppercase px-2 py-1 font-semibold rounded text-green-500">{item.status}</span>
                    ) : (
                      <span className="inline-block text-xs uppercase px-2 py-1 font-semibold rounded text-amber-500">{item.status}</span>
                    )}
                  </div>
                </div>
              ))
          ) : (
            <div>Data not found</div>
          )}
        </div>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default Transactions;
