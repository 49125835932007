import LayoutBase from "../layouts/LayoutBase";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import LayoutHome from "../layouts/LayoutHome";

function NotFoundPage() {
  return (
    <LayoutHome>
      <div className="h-screen flex justify-center items-center">
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto text-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
            />
          </svg>
          <h1 className="font-bold text-2xl mb-4 font-cursive mt-4">Page Not Found</h1>
          <Link
            to={`${process.env.PUBLIC_URL}/`}
            className="px-4 py-2 ml-2 mr-4 bg-primary-800 text-white rounded inline-block font-cursive text-sm"
          >
            Back to home
          </Link>
        </div>
      </div>
    </LayoutHome>
  );
}

export default NotFoundPage;
