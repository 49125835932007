import { Icon } from "@iconify/react";
import { animated, useSpring } from "@react-spring/web";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LayoutMiawhalla from "../../layouts/LayoutMiawhalla";
import LoadingBar from "../../partials/LoadingBar";
import ProfileBox from "../../partials/miawwhalla/ProfileBox";

const images = [
  "https://khuga.io/side-collection/Khuga-Coin.png",
  `${process.env.PUBLIC_URL}/images/miawwhalla/profile-box.webp`,
  `${process.env.PUBLIC_URL}/images/miawwhalla/aksen.webp`,
  `${process.env.PUBLIC_URL}/images/miawwhalla/sword.webp`,
  `${process.env.PUBLIC_URL}/images/miawwhalla/bg-name.webp`,
  `${process.env.PUBLIC_URL}/images/miawwhalla/inventory.webp`,
  `${process.env.PUBLIC_URL}/images/miawwhalla/close.webp`,
  `${process.env.PUBLIC_URL}/images/miawwhalla/season1/part-1.webp`,
  `${process.env.PUBLIC_URL}/images/miawwhalla/season1/part-2.webp`,
  `${process.env.PUBLIC_URL}/images/miawwhalla/season1/part-3.webp`,
  `${process.env.PUBLIC_URL}/images/miawwhalla/season1/ufo.webp`,
  `${process.env.PUBLIC_URL}/images/miawwhalla/season1/event.webp`,
];

function MiawwhallaDashboard() {
  const [loadedImg, setLoadedImg] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [springs, api] = useSpring(() => ({
    from: { x: 800 },
    to: { x: 0 },
  }));

  useEffect(() => {
    setIsLoading(true);

    images.map((i) => {
      const img = new Image();
      img.onload = () => {
        setLoadedImg((prevCount) => prevCount + 1);
      };
      img.src = i;
    });
  }, []);

  useEffect(() => {
    if (loadedImg == images.length) {
      setIsLoading(false);
    }
  }, [loadedImg]);

  return (
    <LayoutMiawhalla>
      {isLoading && <LoadingBar progress={(loadedImg / images.length) * 100} />}

      <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
        <div className="relative mb-12 lg:mb-0">
          <ProfileBox />
        </div>
        <animated.div
          style={{
            ...springs,
          }}
        >
          <Link to={`${process.env.PUBLIC_URL}/miawwhalla/profile`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/miawwhalla/btn-inven.webp`}
              alt="Profile"
              className="w-[80%] mb-4 transition-all active:scale-95 hover:scale-105 origin-right"
            />
          </Link>
          <Link to={`${process.env.PUBLIC_URL}/miawwhalla/event`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/miawwhalla/btn-enter.webp`}
              alt="Enter"
              className="w-[80%] ml-auto transition-all active:scale-95 hover:scale-105 origin-right"
            />
          </Link>
        </animated.div>
      </div>
    </LayoutMiawhalla>
  );
}

export default MiawwhallaDashboard;
