import { createContext, useEffect, useState } from "react";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const auth = localStorage.getItem("x-auth-khuga");
  const metamaskAddress = localStorage.getItem("metamask_address");
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(async () => {
    const checkLoggedIn = async () => {
      let cuser = isAuthenticated();
      if (cuser === null) {
        localStorage.removeItem("x-auth-khuga");
        localStorage.removeItem("x-user-khuga");
        cuser = null;
      }

      setCurrentUser(cuser);
    };

    const profilePicture = async () => {
      if (metamaskAddress && auth) {
        internalAPIClient
          .get(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/nft-ownerships/${metamaskAddress}/profile-picture`)
          .then((res) => {
            if (res?.data?.data?.profile_picture) {
              const id = parseInt(res?.data?.data?.profile_picture?.substr(76).replace(".png", ""));
              setCurrentUser((prevState) => ({
                ...prevState,
                ...{ avatar: `https://khuga.io/khuga-preview/${id}.webp`, avatar_id: id },
              }));
              localStorage.setItem("x-user-khuga", JSON.stringify({
                ...JSON.parse(localStorage.getItem("x-user-khuga")),
                ...{ avatar: `https://khuga.io/khuga-preview/${id}.webp`, avatar_id: id },
              }));
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }

    await checkLoggedIn();
    setTimeout(async () => {
      await profilePicture();
    }, 1000);
  }, []);

  return <UserContext.Provider value={[currentUser, setCurrentUser]}>{children}</UserContext.Provider>;
};

export const isAuthenticated = () => {
  const getKey = (array, key) => array.map((a) => a[key]);

  const user = localStorage.getItem("x-user-khuga");
  if (!user) {
    return null;
  }

  let parsedUser = JSON.parse(user);
  if (getKey(parsedUser.roles, "name")[0] !== undefined) {
    parsedUser.roles = getKey(parsedUser.roles, "name");
  }
  return parsedUser;
};