// Include merge and crypto-js npm packages into your package.json
var merge = require('merge');
// var crypto = require('crypto');
// const { createHmac } = await import("crypto");
// import { createHmac } from 'crypto';
// import * as crypto from "crypto";
// import { Crypto } from "crypto-js";
var createHmac = require('create-hmac');

// export default function /(method, url, consumerKey, consumerSecret, additionalParams) {
const generateAuthorizationHeader = function(method, url, consumerKey, consumerSecret, accessToken, accessTokenSecret, additionalParams) {

	if ( additionalParams === null ) {
		additionalParams = {};
	}

	var signatureMethod = 'HMAC-SHA1';
	var version = '1.0';
	
	// Get timestamp in seconds
	var timestamp = '' + Math.round(new Date().getTime() / 1000);
	
	// Generate a random nonce
	var oauthNonce = Math.random().toString(36).slice(2);

	var oAuthParameters = {
		oauth_token: accessToken,
		oauth_consumer_key: consumerKey,
		oauth_nonce: oauthNonce,
		oauth_signature_method: signatureMethod,
		oauth_timestamp: timestamp,
		oauth_version: version
	};

	if (!accessToken) delete oAuthParameters["oauth_token"];
	console.log(oAuthParameters);

	var signature = generateOAuthSignature(method, url, consumerSecret, accessTokenSecret, merge(oAuthParameters, additionalParams));

	return "OAuth " + 
	(additionalParams?.oauth_callback ? 'oauth_callback="' + additionalParams.oauth_callback + '",' : '' )+
	(accessToken ? 'oauth_token="' + accessToken + '",' : '' )+
	'oauth_consumer_key="' + consumerKey + '",' +
	'oauth_signature_method="' + signatureMethod + '",' +
	'oauth_timestamp="' + timestamp + '",' +
	'oauth_nonce="' + oauthNonce + '",' +
	'oauth_version="' + version + '",' +
	'oauth_signature="' + encodeURIComponent(signature) + '"';
};

function generateOAuthSignature(method, url, secret, AccessTokenSecret, parameters) {

	// Order parameters by key name
	var signatureParams = '';
	Object.keys(parameters).sort().forEach(function(key) {
		signatureParams += key.trim() + '=' + parameters[key].trim() + '&';
	});

	// Remove trailing ampersand
	signatureParams = signatureParams.slice(0, -1);
	var baseString = method.trim() + '&' + encodeURIComponent(url.trim()) + '&' + encodeURIComponent(signatureParams);
	console.log(baseString)
	return createHmac('sha1', secret + '&' + AccessTokenSecret).update(baseString).digest('base64');
}

module.exports = {
	generateAuthorizationHeader
};