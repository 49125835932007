import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { CartProvider } from "react-use-cart";
import { UserProvider } from "./context/UserContext";
import { FavoriteCollectionsProvider } from "./context/FavoriteCollectionsContext";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { WagmiConfig } from "wagmi";
import { khugaWagmiConfig } from "./web3/eth/topLevel";
import App from "./App";
// import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
// import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
// import {
//   GlowWalletAdapter,
//   LedgerWalletAdapter,
//   PhantomWalletAdapter,
//   SlopeWalletAdapter,
//   SolflareWalletAdapter,
//   SolletExtensionWalletAdapter,
//   SolletWalletAdapter,
//   TorusWalletAdapter,
// } from '@solana/wallet-adapter-wallets';

// require('@solana/wallet-adapter-react-ui/styles.css');

// const wallets = [
//   new PhantomWalletAdapter(),
//   new GlowWalletAdapter(),
//   new SlopeWalletAdapter(),
//   new TorusWalletAdapter(),
// ];

// Sentry.init({
//   dsn: "https://9496d3a1a41e4710bda854c0d91f2084@o4504526719549440.ingest.sentry.io/4504526733574144",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 0.2,
// });

ReactDOM.render(
  <React.StrictMode>
    <WagmiConfig config={khugaWagmiConfig}>
      <Router>
        {/* <ConnectionProvider endpoint={process.env.REACT_APP_SOLANA_RPC_HOST}> */}
        {/* <WalletProvider wallets={wallets} autoConnect> */}
        <CartProvider>
          <UserProvider>
            <FavoriteCollectionsProvider>
              <App />
            </FavoriteCollectionsProvider>
          </UserProvider>
        </CartProvider>
        {/* </WalletProvider> */}
        {/* </ConnectionProvider> */}
      </Router>
    </WagmiConfig>
  </React.StrictMode>,
  document.getElementById("root")
);
