import { find, flatten, map } from "lodash";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";

export default function EventList({ event, inventories }) {
  const [passRequirement, setPassRequirement] = useState(false);
  const [showRequirement, setShowRequirement] = useState(false);
  const [eventRequirements, setEventRequirements] = useState([]);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    const getStartTime = moment(event?.start_time).diff(moment.utc());
    setStartTime(getStartTime);

    // Check event requirements
    if (event?.requirements?.length > 0) {
      setEventRequirements(event?.requirements?.filter((o) => o.block_event === true) ?? []);

      setPassRequirement(true);
      event?.requirements?.map((r) => {
        const myInventory = find(inventories, (i) => i.token_id === r.token_id);
        if (myInventory === undefined) {
          return setPassRequirement(false);
        }

        if (r.operant === "gte" && myInventory !== undefined) {
          if (myInventory.balance >= r.amount) {
            setPassRequirement(true);
          } else {
            setPassRequirement(false);
          }
        }

        if (r.operant === "eq" && myInventory !== undefined) {
          if (myInventory.balance == r.amount) {
            setPassRequirement(true);
          } else {
            setPassRequirement(false);
          }
        }
      });
    }
  }, []);

  return (
    <>
      {passRequirement ? (
        <Link
          to={startTime > 0 ? `${process.env.PUBLIC_URL}/miawwhalla/event/${event.id}` : `${process.env.PUBLIC_URL}/miawwhalla/event/${event.id}/battle`}
          className={`${!event.enabled && "pointer-events-none"}`}
        >
          <RenderList event={event} />
        </Link>
      ) : (
        <div className="cursor-pointer" onClick={() => setShowRequirement(true)}>
          <RenderList event={event} />
        </div>
      )}

      <Transition appear show={showRequirement} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setShowRequirement(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <button className="absolute z-20 top-4 right-4 md:right-8 transition-all" onClick={() => setShowRequirement(false)}>
                    <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/close.webp`} alt="" className="w-8 h-8 hover:scale-105" />
                  </button>

                  <Dialog.Title as="h3" className="text-xl font-medium font-cursive text-center leading-6 text-gray-900 mb-4">
                    Event Requirement
                  </Dialog.Title>
                  <div className="flex items-center gap-2">
                    {eventRequirements.map((i) => (
                      <div className="mt-2 text-center flex-1" key={i.id}>
                        <p className="text-sm text-gray-500">
                          <img src={i?.collection?.thumbnail_url} className="h-24 aspect-square mx-auto rounded-lg" />
                        </p>
                        <h6 className="font-bold text-lg mt-2">
                          {i?.collection?.name} ({i.operant === "eq" && "="} {i.operant === "gte" && ">="} {i.amount})
                        </h6>
                        <a
                          target="_blank"
                          className="inline-block mt-4 px-5 py-2 rounded-md bg-primary-800 text-white font-cursive"
                          href={i?.collection?.opensea_url}
                        >
                          Get Now
                        </a>
                      </div>
                    ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

function RenderList({ event }) {
  const [endTime, setEndTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [totalTime, setTotalTime] = useState(null);

  useEffect(() => {
    const getStartTime = moment(event?.start_time).diff(moment.utc());
    const getEndTime = moment(event?.end_time).diff(moment.utc());
    let getTotalTime = moment(event?.end_time).diff(event?.start_time);
    if (getStartTime > 0) {
      getTotalTime = moment(event?.startTime).diff(moment(event?.startTime).subtract(2, "days"));
    }
    setStartTime(getStartTime);
    setEndTime(getEndTime);
    setTotalTime(getTotalTime);
  }, []);

  return (
    <div className="relative pl-2 hover:-translate-x-2 transition-all">
      <img src={event.banner_image} alt="Miawwhalla" className={`w-full ${!event.enabled && "grayscale"}`} />

      <div className="absolute left-[2%] top-[6%] w-[44%]">
        <img src={event.title_url} alt={event.name} className="w-full" />
      </div>

      {/* Event not started */}
      {event.enabled && startTime > 0 && (
        <div className="absolute right-4 bottom-6">
          <div className="flex items-center gap-2">
            <div className="w-64 h-5 bg-white rounded-full p-0.5">
              <div className="bg-yellow-400 rounded-full h-4" style={{ width: `${((totalTime - startTime) / totalTime) * 100}%` }}></div>
            </div>
            <div className="absolute right-2 font-cursive text-[11px]">
              <div className="flex items-center gap-1">
                <Countdown date={moment.utc() + startTime} renderer={rendererCountDown} />
                <span>to Battle</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Event has been started & not ended */}
      {startTime < 0 && endTime > 0 && (
        <div className="absolute right-4 bottom-0">
          <div className="flex items-center gap-2">
            <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/sword.webp`} alt="" className="w-14" />
            <div className="w-64 h-5 bg-white rounded-full p-0.5">
              <div className="bg-[#57D7BB] rounded-full h-4" style={{ width: `${((totalTime - endTime) / totalTime) * 100}%` }}></div>
            </div>
            <div className="absolute right-2 font-cursive text-[11px]">
              <div className="flex items-center gap-1">
                <Countdown date={moment.utc(moment.utc().local()) + endTime} renderer={rendererCountDown} />
                <span>Remaining</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Event has been started & ended */}
      {startTime < 0 && endTime < 0 && (
        <div className="absolute right-4 bottom-0">
          <div className="flex items-center gap-2">
            <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/sword.webp`} alt="" className="w-14" />
            <div className="w-64 h-5 bg-white rounded-full p-0.5">
              <div className="bg-[#57D7BB] rounded-full h-4 w-full"></div>
            </div>
            <div className="absolute right-1/3 font-cursive text-[11px]">
              <div className="flex items-center text-black gap-1">
                <span>Ended</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return window.location.reload();
  } else {
    // let newHours = days * 24 + hours;
    return (
      <p>
        {days}
        <span className="text-[9px]">d</span> {hours}
        <span className="text-[8px]">h</span> {minutes}
        <span className="text-[8px]">m</span> {seconds}
        <span className="text-[8px]">s</span>
      </p>
    );
  }
};
