import { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";

export default function IllustrationSeason1() {
  const anim1 = useSpring({
    from: { x: -400, opacity: 0 },
    to: { x: 0, opacity: 1 },
    delay: 100,
  });

  const anim2 = useSpring({
    from: { x: -400, opacity: 0 },
    to: { x: 0, opacity: 1 },
    delay: 200,
  });

  const anim3 = useSpring({
    from: { y: 100, opacity: 0 },
    to: { y: 0, opacity: 1 },
    delay: 300,
  });

  const anim4 = useSpring({
    from: { y: -300, opacity: 0 },
    to: { y: 0, opacity: 1 },
    delay: 400,
  });

  return (
    <div className="relative w-full aspect-square">
      <animated.div style={anim1} className="absolute w-[70%] top-0 left-[5%]">
        <img className="" src={`${process.env.PUBLIC_URL}/images/miawwhalla/season1/part-1.webp`} alt="" />
      </animated.div>
      <animated.div style={anim4} className="absolute w-[40%] -top-[15%] right-[5%]">
        <img className="animate-x-1" src={`${process.env.PUBLIC_URL}/images/miawwhalla/season1/ufo.webp`} alt="" />
      </animated.div>
      <animated.div style={anim3} className="absolute w-[85%] top-[15%] right-0">
        <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/season1/part-3.webp`} alt="" />
      </animated.div>
      <animated.div style={anim2} className="absolute w-[40%] top-[25%] left-[2%]">
        <img className="" src={`${process.env.PUBLIC_URL}/images/miawwhalla/season1/part-2.webp`} alt="" />
      </animated.div>
    </div>
  );
}
