import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { useContext, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import LayoutHome from "../../layouts/LayoutHome";
import LoadingSpinner from "../../partials/LoadingSpinner";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { useEffect } from "react";
import { useNavigate } from "react-router";

function AdminAuth() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser] = useContext(UserContext);

  useEffect(() => {
    if (currentUser !== null && currentUser !== undefined) {
      return navigate(`${process.env.PUBLIC_URL}/home`);
    }
  }, [currentUser]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);

      axios
        .post(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/member/login`, {
          email: values.email,
          password: values.password,
        })
        .then((res) => {
          if (res?.data?.status === "success") {
            localStorage.setItem("x-auth-khuga", res?.headers?.["x-auth-khuga"] ?? res?.data?.data?.khuga_token);
            localStorage.setItem("x-user-khuga", JSON.stringify(res?.data?.data));
            toast.success("Login success!");
            setTimeout(() => {
              location.reload();
            }, 500);
          } else {
            toast.error(res?.data?.message);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Error: " + error);
          }
        });
    },
  });

  return (
    <LayoutHome>
      {isLoading && <LoadingSpinner />}

      <div className="w-full h-screen bg-[#C2C2C2]">
        <div className="absolute z-0 inset-0 bg-cover bg-bottom bg-no-repeat bg-[url('../../public/images/bg/miawhalla-mobile.webp')] sm:bg-[url('../../public/images/bg/miawhalla.webp')]"></div>

        <div className="relative max-w-md z-10 bg-white top-56 mx-auto p-6 md:p-8 rounded-md shadow-2xl border-b-8 border-primary-800">
          <h1 className="text-2xl font-cursive mb-4 text-center">Sign in to Admin</h1>
          <form onSubmit={formik.handleSubmit} className="space-y-4">
            <div className="space-y-1">
              <label htmlFor="email" className="block">
                Email Address
              </label>
              <input id="email" type="email" {...formik.getFieldProps("email")} className="block w-full rounded" placeholder="Your email address" />
              {formik.touched.email && formik.errors.email ? <div className="text-sm text-red-500">{formik.errors.email}</div> : null}
            </div>

            <div className="space-y-1">
              <label htmlFor="password" className="block">
                Password
              </label>
              <input id="password" type="password" {...formik.getFieldProps("password")} className="block w-full rounded" placeholder="********" />
              {formik.touched.password && formik.errors.password ? <div className="text-sm text-red-500">{formik.errors.password}</div> : null}
            </div>

            <button
              type="submit"
              className="block w-full p-3 bg-primary-800 text-white rounded-md font-cursive hover:contrast-75 hover:shadow-lg transition-all"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      <footer className="absolute inset-x-0 bottom-0 z-10 bg-black text-white py-2 md:py-4">
        <div className="max-w-6xl mx-auto px-4 pb-2 sm:pb-4 relative z-10 text-center md:text-left">
          <span className="font-bold text-xs">©2023 Khuga by Khuga Labs</span>
        </div>
      </footer>

      <Toaster />
    </LayoutHome>
  );
}

export default AdminAuth;
