import { Icon } from "@iconify/react";

function CartItem({ title, price, image, quantity, onAddQuantity, onSubQuantity, onRemoveItem, trigger }) {
  return (
    <div className="pt-4">
      <div className="flex items-center space-x-2 mb-2">
        <div className="w-14 h-14 shrink-0">
          <img src={image} alt="Image" className="w-14 h-14 rounded object-cover" />
        </div>
        <div>
          <h6 className="text-white/80 font-medium line-clamp-1">{title}</h6>
          <p className="text-white/50">${price*quantity}</p>
        </div>
      </div>
      <div className="flex space-x-2">
        <button ref={trigger} className="shrink-0 w-8 h-8 bg-slate-800 border border-slate-700 p-1 hover:bg-slate-700" onClick={() => onRemoveItem()}>
          <Icon icon="tabler:x" className="w-6 h-6 text-white/50" />
        </button>
        <div className="flex-1 w-full h-8 bg-slate-800 border border-slate-700 flex">
          <div className="flex-1 p-1 text-white/50">{quantity}</div>
          <div className="shrink-0 flex">
            <button ref={trigger} className="bg-slate-800 border border-slate-700 p-1 hover:bg-slate-700" onClick={() => onSubQuantity()}>
              <Icon icon="tabler:minus" className="w-5 h-5 text-white/50" />
            </button>
            <button ref={trigger} className="bg-slate-800 border border-slate-700 p-1 hover:bg-slate-700" onClick={() => onAddQuantity()}>
              <Icon icon="tabler:plus" className="w-5 h-5 text-white/50" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
