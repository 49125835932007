import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LayoutBase from "../../../layouts/LayoutBase";
import { useAuthenticatedHttpClient } from "../../../utils/authenticated-api-call";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../../../partials/LoadingSpinner";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function AdminParticipantEvent() {
  const params = useParams();
  const navigate = useNavigate();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [rows, setRows] = useState([]);
  const [event, setEvent] = useState();
  const [participants, setParticipants] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    unit: yup.string().required(),
    number: yup.number().required(),
  });

  const renderError = (message) => <p className="text-sm italic text-red-600">{message}</p>;

  useEffect(async () => {
    await internalAPIClient
      .get(`/missions/${params?.id}`)
      .then((res) => {
        setEvent(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occured");
      });
  }, []);

  useEffect(() => {
    if (event) {
      internalAPIClient
        .get(`/cms/missions/${params?.id}/participants`)
        .then((res) => {
          setParticipants(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error occured");
        });
    }
  }, [event]);

  const handleDisqualified = async () => {
    setIsLoading(true);

    await internalAPIClient
      .post(`/missions/${params?.id}/disqualified`, {
        unit: values?.unit,
        number: values?.number,
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Success disqualified event participants", { position: "top-right" });
          setTimeout(() => {
            return navigate(`${process.env.PUBLIC_URL}/admin/event/${params?.id}`);
          }, 1500);
        } else {
          console.log(res);
          toast.error("Failed to disqualified event participants");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occured");
      });

    setIsLoading(false);
  };

  return (
    <LayoutBase>
      {isLoading && <LoadingSpinner />}

      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div className="flex items-center justify-between">
          <h1 className="font-bold text-2xl mb-4 font-cursive">Data Event Participants</h1>
          <div className="flex items-center gap-2">
            <Link
              to="/admin/event"
              className="px-5 py-2.5 bg-primary-800 text-white rounded hover:contrast-75 font-cursive text-xs mb-4 flex items-center gap-1"
            >
              <Icon icon="uil:arrow-left" className="w-4 h-4" />
              <span>Back</span>
            </Link>
            <button
              onClick={() => setShowModal(true)}
              className="px-5 py-2.5 bg-primary-800 text-white rounded hover:contrast-75 font-cursive text-xs mb-4 flex items-center gap-1"
            >
              <span>Disqualification</span>
            </button>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table className="w-full" aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {participants == null ? (
                <TableRow key="load">
                  <TableCell>Loading...</TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              ) : (
                <>
                  {participants.length > 0 ? (
                    <>
                      {participants.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row?.collection_name}</TableCell>
                          <TableCell>
                            {row?.disqualified_status ? (
                              <Icon icon="uil:times-circle" className="w-5 h-5 text-red-500" />
                            ) : (
                              <Icon icon="uil:check-circle" className="w-5 h-5 text-green-500" />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableRow key="load">
                      <TableCell>Data not found...</TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={() => setShowModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-bold font-cursive mb-4 leading-6 text-gray-700">
                    Disqualification
                  </Dialog.Title>

                  <Formik
                    enableReinitialize
                    initialValues={{
                      unit: event?.unit,
                      number: event?.number,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      handleDisqualified(values);
                    }}
                  >
                    <Form>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="space-y-1">
                          <label htmlFor="unit">Unit</label>
                          <Field as="select" name="unit" className="block w-full px-4 py-2 border border-gray-300 rounded">
                            <option value="">-- Select --</option>
                            <option value="percent">Percent</option>
                            <option value="individual">Individual</option>
                            <option value="remaining">Remaining</option>
                          </Field>
                          <ErrorMessage name="unit" render={renderError} />
                        </div>
                        <div className="space-y-1">
                          <label htmlFor="number">Number</label>
                          <Field name="number" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                          <ErrorMessage name="number" render={renderError} />
                        </div>
                      </div>
                      <div className="mt-4 flex items-center gap-4">
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={() => setShowModal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Toaster />
    </LayoutBase>
  );
}

export default AdminParticipantEvent;
