import IllustrationSeason1 from "../../partials/miawwhalla/illustration/Season1";
import LayoutMiawhalla from "../../layouts/LayoutMiawhalla";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import SelectCollection from "../../partials/miawwhalla/SelectCollection";
import Countdown from "react-countdown";
import { Icon } from "@iconify/react";
import MotionE1 from "../../lotties/motion-e1";
import Lottie from "react-lottie";
import SelectSquadCollection from "../../partials/miawwhalla/SelectSquadCollection";

function MiawwhallaEventDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const metamaskAddress = localStorage.getItem("metamask_address");
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [event, setEvent] = useState();
  const [showSquad, setShowSquad] = useState(false);
  const [endTime, setEndTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [totalTime, setTotalTime] = useState(null);
  const [totalDays, setTotalDays] = useState([]);
  const [assetCollections, setAssetCollections] = useState([]);

  const [startVote, setStartVote] = useState();
  const [endVote, setEndVote] = useState();

  useEffect(async () => {
    await internalAPIClient
      .get(`/missions/${params?.id}`)
      .then((res) => {
        if (res?.data?.status === "error") {
          toast.error(res?.data?.message);
          setTimeout(() => {
            return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
          }, 3000);
        }

        setEvent(res?.data?.data);
        const getStartTime = moment.utc(res?.data?.data?.start_time).diff(moment.utc(moment.utc().local()));
        const getEndTime = moment.utc(res?.data?.data?.end_time).diff(moment.utc(moment.utc().local()));
        const getTotalTime = moment.utc(res?.data?.data?.end_time).diff(moment.utc(res?.data?.data?.start_time));
        setStartTime(getStartTime);
        setEndTime(getEndTime);
        setTotalTime(getTotalTime);

        const getStartVote = moment.utc(res?.data?.data?.event_vote?.start_time).diff(moment.utc(moment.utc().local()));
        const getEndVote = moment.utc(res?.data?.data?.event_vote?.end_time).diff(moment.utc(moment.utc().local()));
        setStartVote(getStartVote);
        setEndVote(getEndVote);

        const td = [];
        if (res?.data?.data?.milestone_interval > 0) {
          for (let i = 1; i <= moment.duration(getTotalTime).asDays(); i += res?.data?.data?.milestone_interval) {
            td.push(i);
          }
        } else {
          td.push(1);
        }
        setTotalDays(td);

        // redirect to event list when current event is not started
        if (getStartTime > 0) {
          return navigate(`${process.env.PUBLIC_URL}/miawwhalla/event`);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occured");
      });
  }, []);

  useEffect(async () => {
    if (event) {
      await internalAPIClient
        .get(`/missions/${event?.id}/assets/${metamaskAddress}`)
        .then((res) => {
          setAssetCollections(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [event]);

  const secsToTime = (seconds) => {
    const days = Math.floor(seconds / 86400);
    seconds %= 86400;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;
    return `${days.toString().padStart(2, "0")}d ${hours.toString().padStart(2, "0")}h ${minutes.toString().padStart(2, "0")}m ${seconds
      .toString()
      .padStart(2, "0")}s`;
  };

  return (
    <LayoutMiawhalla className="bg-[#221F1F]">
      <div className="relative container mx-auto px-4">
        <div className="absolute right-4 top-10 z-20">
          <Link to={`${process.env.PUBLIC_URL}/miawwhalla/event`}>
            <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/close.webp`} alt="" className="w-8 h-8 hover:scale-105" />
          </Link>
        </div>

        <div>
          <div className="hidden md:block -mt-12 2xl:mt-0">
            {event?.animation_url && (
              <>
                {event?.animation_url.split(".").pop() === "json" ? (
                  <Lottie
                    isClickToPauseDisabled={true}
                    options={{
                      loop: true,
                      autoplay: true,
                      // animationData: MotionE1,
                      path: event?.animation_url,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    style={{ width: `70%` }}
                    className="mx-auto"
                  />
                ) : (
                  <img src={event?.animation_url} className="w-[70%] mx-auto" />
                )}
              </>
            )}
          </div>
          <div className="block md:hidden">
            {event?.id === "595a361f-ae9c-45f8-af8f-05d8e6c1d343" ? (
              <IllustrationSeason1 />
            ) : (
              <>
                {event?.v_animation_url === null ? (
                  <img src={event?.background_image} className="w-full" />
                ) : (
                  <>
                    {event?.v_animation_url.split(".").pop() === "json" ? (
                      <Lottie
                        isClickToPauseDisabled={true}
                        options={{
                          loop: true,
                          autoplay: true,
                          // animationData: MotionE1,
                          path: event?.v_animation_url,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        style={{ width: `90%` }}
                        className="mx-auto"
                      />
                    ) : (
                      <img src={event?.v_animation_url} className="w-[90%] mx-auto" />
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="text-white text-lg 2xl:text-xl" dangerouslySetInnerHTML={{ __html: event?.story?.replace(/\n/g, "<br />") }}></div>
        </div>

        <div className="mt-6 text-white">
          {/* <p className="p-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Non dolor eum facilis quisquam, deserunt obcaecati nisi neque numquam necessitatibus, sed
            porro nobis? Sint laudantium aperiam iste rem minus. Eum, harum.
          </p> */}
          <div className="p-4 2xl:p-8 bg-[#434143] rounded-xl flex flex-col md:flex-row items-center gap-6">
            <div className="w-full flex-1">
              <div className="flex items-center justify-between">
                <div className="flex items-end gap-2 text-[#9B9B9B]">
                  <h4 className="font-cursive">{event?.name}</h4>
                  {endTime > 0 && (
                    <p className="text-sm hidden md:flex items-end">
                      <span>On progress ({Math.round(((totalTime - endTime) / totalTime) * 100)}%)</span>{" "}
                      <Icon icon="eos-icons:three-dots-loading" className="w-5 h-5 -mb-1" />
                    </p>
                  )}
                </div>
                <h6 className="text-lg tracking-widest hidden md:flex items-center gap-2">
                  {endTime > 0 ? (
                    <span className="text-[#57D7BB]">
                      <Countdown date={Date.now() + endTime} renderer={rendererCountDown} />
                    </span>
                  ) : (
                    <span className="text-[#57D7BB]">00:00:00:00</span>
                  )}
                  {/* <span>/ {secsToTime(totalTime / 1000)}</span> */}
                  {/* <span>remaining</span> */}
                </h6>
              </div>

              <div className="mt-3 relative w-full h-2 bg-white rounded-full">
                {endTime > 0 ? (
                  <>
                    <div className="h-2 rounded-full bg-[#57D7BB] absolute left-0" style={{ width: `${((totalTime - endTime) / totalTime) * 100}%` }}></div>
                    <div className="absolute z-10 w-6 h-6 -ml-2 -top-2 bg-white rounded-full" style={{ left: `${((totalTime - endTime) / totalTime) * 100}%` }}>
                      <div className="w-4 h-4 mt-1 ml-1 bg-[#57D7BB] rounded-full"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="h-2 rounded-full bg-[#57D7BB] absolute left-0" style={{ width: `100%` }}></div>
                    <div className="absolute z-10 w-6 h-6 -ml-2 -top-2 bg-white rounded-full" style={{ left: `100%` }}>
                      <div className="w-4 h-4 mt-1 ml-1 bg-[#57D7BB] rounded-full"></div>
                    </div>
                  </>
                )}

                {totalDays.map((i, idx) => (
                  <div className="absolute" style={{ left: `${(100 / totalDays.length) * (idx + 1)}%` }} key={i}>
                    <div className="absolute w-4 h-4 -ml-1 -top-1 bg-white rounded-full"></div>
                    {/* <div className="bg-white font-bold text-black px-2 py-1 text-xs w-full mt-2 rounded-md">1 Eliminated</div> */}
                  </div>
                ))}
              </div>

              <div className="md:hidden flex items-center justify-between mt-3">
                {endTime > 0 && (
                  <p className="text-sm text-[#9B9B9B] flex items-end">
                    <span>On progress ({Math.round(((totalTime - endTime) / totalTime) * 100)}%)</span>
                    <Icon icon="eos-icons:three-dots-loading" className="w-5 h-5 -mb-1" />
                  </p>
                )}
                <div className="tracking-widest flex items-center gap-2 text-xs">
                  {endTime > 0 ? (
                    <span className="text-[#57D7BB]">
                      <Countdown date={Date.now() + endTime} renderer={rendererCountDown} />
                    </span>
                  ) : (
                    <span className="text-[#57D7BB]">00:00:00:00</span>
                  )}
                  {/* <span>/ {secsToTime(totalTime / 1000)}</span> */}
                  {/* <span>remaining</span> */}
                </div>
              </div>
            </div>
            <div className="shrink-0 flex flex-col md:flex-row items-center gap-2 w-full md:w-auto">
              {assetCollections?.event_datas ? (
                <>
                  {startTime < 0 &&
                    (assetCollections?.event_datas?.filter((i) => i.join_status === "JOINED").length > 0 ||
                      assetCollections?.event_datas?.filter((i) => i.disqualified_status == 1).length > 0) && (
                      <button onClick={() => setShowSquad(true)} className="bg-[#439583] font-bold p-3 rounded-md w-full md:w-auto">
                        View Squad
                      </button>
                    )}
                </>
              ) : (
                <>
                  {startTime < 0 &&
                    (assetCollections?.filter((i) => i.join_status === "JOINED").length > 0 ||
                      assetCollections?.filter((i) => i.disqualified_status == 1).length > 0) && (
                      <button onClick={() => setShowSquad(true)} className="bg-[#439583] font-bold p-3 rounded-md w-full md:w-auto">
                        View Squad
                      </button>
                    )}
                </>
              )}

              {assetCollections?.event_datas ? (
                <>
                  {event?.event_vote ? (
                    <Link
                      to={`${process.env.PUBLIC_URL}/miawwhalla/event/${event?.id}/vote`}
                      className="bg-[#439583] font-bold p-3 rounded-md w-full md:w-auto text-center"
                    >
                      {startVote < 0 && endVote > 0 ? "Let's Vote" : "Vote Result"}
                    </Link>
                  ) : (
                    <>
                      {endTime < 0 &&
                      (assetCollections?.event_datas?.filter((i) => i.join_status === "JOINED").length > 0 ||
                        assetCollections?.event_datas?.filter((i) => i.disqualified_status == 1).length > 0) ? (
                        <Link
                          to={`${process.env.PUBLIC_URL}/miawwhalla/event/${event.id}/claim`}
                          className="bg-[#439583] font-bold p-3 rounded-md w-full md:w-auto text-center"
                        >
                          View Result
                        </Link>
                      ) : (
                        <button className="bg-[#439583] font-bold p-3 rounded-md w-full md:w-auto grayscale cursor-not-allowed">View Result</button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {endTime < 0 &&
                  (assetCollections?.filter((i) => i.join_status === "JOINED").length > 0 ||
                    assetCollections?.filter((i) => i.disqualified_status == 1).length > 0) ? (
                    <Link
                      to={`${process.env.PUBLIC_URL}/miawwhalla/event/${event.id}/claim`}
                      className="bg-[#439583] font-bold p-3 rounded-md w-full md:w-auto text-center"
                    >
                      View Result
                    </Link>
                  ) : (
                    <button className="bg-[#439583] font-bold p-3 rounded-md w-full md:w-auto grayscale cursor-not-allowed">View Result</button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="mt-2 text-center md:text-left">
            {event?.mode === "squad" ? (
              <p>
                Participant Remaining: {event?.qualified_participants / 5}/{event?.all_participants / 5}
              </p>
            ) : (
              <p>
                Participant Remaining: {event?.qualified_participants}/{event?.all_participants}
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Modal */}
      {showSquad && (
        <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="absolute inset-0 bg-black/50 backdrop-blur"></div>
          <div className="relative w-full max-w-6xl mx-auto px-4 h-[calc(100vh_-_20%)]">
            <button className="absolute z-20 top-4 right-8 transition-all" onClick={() => setShowSquad(false)}>
              <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/close.webp`} alt="" className="w-8 h-8 hover:scale-105" />
            </button>
            <div className="bg-white/80 rounded-lg border border-gray-600 h-full">
              {event?.mode === "squad" ? <SelectSquadCollection event={event} /> : <SelectCollection event={event} />}
            </div>
          </div>
        </div>
      )}
    </LayoutMiawhalla>
  );
}

const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return window.location.reload();
  } else {
    return (
      <p>
        {days}d {hours}h {minutes}m {seconds}s
      </p>
    );
  }
};

export default MiawwhallaEventDetail;
