import LayoutBase from "../../../layouts/LayoutBase";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useAuthenticatedHttpClient } from "../../../utils/authenticated-api-call";
import toast, { Toaster } from "react-hot-toast";
import { Paper } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { find, map } from "lodash";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { confirmAlert } from "react-confirm-alert";
import LoadingSpinner from "../../../partials/LoadingSpinner";

function ListProduct() {
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [rows, setRows] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { field: "code", headerName: "Code", flex: 1, width: 180 },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      width: 150,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      width: 150,
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
      width: 180,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      width: 180,
    },
    {
      field: "id",
      headerName: "Action",
      flex: 1,
      width: 150,
      renderCell: (params) => (
        <div className="h-full flex items-center gap-2">
          <Link to={`/admin/marketplace/product/${params.value}/edit`} className="inline-block p-2 bg-primary-800 rounded-md text-white">
            <Icon icon="uil:edit" />
          </Link>
          <button className="inline-block p-2 bg-red-600 rounded-md text-white" onClick={() => handleDelete(params.value)}>
            <Icon icon="uil:trash-alt" />
          </button>
        </div>
      ),
    },
  ];

  useEffect(async () => {
    getCurrency();
  }, []);

  useEffect(async () => {
    getProducts();
  }, [currencies]);

  const getProducts = async () => {
    await internalAPIClient
      .get(`/cms/marketplace/products`)
      .then((res) => {
        const mapData = map(res?.data?.data, (r) => {
          return { id: r.id, code: r.code, type: r.type, name: r.name, price: r.price, currency: find(currencies, (o) => o.id === r.currency_id)?.code };
        });
        setRows(mapData);
      })
      .catch(async (e) => {
        console.log(e);
      });
  };

  const getCurrency = async () => {
    await internalAPIClient.get(`/cms/marketplace/currencies`).then((res) => setCurrencies(res?.data?.data));
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Are you sure?",
      message: "The data will be permanently deleted",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setIsLoading(true);
            await internalAPIClient
              .delete(`/cms/marketplace/products/${id}`)
              .then((res) => {
                if (res?.data?.status === "success") {
                  toast.success("Success!");
                  getProducts();
                } else {
                  toast.error("Error occured!");
                }
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err);
                toast.error("Error occured!");
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <LayoutBase>
      {isLoading && <LoadingSpinner />}

      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div className="flex items-center justify-between">
          <h1 className="font-bold text-2xl mb-4 font-cursive">Data Marketplace Products</h1>
          <Link to={`/admin/marketplace/product/create`} className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-cursive text-sm mb-4">
            Create
          </Link>
        </div>

        <Paper style={{ width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid autoHeight rows={rows} columns={columns} pageSize={10} disableSelectionOnClick />
            </div>
          </div>
        </Paper>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default ListProduct;
