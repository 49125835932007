import { Link, useNavigate, useParams } from "react-router-dom";
import LayoutMiawhalla from "../../layouts/LayoutMiawhalla";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { useEffect, useRef, useState } from "react";
import { useTransition, animated } from "@react-spring/web";
import toast from "react-hot-toast";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import moment from "moment/moment";
import { getEnsName } from "../../web3/eth/ens";
import { map, orderBy, sumBy } from "lodash";
import Countdown from "react-countdown";
import LoadingSpinner from "../../partials/LoadingSpinner";
import { Icon } from "@iconify/react";

function MiawwhallaVote() {
  const params = useParams();
  const navigate = useNavigate();
  const refSplide = useRef();
  const { internalAPIClient } = useAuthenticatedHttpClient();

  const [loading, setLoading] = useState(false);
  const [selectedVote, setSelectedVote] = useState(null);
  const [event, setEvent] = useState();
  const [voteCandidates, setVoteCandidates] = useState([]);
  const [winner, setWinner] = useState();
  const [leaderboard, setLeaderboard] = useState();
  const [startVote, setStartVote] = useState();
  const [endVote, setEndVote] = useState();
  const [alreadyVoted, setAlreadyVoted] = useState(false);

  useEffect(async () => {
    await internalAPIClient
      .get(`/missions/${params?.id}`)
      .then((res) => {
        if (res?.data?.status === "error") {
          toast.error(res?.data?.message);
          setTimeout(() => {
            return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
          }, 3000);
        }

        setEvent(res?.data?.data);
        const getStartVote = moment.utc(res?.data?.data?.event_vote?.start_time).diff(moment.utc(moment.utc().local()));
        const getEndVote = moment.utc(res?.data?.data?.event_vote?.end_time).diff(moment.utc(moment.utc().local()));
        setStartVote(getStartVote);
        setEndVote(getEndVote);

        // redirect to event list when current event vote is not started
        if (getStartVote > 0) {
          return navigate(`${process.env.PUBLIC_URL}/miawwhalla/event`);
        }

        // redirect to event list when current event vote is ended
        // if (getEndVote < 0) {
        //   return navigate(`${process.env.PUBLIC_URL}/miawwhalla/event`);
        // }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occured");
      });
  }, []);

  useEffect(async () => {
    if (event) {
      // redirect to event list when user already voted
      if (event?.event_vote?.already_voted === 1) {
        // toast.error("You're already voted!");
        setAlreadyVoted(true);
      }

      await internalAPIClient
        .get(`/missions/${event?.id}/vote-candidates`)
        .then((res) => {
          if (res?.data?.status === "error") {
            toast.error(res?.data?.message);
            setTimeout(() => {
              return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
            }, 3000);
          }

          const mapOwner = map(res?.data?.data?.event_vote_candidates, (i) => {
            let response = i;
            getEnsName(i.owner).then((res) => (response = { ...i, ownerEns: res }));

            return response;
          });

          setVoteCandidates(mapOwner);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error occured");
        });
    }
  }, [event]);

  useEffect(() => {
    if (voteCandidates.length > 0) {
      setWinner(orderBy(voteCandidates, ["event_vote_data_count"], ["desc"])[0]);
      setLeaderboard(orderBy(voteCandidates, ["event_vote_data_count"], ["desc"]));
    }
  }, [voteCandidates]);

  useEffect(() => {
    // if (refSplide.current) {
    //   console.log(refSplide.current.splide);
    // }
  });

  const handleVote = () => {
    setLoading(true);

    internalAPIClient
      .post(`/missions/${event?.id}/vote-candidates`, {
        owner: selectedVote?.owner,
        squad: selectedVote?.squad,
      })
      .then((res) => {
        if (res?.data?.status === "error") {
          toast.error(res?.data?.message);
        } else {
          toast.success("Vote success!");
        }

        setLoading(false);
        setTimeout(() => {
          return window.location.reload();
        }, 3000);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Error occured");
        setTimeout(() => {
          return window.location.reload();
        }, 3000);
      });
  };

  const transition = useTransition(selectedVote, {
    from: {
      scale: 0,
      opacity: 0,
    },
    enter: {
      scale: 1,
      opacity: 1,
    },
    leave: {
      scale: 0,
      opacity: 0,
    },
  });

  return (
    <LayoutMiawhalla className="bg-[#221F1F]">
      {loading && <LoadingSpinner />}

      <div className="relative container mx-auto px-4">
        <div className="absolute right-4 top-0 z-20">
          <Link to={`${process.env.PUBLIC_URL}/miawwhalla/event`}>
            <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/close.webp`} alt="" className="w-8 h-8 hover:scale-105" />
          </Link>
        </div>

        {endVote < 0 ? (
          <>
            <div className="text-center">
              <img src={event?.banner_image} alt="Image" className="w-full sm:s-1/2 md:w-1/3 mx-auto" />
            </div>
            {winner && (
              <div className="max-w-3xl mx-auto">
                <div className="text-center mb-8">
                  <h2 className="font-bold text-amber-400 font-cursive text-xl md:text-2xl mb-1">The {event?.name} will be revealed soon!</h2>
                  <p className="font-bold text-white font-cursive text-xl mb-8">Thanks For Your Vote</p>

                  {leaderboard && leaderboard.length > 0 && (
                    <table className="max-w-md w-full mx-auto bg-white/10 text-white">
                      <thead className="bg-white text-black font-cursive">
                        <tr>
                          <th className="px-4 py-1.5">Rank</th>
                          <th className="px-4 py-1.5 mx-auto"></th>
                          <th className="px-4 py-1.5">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaderboard.map((i, idx) => (
                          <tr>
                            <td className="px-4 py-1.5 font-bold">{idx + 1}</td>
                            <td className="px-4 py-1.5 pl-16">
                              <div className="w-36 bg-white/10 border border-white/20 h-6 rounded-full p-1">
                                <div className="bg-amber-400 h-4 rounded-full" style={{ width: `${((i.event_vote_data_count / sumBy(leaderboard, "event_vote_data_count")) * 100).toFixed(2)}%` }}></div>
                              </div>
                            </td>
                            <td className="px-4 py-1.5 font-bold">{((i.event_vote_data_count / sumBy(leaderboard, "event_vote_data_count")) * 100).toFixed(2)}%</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="text-center pt-16 md:pt-0">
              {alreadyVoted ? (
                <h1 className="font-cursive text-3xl text-amber-400">Thanks For Your Vote</h1>
              ) : (
                <h1 className="font-cursive text-3xl text-amber-400">Let's Vote</h1>
              )}
              {endVote > 0 && (
                <span className="text-white font-cursive">
                  <Countdown date={Date.now() + endVote} renderer={rendererCountDown} />
                </span>
              )}
            </div>

            <div className="max-w-2xl mx-auto mt-4 space-y-6">
              <Splide
                ref={refSplide}
                aria-label="My Favorite Images"
                hasTrack={false}
                options={{
                  type: "loop",
                  autoplay: true,
                  gap: "2rem",
                  padding: "6rem",
                  breakpoints: {
                    600: {
                      gap: "1rem",
                      padding: "2rem",
                    },
                  },
                }}
              >
                <SplideTrack>
                  {voteCandidates.map((i, idx) => (
                    <SplideSlide key={i.owner}>
                      <div>
                        <div className="mb-2">
                          <div className="flex items-center justify-between">
                            <h2 className="font-bold text-white/80 font-cursive text-lg truncate">{i.squad_name}</h2>
                            <div className="font-bold text-white/80 font-cursive text-lg">
                              {idx + 1}/{voteCandidates.length}
                            </div>
                          </div>
                          <p className="text-white/70 font-bold font-mono text-sm">
                            By{" "}
                            {i?.ownerEns ? (
                              i.ownerEns
                            ) : (
                              <>
                                <span className="hidden md:inline-block">{i.owner}</span>
                                <span className="md:hidden inline-block">{i.owner?.substr(0, 4) + "..." + i.owner?.substr(-4)}</span>
                              </>
                            )}
                          </p>
                        </div>
                        <div
                          className={`p-4 bg-white/10 backdrop-blur group rounded-md border-2 ${
                            selectedVote?.squad === i?.squad ? "border-white" : "border-transparent"
                          }`}
                        >
                          <div className="w-full aspect-square relative">
                            <div className="w-1/3 absolute left-0 top-0 aspect-square col-span-2">
                              <img src={`https://khuga.io/khuga-preview/${i.event_datas[0].token_id}.webp`} alt="" className="rounded-md" />
                            </div>
                            <div className="w-1/3 absolute right-0 top-0 aspect-square col-span-2">
                              <img src={`https://khuga.io/khuga-preview/${i.event_datas[1].token_id}.webp`} alt="" className="rounded-md" />
                            </div>
                            <div className="w-1/3 absolute top-1/3 left-1/3 aspect-square col-span-2">
                              <img src={`https://khuga.io/khuga-preview/${i.event_datas[2].token_id}.webp`} alt="" className="rounded-md" />
                            </div>
                            <div className="w-1/3 absolute left-0 bottom-0 aspect-square col-span-2">
                              <img src={`https://khuga.io/khuga-preview/${i.event_datas[3].token_id}.webp`} alt="" className="rounded-md" />
                            </div>
                            <div className="w-1/3 absolute right-0 bottom-0 aspect-square col-span-2">
                              <img src={`https://khuga.io/khuga-preview/${i.event_datas[4].token_id}.webp`} alt="" className="rounded-md" />
                            </div>
                          </div>
                          {!alreadyVoted && (
                            <div className="hidden absolute rounded-md inset-0 group-hover:bg-white/20 group-hover:backdrop-blur transition-all group-hover:flex items-center justify-center">
                              <button onClick={() => setSelectedVote(i)} className="px-6 py-2 bg-primary-800 text-white rounded hover:contrast-125">
                                Select
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </SplideSlide>
                  ))}
                </SplideTrack>
                <div className="splide__arrows">
                  <button className="group splide__arrow--prev bg-gradient-to-r from-[#221F1F] absolute top-14 bottom-0 left-0 px-2" style={{ left: 0 }}>
                    <Icon icon="akar-icons:circle-chevron-right-fill" className="w-8 h-8 group-hover:text-white" />
                  </button>
                  <button className="group splide__arrow--next bg-gradient-to-l from-[#221F1F] absolute top-14 bottom-0 right-0 px-2" style={{ right: 0 }}>
                    <Icon icon="akar-icons:circle-chevron-right-fill" className="w-8 h-8 group-hover:text-white" />
                  </button>
                </div>
              </Splide>
            </div>

            {transition((style, selectedVote) => (
              <>
                {selectedVote ? (
                  <div className="fixed bottom-4 inset-x-0 px-4 transition-all" forceMount style={style}>
                    <div className="max-w-2xl mx-auto bg-white rounded-full h-16 flex items-center justify-between px-3 group shadow-xl hover:shadow-2xl hover:scale-105 transition-all">
                      <div className="relative flex-1">
                        <div className="absolute -top-4 group-hover:top-20 transition-all inset-x-0 w-full">
                          <h3 className="font-bold text-xl font-cursive block group-hover:hidden ml-2 truncate">{selectedVote.squad_name}</h3>
                        </div>
                        <div className="absolute top-20 group-hover:-top-6 transition-all">
                          <div className="flex items-center gap-2">
                            {selectedVote.event_datas.map((i) => (
                              <img
                                key={i.token_id}
                                src={`https://khuga.io/khuga-preview/${i.token_id}.webp`}
                                alt=""
                                className="w-10 md:w-12 aspect-square rounded-full"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="shrink-0">
                        <button
                          onClick={() => handleVote()}
                          className="bg-primary-700 text-white font-cursive h-12 rounded-full px-5 shadow hover:shadow-xl hover:contrast-150"
                        >
                          Vote Now
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ))}
          </>
        )}
      </div>
    </LayoutMiawhalla>
  );
}

const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return window.location.reload();
  } else {
    return (
      <p>
        {days}d {hours}h {minutes}m {seconds}s
      </p>
    );
  }
};

export default MiawwhallaVote;
