import { Icon } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LayoutBase from "../../../layouts/LayoutBase";
import { Paper } from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { Fragment, useEffect, useState } from "react";
import { useAuthenticatedHttpClient } from "../../../utils/authenticated-api-call";
import LoadingSpinner from "../../../partials/LoadingSpinner";
import { Dialog, Transition } from "@headlessui/react";

function EditProduct() {
  const params = useParams();
  const navigate = useNavigate();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [currencies, setCurrencies] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [product, setProduct] = useState();
  const [isAddAttr, setIsAddAttr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    type: yup.string().required(),
    code: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().required(),
    weight: yup.number().required(),
    price: yup.number().required(),
    currency_id: yup.string().required(),
    stock_remaining: yup.number().required(),
  });

  const validationSchemaAttribute = yup.object().shape({
    attribute_id: yup.string().required(),
    attribute_value: yup.number().required(),
  });

  const renderError = (message) => <p className="text-sm italic text-red-600">{message}</p>;

  useEffect(() => {
    getProduct();
    getCurrency();
    getAttributes();
  }, []);

  const getProduct = async () => {
    await internalAPIClient
      .get(`/cms/marketplace/products/${params?.id}`)
      .then((res) => {
        console.log(res?.data?.data);
        setProduct(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occured");
      });
  };

  const getCurrency = async () => {
    await internalAPIClient.get(`/cms/marketplace/currencies`).then((res) => setCurrencies(res?.data?.data));
  };

  const getAttributes = async () => {
    await internalAPIClient.get(`/cms/marketplace/attributes`).then((res) => setAttributes(res?.data?.data));
  };

  const handleSubmitAttribute = async (values) => {
    setIsLoading(true);

    var formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }

    await internalAPIClient
      .post(`/cms/marketplace/products/${params?.id}/attributes`, formData)
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Success add attribute", { position: "top-right" });
          getProduct();
          setIsAddAttr(false);
        } else {
          console.log(res);
          toast.error("Failed to add attribute");
        }
      })
      .catch(async (e) => {
        console.log(e);
        toast.error("Failed to add attribute");
      });

    setIsLoading(false);
  };

  const handleSubmitData = async (values) => {
    setIsLoading(true);

    var formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }

    await internalAPIClient
      .put(`/cms/marketplace/products/${params?.id}`, formData)
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Success edit product", { position: "top-right" });
          setTimeout(() => {
            return navigate(`${process.env.PUBLIC_URL}/admin/marketplace/product`);
          }, 1500);
        } else {
          console.log(res);
          toast.error("Failed to create product");
        }
      })
      .catch(async (e) => {
        console.log(e);
        toast.error("Failed to create product");
      });

    setIsLoading(false);
  };

  return (
    <LayoutBase>
      {isLoading && <LoadingSpinner />}

      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div className="flex items-center justify-between">
          <h1 className="font-bold text-2xl mb-4 font-cursive">Edit Product</h1>
          <div className="flex items-center gap-2">
            <Link
              to="/admin/marketplace/product"
              className="px-5 py-2.5 bg-primary-800 text-white rounded hover:contrast-75 font-cursive text-xs mb-4 flex items-center gap-1"
            >
              <Icon icon="uil:arrow-left" className="w-4 h-4" />
              <span>Back</span>
            </Link>
            <button
              onClick={() => setIsAddAttr(true)}
              className="px-5 py-2.5 bg-primary-800 text-white rounded hover:contrast-75 font-cursive text-xs mb-4 flex items-center gap-1"
            >
              <Icon icon="uil:plus" className="w-4 h-4" />
              <span>Attribute</span>
            </button>
          </div>
        </div>

        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="col-span-2">
            <Paper elevation={2} className="p-4 md:p-6 mb-8">
              <Formik
                enableReinitialize
                initialValues={{
                  type: product?.type,
                  code: product?.code,
                  name: product?.name,
                  description: product?.description,
                  weight: product?.weight,
                  price: product?.price,
                  currency_id: product?.currency_id,
                  stock_remaining: product?.stock_remaining,
                  image_url: product?.image_url,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmitData(values);
                }}
              >
                {(formik) => (
                  <Form>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div className="space-y-1">
                        <label htmlFor="type">Type *</label>
                        <Field as="select" name="type" className="block w-full px-4 py-2 border border-gray-300 rounded">
                          <option value="web2">WEB2</option>
                          <option value="web3">WEB3</option>
                        </Field>
                        <ErrorMessage name="type" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="code">Code *</label>
                        <Field name="code" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="code" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="name">Name *</label>
                        <Field name="name" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="name" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="description">Description *</label>
                        <Field name="description" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="description" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="weight">Weight *</label>
                        <Field name="weight" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="weight" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="price">Price *</label>
                        <Field name="price" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="price" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="stock_remaining">Stock Remaining *</label>
                        <Field name="stock_remaining" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                        <ErrorMessage name="stock_remaining" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="currency_id">Currency *</label>
                        <Field as="select" name="currency_id" className="block w-full px-4 py-2 border border-gray-300 rounded">
                          <option value="">-- Select --</option>
                          {currencies.map((i) => (
                            <option key={i.id} value={i.id}>
                              {i.code}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name="currency_id" render={renderError} />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="image_url">Image</label>
                        <input
                          id="image_url"
                          name="image_url"
                          type="file"
                          className="block w-full px-4 py-2 border border-gray-300 rounded"
                          accept="image/*"
                          onChange={(event) => {
                            formik.setFieldValue("image_url", event.currentTarget.files[0]);
                          }}
                        />
                        <ErrorMessage name="image_url" render={renderError} />
                      </div>
                    </div>
                    <hr className="my-4" />
                    <button type="submit" className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-cursive">
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>
            </Paper>
          </div>
          <div>
            <Paper elevation={2} className="p-4 md:p-6">
              <h3 className="font-bold text-lg mb-2">Attributes</h3>
              <div className="space-y-4">
                {product?.product_attributes?.map((i) => (
                  <div key={i.id}>
                    <h6 className="text-sm">{i.attribute_name}</h6>
                    <p className="font-bold">{i.attribute_value}</p>
                  </div>
                ))}
              </div>
            </Paper>
          </div>
        </div>
      </div>

      <Transition appear show={isAddAttr} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={() => setIsAddAttr(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-gray-900">
                    Add Attribute
                  </Dialog.Title>
                  <div className="mt-4 border-t pt-4">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        attribute_id: "",
                        attribute_value: "",
                      }}
                      validationSchema={validationSchemaAttribute}
                      onSubmit={(values) => {
                        handleSubmitAttribute(values);
                      }}
                    >
                      {(formik) => (
                        <Form className="space-y-3">
                          <div className="space-y-1">
                            <label htmlFor="attribute_id">Attribute *</label>
                            <Field as="select" name="attribute_id" className="block w-full px-4 py-2 border border-gray-300 rounded">
                              <option value="">-- Select --</option>
                              {attributes.map((i) => (
                                <option key={i.id} value={i.id}>
                                  {i.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage name="attribute_id" render={renderError} />
                          </div>
                          <div className="space-y-1">
                            <label htmlFor="attribute_value">Value *</label>
                            <Field name="attribute_value" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                            <ErrorMessage name="attribute_value" render={renderError} />
                          </div>
                          <button type="submit" className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-cursive">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Toaster />
    </LayoutBase>
  );
}

export default EditProduct;
