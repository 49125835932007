import LayoutBase from "../../../layouts/LayoutBase";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { useAuthenticatedHttpClient } from "../../../utils/authenticated-api-call";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Paper } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { map } from "lodash";
import { Link } from "react-router-dom";

const columns = [
  { field: "code", headerName: "TRX Code", flex: 1, width: 180 },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    width: 180,
  },
  {
    field: "total",
    headerName: "Total",
    type: "number",
    flex: 1,
    width: 110,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    width: 150,
  },
  {
    field: "id",
    headerName: "Action",
    flex: 1,
    width: 150,
    renderCell: (params) => (
      <div>
        <Link to={`/admin/transaction/${params.value}`} className="px-4 py-2 bg-primary-800 rounded text-white">
          Detail
        </Link>
      </div>
    ),
  },
];

function AdminDataTransaction() {
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [rows, setRows] = useState([]);

  useEffect(async () => {
    await internalAPIClient
      .get(`/transactions`)
      .then((res) => {
        const mapData = map(res?.data?.data, (r) => {
          return { id: r.id, code: r.code, name: r.contact.name, email: r.contact.email, total: r.total_price_paid, status: r.status };
        });
        setRows(mapData);
      })
      .catch(async (e) => {
        console.log(e);
      });
  }, []);

  const handleDeleteTransaction = async (id) => {
    await internalAPIClient
      .delete(`/transactions/${id}`)
      .then((res) => {
        location.reload();
      })
      .catch(async (e) => {
        toast.error("Error: " + e);
      });
  };

  const handleExportData = async () => {
    axios
      .get(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/transaction-export`, {
        responseType: "blob",
        headers: {
          "x-auth-khuga": localStorage.getItem("x-auth-khuga") || "",
        },
      })
      .then((res) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(new Blob([res.data]));
        link.setAttribute("download", "transactions.xlsx");
        link.click();
      })
      .catch(async (e) => {
        toast.error("Error: " + e);
      });
  };

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div className="flex items-center justify-between">
          <h1 className="font-bold text-2xl mb-4 font-cursive">Data Transactions</h1>
          <button className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-cursive text-sm mb-4" onClick={handleExportData}>
            Export
          </button>
        </div>
        {/* <table className="w-full bg-white border">
          <thead className="bg-primary-700 text-white">
            <tr>
              <th className="text-left px-4 py-3">TRX Code</th>
              <th className="text-left px-4 py-3">Name</th>
              <th className="text-left px-4 py-3">Email</th>
              <th className="text-left px-4 py-3">Total</th>
              <th className="text-left px-4 py-3">Status</th>
              <th className="text-left px-4 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {transactions.length > 0 &&
              transactions.map((item) => (
                <tr key={item?.id}>
                  <td className="px-4 py-3">{item?.code ?? "-"}</td>
                  <td className="px-4 py-3">{item?.contact?.name ?? "-"}</td>
                  <td className="px-4 py-3">{item?.contact?.email ?? "-"}</td>
                  <td className="px-4 py-3">{item?.total_price_paid ?? "-"}</td>
                  <td className="px-4 py-3">{item?.status ?? "-"}</td>
                  <td className="px-4 py-3 flex items-center space-x-2">
                    <Menu as="div" className="relative inline-block">
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center items-center space-x-1 rounded-md bg-black/80 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                          <span>Update Status</span>
                          <Icon icon="heroicons:chevron-down" className="w-4 h-4" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                          <div className="px-1 py-1 ">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? "bg-primary-700 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                >
                                  Payment Received
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? "bg-primary-700 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                >
                                  Cancelled
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                          <div className="px-1 py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? "bg-primary-700 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                >
                                  Processed
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? "bg-primary-700 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                >
                                  Done
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    <button className="p-2.5 bg-red-500 rounded-md text-white" onClick={() => handleDeleteTransaction(item?.id)}>
                      <Icon icon="heroicons-outline:trash" />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table> */}

        <Paper style={{ width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid autoHeight rows={rows} columns={columns} pageSize={10} disableSelectionOnClick />
            </div>
          </div>
        </Paper>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default AdminDataTransaction;
