export default function LoadingBar({progress = 0}) {
  return (
    <div className="z-50 fixed inset-0 w-full h-screen flex justify-center items-center bg-black/50 backdrop-blur-md">
      <div className="w-full max-w-sm text-center text-white">
        <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 100 100" xmlSpace="preserve" className="w-24 h-24 mx-auto">
          <path fill="#fff" d="M73 50c0-12.7-10.3-23-23-23S27 37.3 27 50m3.9 0c0-10.5 8.5-19.1 19.1-19.1S69.1 39.5 69.1 50">
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              dur="1s"
              from="0 50 50"
              repeatCount="indefinite"
              to="360 50 50"
              type="rotate"
            ></animateTransform>
          </path>
        </svg>
        <p className="my-4 mx-auto">Loading your content, it'll be worth the wait</p>
        <div className="w-full h-4 bg-white/70 backdrop-blur rounded-full p-1 overflow-hidden">
          <div className="bg-primary-800 h-full rounded-full animate-pulse" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    </div>
  );
}
