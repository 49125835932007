import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import LayoutBase from "../../../layouts/LayoutBase";
import { Paper } from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useAuthenticatedHttpClient } from "../../../utils/authenticated-api-call";
import LoadingSpinner from "../../../partials/LoadingSpinner";

function CreateProduct() {
  const navigate = useNavigate();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [currencies, setCurrencies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    type: yup.string().required(),
    code: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().required(),
    weight: yup.number().required(),
    price: yup.number().required(),
    currency_id: yup.string().required(),
    stock_remaining: yup.number().required(),
    image_url: yup.string().required(),
  });

  const renderError = (message) => <p className="text-sm italic text-red-600">{message}</p>;

  useEffect(() => {
    getCurrency();
  }, []);

  const getCurrency = async () => {
    await internalAPIClient
      .get(`/cms/marketplace/currencies`)
      .then((res) => setCurrencies(res?.data?.data));
  }

  const handleSubmitData = async (values) => {
    setIsLoading(true);

    var formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }

    await internalAPIClient
      .post(`/cms/marketplace/products`, formData)
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Success create new product", { position: "top-right" });
          setTimeout(() => {
            return navigate(`${process.env.PUBLIC_URL}/admin/marketplace/product`);
          }, 1500);
        } else {
          console.log(res);
          toast.error("Failed to create product");
        }
      })
      .catch(async (e) => {
        console.log(e);
        toast.error("Failed to create product");
      });

    setIsLoading(false);
  }

  return (
    <LayoutBase>
      {isLoading && <LoadingSpinner />}

      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div className="flex items-center justify-between">
          <h1 className="font-bold text-2xl mb-4 font-cursive">Create Product</h1>
          <Link to="/admin/marketplace/product" className="px-5 py-2.5 bg-primary-800 text-white rounded hover:contrast-75 font-cursive text-xs mb-4 flex items-center gap-1">
            <Icon icon="uil:arrow-left" className="w-4 h-4" />
            <span>Back</span>
          </Link>
        </div>

        <div className="w-full">
          <Paper elevation={2} className="p-4 md:p-6">
            <Formik
              initialValues={{
                type: "web2",
                code: "",
                name: "",
                description: "",
                weight: "",
                price: "",
                currency_id: "",
                stock_remaining: "",
                image_url: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmitData(values);
              }}
            >
              {(formik) => (
                <Form>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div className="space-y-1">
                      <label htmlFor="type">Type *</label>
                      <Field as="select" name="type" className="block w-full px-4 py-2 border border-gray-300 rounded">
                        <option value="web2">WEB2</option>
                        <option value="web3">WEB3</option>
                      </Field>
                      <ErrorMessage name="type" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="code">Code *</label>
                      <Field name="code" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="code" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="name">Name *</label>
                      <Field name="name" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="name" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="description">Description *</label>
                      <Field name="description" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="description" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="weight">Weight *</label>
                      <Field name="weight" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="weight" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="price">Price *</label>
                      <Field name="price" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="price" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="stock_remaining">Stock Remaining *</label>
                      <Field name="stock_remaining" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="stock_remaining" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="currency_id">Currency *</label>
                      <Field as="select" name="currency_id" className="block w-full px-4 py-2 border border-gray-300 rounded">
                        <option value="">-- Select --</option>
                        {currencies.map((i) => (
                          <option key={i.id} value={i.id}>{i.code}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="currency_id" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="image_url">Image *</label>
                      <input
                        id="image_url"
                        name="image_url"
                        type="file"
                        className="block w-full px-4 py-2 border border-gray-300 rounded"
                        accept="image/*"
                        onChange={(event) => {
                          formik.setFieldValue("image_url", event.currentTarget.files[0]);
                        }}
                      />
                      <ErrorMessage name="image_url" render={renderError} />
                    </div>
                  </div>
                  <hr className="my-4" />
                  <button type="submit" className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-cursive">
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </Paper>
        </div>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default CreateProduct;
