
import { BeaconWallet } from "@taquito/beacon-wallet";
import { TezosToolkit } from "@taquito/taquito";
import { verifySignature, char2Bytes } from "@taquito/utils";
import { SigningType } from "@airgap/beacon-sdk";

const verifySignedMessage = (message, pubkey, signature) => {
  const verified = verifySignature('05' + char2Bytes(message), pubkey, signature);
  console.log("Verified: ", verified);
  return verified;
}

const signMessage = async (TezosWallet, message) => {
  console.log(TezosWallet);
  const response = await TezosWallet.client.requestSignPayload({
      signingType: SigningType.MICHELINE,
      payload: '05' + char2Bytes(message)
  });

  console.log(response.signature);
  return response.signature;
}

/**
 * 
 * @param {TezosToolkit} TezosInstance 
 * @param {BeaconWallet} TezosWallet 
 */
const connectWallet = async (TezosInstance, TezosWallet) => {
    const tezosPermissionRequest = await TezosWallet.client.requestPermissions({
        network: {
          type: 'mainnet',
        },
      }).catch((e) => { 
        console.log("Throw e");
        throw e;
      });

      console.log(`This request: ${tezosPermissionRequest}`);

      let connectionStatus;
      if ((connectionStatus = getConnectionStatus(TezosWallet))){
        console.log("Not connected");
        throw new Error("Wallet not connected!");
      }
      console.log(`Connection status is: ${connectionStatus}`);

      TezosInstance.setWalletProvider(TezosWallet);
}

const getConnectionStatus = async ( TezosWallet ) => {
    console.log(await TezosWallet.client.getActiveAccount());
    return (await TezosWallet.client.getActiveAccount()) ? true : false;
}

export default function ({ TezosInstance, TezosWallet }){
    return {
        connectWallet: () => connectWallet(TezosInstance, TezosWallet),
        isConnected: () => getConnectionStatus(TezosWallet),
        signMessage: (message) => signMessage(TezosWallet, message),
        verifySignedMessage
    }
}