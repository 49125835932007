import React from "react";
import ContentLoader from "react-content-loader";

const CollectionLoader = (props) => (
  <ContentLoader 
    speed={2}
    className="w-full"
    viewBox="0 0 400 440"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="10" y="10" rx="2" ry="2" width="380" height="380" /> 
    <rect x="60" y="405" rx="0" ry="0" width="280" height="15" /> 
  </ContentLoader>
);

export default CollectionLoader;
