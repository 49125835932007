import React, { useContext, useState, useEffect } from "react";
import { useCart } from "react-use-cart";
import { CheckoutContext } from "../../pages/Checkout";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";

function Review() {
  const navigate = useNavigate();
  const { items, cartTotal, emptyCart } = useCart();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(CheckoutContext);
  const [paymentChannel, setPaymentChannel] = useState();
  const [currency, setCurrency] = useState('USD');
  const [totalWeight, setTotalWeight] = useState(0);
  const [checkoutItems, setCheckoutItems] = useState([]);
  const [paymentChannelsData, setPaymentChannelData] = useState([]);

  useEffect(async () => {
    await internalAPIClient
      .get(`/transaction-payment-channels`)
      .then((res) => {
        setPaymentChannelData(res.data?.data ?? []);
      })
      .catch(async (e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    let arr = [];
    let weight = 0;
    items.map((i) => {
      arr.push({
        category_id: 1,
        item_id: i.id,
        weight: i.weight,
        currency: i.currency,
        price: i.price,
        amount: i.quantity,
        total_price: i.price * i.quantity,
      });
      weight += i.weight;
      setCurrency(i.currency);
    });
    setTotalWeight(weight);
    setCheckoutItems(arr);
  }, [items]);

  const handleOrder = async () => {
    if (paymentChannel === undefined) {
      return toast.error("Please select payment channel");
    }

    await internalAPIClient
      .post(`/transactions`, {
        currency: currency,
        items: checkoutItems,
        shipping: {
          address: formData.address,
          country_id: formData.country.value,
          country_name: formData.country.label,
          province_id: formData.province.value,
          province_name: formData.province.label,
          city_id: formData.city.value,
          city_name: formData.city.label,
          postal_code: formData.postal_code,
          total_weight: totalWeight,
          courier_id: formData.courier.courier_id,
          courier_code: formData.courier.courier_code,
          courier_package: formData.courier.courier_package,
          courier_value: formData?.shippingCost,
          courier_etd: formData.courier.courier_etd,
        },
        contact: {
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
          discord_id: formData.discord,
        },
        payment: {
          payment_channel_id: paymentChannel.id,
          payment_channel_unit: paymentChannel.unit,
          total_bill: (parseFloat(cartTotal) + parseFloat(formData?.shippingCost ?? 0)) * paymentChannel.rate,
        },
      })
      .then((res) => {
        emptyCart();
        return navigate(`${process.env.PUBLIC_URL}/transactions`);
      })
      .catch(async (e) => {
        toast.error("Error: " + e);
      });
  };

  return (
    <>
      {paymentChannelsData.length > 0 && (
        <div className="mb-8">
          <h2 className="font-bold font-cursive text-lg mb-3">Select Payment Channel</h2>
          <div className="grid grid-cols-1 md:grid-cols-3">
            {paymentChannelsData.map((item) => (
              <div
                className={`w-full border p-2 text-center cursor-pointer rounded hover:bg-gray-100 ${
                  paymentChannel?.id === item?.id ? "bg-green-50 border-primary-700" : "bg-white"
                }`}
                key={item.id}
                onClick={() => {
                  setPaymentChannel(item);
                }}
              >
                <img src={item.image_url} alt={item.name} className="w-10 h-10 rounded-full mx-auto" />
                <h5 className="font-bold">{item.name}</h5>
              </div>
            ))}
          </div>
        </div>
      )}
      <h2 className="font-bold font-cursive text-lg mb-3">Summary</h2>
      <table className="table w-full border">
        <tbody>
          <tr>
            <td className="px-3 py-1.5">Name</td>
            <td className="font-bold">{formData.name}</td>
          </tr>
          <tr>
            <td className="px-3 py-1.5">Email</td>
            <td className="font-bold">{formData.email}</td>
          </tr>
          <tr>
            <td className="px-3 py-1.5">Phone</td>
            <td className="font-bold">{formData.phone}</td>
          </tr>
          <tr>
            <td className="px-3 py-1.5">Delivery Address</td>
            <td className="font-bold">{formData.address}</td>
          </tr>
        </tbody>
      </table>
      <div className="space-x-2 mt-10">
        <button
          className="px-6 py-2 bg-gray-300 text-gray-600 rounded hover:opacity-90 font-cursive text-sm"
          type="button"
          onClick={() => setActiveStepIndex(activeStepIndex - 1)}
        >
          Prev.
        </button>

        <button onClick={handleOrder} className="px-6 py-2 bg-primary-700 text-white rounded hover:opacity-90 font-cursive text-sm">
          Order Now
        </button>
      </div>

      <Toaster />
    </>
  );
}

export default Review;
