import { Icon } from "@iconify/react";
import { useWallet } from "@solana/wallet-adapter-react";
import React, { useState, useEffect, useRef, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import routes from "../routes";
import SidebarLinkGroup from "./SidebarLinkGroup";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { disconnect } = useWallet();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? true : storedSidebarExpanded === "true");
  const [currentUser, setCurrentUser] = useContext(UserContext);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  const handleLogOut = async () => {
    localStorage.removeItem("x-auth-khuga");
    localStorage.removeItem("x-user-khuga");
    setCurrentUser(null);
    await disconnect();
    window.location.reload();
  };

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-[#196052] bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-[#196052] p-4 transition-all duration-200 ease-in-out border-r-4 border-black ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to={`${process.env.PUBLIC_URL}/`} className="block -mt-8">
            <img src={`${process.env.PUBLIC_URL}/images/navbar/logo.webp`} />
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8 font-cursive">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-white/40 font-semibold pl-3">
              <span className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6" aria-hidden="true">
                •••
              </span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">Pages</span>
            </h3>
            <ul className="mt-3 space-y-2">
              {routes
                .filter((i) => i.type == "menu" && i.roles.includes(currentUser?.roles[0]))
                .map((item) =>
                  item?.submenu === undefined ? (
                    <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === item.route && "bg-[#196052]"}`} key={item.key}>
                      <NavLink
                        end
                        to={`${process.env.PUBLIC_URL}${item.route}`}
                        className={`block hover:text-white truncate transition duration-150 ${pathname === item.route ? "text-white" : "text-white/60"}`}
                      >
                        <div className="flex items-center">
                          <div className={`${pathname === item.route ? "text-primary-300" : "text-white/40"}`}>{item.icon}</div>
                          <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            {item.name}
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  ) : (
                    <SidebarLinkGroup
                      activecondition={(pathname === "/merchandise" || pathname.includes("merchandise") || pathname.includes("transactions")) && !pathname.includes("admin")}
                      key={item.key}
                    >
                      {(handleClick, open) => {
                        return (
                          <React.Fragment>
                            <a
                              href="#0"
                              className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                ((pathname === "/merchandise" || pathname.includes("merchandise") || pathname.includes("transactions")) && !pathname.includes("admin")) && "hover:text-slate-200"
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                              }}
                            >
                              <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                  <Icon icon="heroicons-outline:shopping-bag" className="w-6 h-6 text-white/40" />
                                  <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-white/60">
                                    {item.name}
                                  </span>
                                </div>
                                <div className="flex shrink-0 ml-2">
                                  <svg className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"}`} viewBox="0 0 12 12">
                                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                  </svg>
                                </div>
                              </div>
                            </a>
                            <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                              <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                                {item.submenu.map((subItem) => (
                                  <li className="mb-3 mt-4 last:mb-0" key={subItem.key}>
                                    <NavLink end to={subItem.route} className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate">
                                      <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                        {subItem.name}
                                      </span>
                                    </NavLink>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </React.Fragment>
                        );
                      }}
                    </SidebarLinkGroup>
                  )
                )}

              {/* Voucher Generator */}
              {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === "/voucher-generator" && "bg-[#196052]"}`}>
                <NavLink
                  end
                  to={`${process.env.PUBLIC_URL}/voucher-generator`}
                  className={`block hover:text-white truncate transition duration-150 ${
                    pathname === "/voucher-generator" ? "text-white" : "text-white/60"
                  }`}
                >
                  <div className="flex items-center">
                    <Icon icon="heroicons-outline:cube-transparent" className="shrink-0 h-6 w-6" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Voucher Generator
                    </span>
                  </div>
                </NavLink>
              </li> */}
              {/* Self-Generated Ticket */}
              {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === "/self-ticket" && "bg-[#196052]"}`}>
                <NavLink
                  end
                  to={`${process.env.PUBLIC_URL}/self-ticket`}
                  className={`block hover:text-white truncate transition duration-150 ${pathname === "/self-ticket" ? "text-white" : "text-white/60"}`}
                >
                  <div className="flex items-center">
                    <Icon
                      icon="heroicons-outline:ticket"
                      className={`shrink-0 h-6 w-6 ${pathname === "/self-ticket" ? "text-primary-300" : "text-white/40"}`}
                    />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Self-Generated Ticket
                    </span>
                  </div>
                </NavLink>
              </li> */}

              {/* Logout */}
              <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === "/logout" && "bg-[#196052]"}`}>
                <NavLink
                  end
                  to={`${process.env.PUBLIC_URL}/`}
                  onClick={() => handleLogOut()}
                  className={`block hover:text-white truncate transition duration-150 ${pathname === "/logout" ? "text-white" : "text-white/60"}`}
                >
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        className={`${pathname === "/logout" ? "text-primary-300" : "text-white/40"}`}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Logout</span>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                <path className="text-slate-400" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
