import { Link, useNavigate, useParams } from "react-router-dom";
import LayoutMiawhalla from "../../layouts/LayoutMiawhalla";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { useEffect, useRef, useState } from "react";
import { useTransition, animated } from "@react-spring/web";
import toast from "react-hot-toast";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import moment from "moment/moment";
import { getEnsName } from "../../web3/eth/ens";
import { groupBy, map } from "lodash";
import Countdown from "react-countdown";
import LoadingSpinner from "../../partials/LoadingSpinner";
import { Icon } from "@iconify/react";
import axios from "axios";

function MiawwhallaVoteCandidate() {
  const params = useParams();
  const navigate = useNavigate();
  const { internalAPIClient } = useAuthenticatedHttpClient();

  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState();
  const [voteCandidates, setVoteCandidates] = useState([]);

  useEffect(async () => {
    await internalAPIClient
      .get(`/missions/${params?.id}`)
      .then((res) => {
        if (res?.data?.status === "error") {
          toast.error(res?.data?.message);
          setTimeout(() => {
            return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
          }, 3000);
        }

        setEvent(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occured");
      });
  }, []);

  useEffect(async () => {
    if (event) {
      await axios
        .get(`https://playtoearn.host:3001/local/api/cms/missions/9791ca67-70d0-491d-a564-16b342ea2860/assets?disqualified_status=0`)
        .then((res) => {
          if (res?.data?.status === "error") {
            toast.error(res?.data?.message);
            setTimeout(() => {
              return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
            }, 3000);
          }

          console.log(res?.data?.data);

          const mapOwner = map(res?.data?.data, (i) => {
            let response = i;
            getEnsName(i.owner).then((res) => (response = { ...i, ownerEns: res }));

            return response;
          });

          console.log(mapOwner);

          setVoteCandidates(mapOwner);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error occured");
        });
    }
  }, [event]);

  return (
    <LayoutMiawhalla className="bg-[#221F1F]">
      {loading && <LoadingSpinner />}

      <div className="relative container mx-auto px-4">
        <div className="absolute right-4 top-0 z-20">
          <Link to={`${process.env.PUBLIC_URL}/miawwhalla/event`}>
            <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/close.webp`} alt="" className="w-8 h-8 hover:scale-105" />
          </Link>
        </div>

        <div className="text-center pt-16 md:pt-0">
          <h1 className="font-cursive text-3xl text-amber-400">List Candidate</h1>
        </div>

        <div className="max-w-2xl mx-auto mt-4 space-y-6 mb-20">
          {voteCandidates.map((i, idx) => (
            <div key={idx}>
              <h2 className="font-bold text-white/80 font-cursive text-lg"><span className="text-amber-500">({idx+1}/{voteCandidates.length})</span> {i.name}</h2>
              <div className="mb-2 text-white/70 font-bold font-mono text-sm">
                <span className="hidden md:inline-block">By {i.owner}</span>
                <span className="md:hidden inline-block">By {i.owner?.substr(0, 4) + "..." + i.owner?.substr(-4)}</span>
              </div>
              <div className="grid grid-cols-5 bg-white/10 p-4 rounded-md gap-4">
                {i.event_datas.map((d) => (
                  <div>
                    <img src={`https://khuga.io/khuga-preview/${d.token_id}.webp`} alt="" className="w-full rounded-md" />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </LayoutMiawhalla>
  );
}

export default MiawwhallaVoteCandidate;
