import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { CheckoutContext } from "../../pages/Checkout";
import * as yup from "yup";
import Select from "react-select";
import { useState, useEffect } from "react";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import toast, { Toaster } from "react-hot-toast";
import { useCart } from "react-use-cart";

function ShippingAddress() {
  const { items } = useCart();
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(CheckoutContext);
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [rate, setRate] = useState(15000);
  const [totalWeight, setTotalWeight] = useState(0);

  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);

  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");

  const [trxCouriers, setTrxCouriers] = useState([]);
  const [selectedTrxCourier, setSelectedTrxCourier] = useState("");
  const [couriers, setCouriers] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState("");

  console.log(country);
  // Set total weight
  useEffect(() => {
    let weight = 0;
    items.map((i) => {
      weight += i.weight;
    });
    setTotalWeight(weight);
  }, [items]);

  // Get IDR -> USD currency
  useEffect(async () => {
    await internalAPIClient
      .get(`/transaction-currency`)
      .then((res) => {
        let result = res.data?.data ?? [];
        setRate(result[0].rate ?? 15000);
      })
      .catch((e) => {
        toast.error("Error get currency: " + e);
      });

    const arr = [];
    await internalAPIClient
      .get(`/transaction-countries`)
      .then((res) => {
        let result = res.data?.data ?? [];
        result.map((item) => {
          return arr.push({ value: item.id, label: item.name_en });
        });
      })
      .catch((e) => {
        toast.error("Error get counties: " + e);
      });
    setCountries(arr);
  }, []);

  // Get provinces data
  useEffect(async () => {
    const arr = [];
    await internalAPIClient
      .get(`/freight/get-provinces`)
      .then((res) => {
        let result = res.data?.data ?? [];
        result.map((item) => {
          return arr.push({ value: item.province_id, label: item.province });
        });
      })
      .catch((e) => {
        toast.error("Error get provinces: " + e);
      });
    setProvinces(arr);
  }, [country]);

  // Get cities data
  useEffect(async () => {
    if (province !== "") {
      const arr = [];
      await internalAPIClient
        .get(`/freight/get-cities?province_id=${province}`)
        .then((res) => {
          let result = res.data?.data ?? [];
          result.map((item) => {
            return arr.push({ value: item.city_id, label: item.city_name });
          });
        })
        .catch((e) => {
          toast.error("Error get cities: " + e);
        });
      setCities(arr);
    }
  }, [province]);

  // Get trx courier
  useEffect(async () => {
    if (city !== "") {
      await internalAPIClient
        .get(`/transaction-couriers?is_domestic=${parseInt(country) === 104 ? '1' : '0'}`)
        .then((res) => {
          let result = res.data?.data ?? [];
          setTrxCouriers(result);
        })
        .catch((e) => {
          toast.error("Error get trx courier: " + e);
        });
    }
  }, [city]);

  // Get freight cost
  useEffect(async () => {
    if (selectedTrxCourier !== "") {
      await internalAPIClient
        .post(`/freight/get-cost`, {
          origin_city_id: "152",
          destination_city_id: city,
          weight: totalWeight,
          courier_code: selectedTrxCourier?.code,
        })
        .then((res) => {
          let result = res.data?.data ?? [];
          setCouriers(result);
        })
        .catch((e) => {
          toast.error("Error get freight cost: " + e);
        });
    }
  }, [selectedTrxCourier]);

  const handleSelectCourier = (code, service, cost, etd) => {
    setFormData({ ...formData, ...{ shippingCost: parseFloat((cost / rate).toFixed(2)) } });
    setSelectedCourier({
      courier_id: selectedTrxCourier?.id,
      courier_code: code,
      courier_package: service,
      courier_value: cost,
      courier_etd: etd,
    });
  };

  const renderError = (message) => <p className="text-sm italic text-red-600">{message}</p>;

  const ValidationSchema = yup.object().shape({
    address: yup.string().required(),
    city: yup.object().required("city is a required field"),
    country: yup.object().required("country is a required field"),
    province: yup.object().required("province is a required field"),
    postal_code: yup.string().required(),
  });

  return (
    <>
      <Formik
        initialValues={{
          address: formData.address || "",
          city: formData.city || "",
          country: formData.country || "",
          province: formData.province || "",
          postal_code: formData.postal_code || "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          if (selectedCourier === "") {
            toast.error("Please select courier service");
          } else {
            const data = { ...formData, ...values, ...{ courier: selectedCourier } };
            setFormData(data);
            setActiveStepIndex(activeStepIndex + 1);
          }
        }}
      >
        <Form className={`space-y-4`}>
          <div className="space-y-2">
            <label htmlFor="country">Country</label>
            <Field name="country">
              {({ field, form }) => (
                <Select
                  options={countries}
                  placeholder="Select"
                  value={form.values.country}
                  name="country"
                  onChange={(selectedOption) => {
                    form.setFieldValue("country", selectedOption);
                    setCountry(selectedOption.value);
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="country" render={renderError} />
          </div>
          <div className="space-y-2">
            <label htmlFor="province">State/Province</label>
            <Field name="province">
              {({ field, form }) => (
                <Select
                  options={provinces}
                  placeholder="Select"
                  value={form.values.province}
                  name="province"
                  onChange={(selectedOption) => {
                    form.setFieldValue("province", selectedOption);
                    setProvince(selectedOption.value);
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="province" render={renderError} />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label htmlFor="city">City</label>
              <Field name="city">
                {({ field, form }) => (
                  <Select
                    options={cities}
                    placeholder="Select"
                    value={form.values.city}
                    name="city"
                    onChange={(selectedOption) => {
                      form.setFieldValue("city", selectedOption);
                      setCity(selectedOption.value);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage name="city" render={renderError} />
            </div>
            <div className="space-y-2">
              <label htmlFor="postal_code">Postal Code</label>
              <Field name="postal_code" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
              <ErrorMessage name="postal_code" render={renderError} />
            </div>
          </div>
          <div className="space-y-2">
            <label htmlFor="address">Address</label>
            <Field name="address" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." as="textarea" />
            <ErrorMessage name="address" render={renderError} />
          </div>

          {trxCouriers.length > 0 && (
            <div>
              <h4 className="font-cursive text-lg mb-4">Select Courier Services</h4>
              {trxCouriers.map((i) => (
                <div
                  key={i.code}
                  className={`border p-2 cursor-pointer flex items-center space-x-3 ${
                    selectedTrxCourier?.code === i.code ? "bg-green-50 border-primary-700" : "hover:bg-gray-50"
                  }`}
                  onClick={() => setSelectedTrxCourier(i)}
                >
                  <div className="w-8">
                    <img src={i.image_url} alt="Logo" className="h-4 mx-auto" />
                  </div>
                  <h4 className="font-bold mb-2">{i.name}</h4>
                </div>
              ))}
            </div>
          )}

          {couriers.length > 0 && (
            <div>
              {couriers.map((group) => (
                <div key={group.code}>
                  <h4 className="font-bold mb-2">{group.name}</h4>
                  <div className="space-y-2">
                    {group.costs.length > 0 &&
                      group.costs.map((item) => (
                        <div
                          key={item.service}
                          className={`border p-3 cursor-pointer ${
                            selectedCourier?.courier_code === group.code && selectedCourier?.courier_package === item.service
                              ? "bg-green-50 border-primary-700"
                              : "hover:bg-gray-50"
                          }`}
                          onClick={() => handleSelectCourier(group.code, item.service, item.cost[0]?.value, item.cost[0]?.etd)}
                        >
                          <div className="flex justify-between">
                            <h6>
                              {item.service} ({item.cost[0]?.etd} days)
                            </h6>
                            <p className="text-xs">{item.description}</p>
                          </div>
                          <p className="font-bold">${(item.cost[0]?.value / rate).toFixed(2)}</p>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="space-x-2">
            <button
              className="px-6 py-2 bg-gray-300 text-gray-600 rounded hover:opacity-90 font-cursive text-sm"
              type="button"
              onClick={() => setActiveStepIndex(activeStepIndex - 1)}
            >
              Prev.
            </button>
            <button className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-cursive text-sm" type="submit">
              Next
            </button>
          </div>
        </Form>
      </Formik>
      <Toaster />
    </>
  );
}

export default ShippingAddress;
