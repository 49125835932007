import { Icon } from "@iconify/react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { FavoriteCollectionsContext } from "../../context/FavoriteCollectionsContext";
import { UserContext } from "../../context/UserContext";
import { animated, easings, useSpring } from "@react-spring/web";
import MiawwhallaCollections from "./Collections";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import { filter, first } from "lodash";
import { Menu, Transition } from "@headlessui/react";
import ExperiencePoint from "./ExperiencePoint";

function ProfileBox() {
  const metamaskAddress = localStorage.getItem("metamask_address");
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [showModal, setShowModal] = useState(false);
  const [currentUser, setCurrentUser] = useContext(UserContext);
  const [favCollections, setFavCollections, selectedFavCollection, setSelectedFavCollection] = useContext(FavoriteCollectionsContext);
  const [emptyFavorite, setEmptyFavorite] = useState([]);

  useEffect(() => {
    if (currentUser?.avatar_id) {
      const asProfilePicture = filter(favCollections, (i) => i.metadata.id == currentUser.avatar_id);
      const notProfilePicture = filter(favCollections, (i) => i.metadata.id != currentUser.avatar_id);
      const orderedFavCollections = [...asProfilePicture, ...notProfilePicture];
      if (orderedFavCollections.length > 0 && first(orderedFavCollections).id !== first(favCollections).id) {
        setFavCollections(orderedFavCollections);
        setSelectedFavCollection(first(orderedFavCollections));
      } else if (!selectedFavCollection) {
        setSelectedFavCollection(first(favCollections));
      }
    } else {
      setSelectedFavCollection(first(favCollections));
    }
  }, [currentUser, favCollections]);

  useEffect(() => {
    let emptyFav = [];
    for (let i = 0; i < 5 - favCollections.length; i++) {
      emptyFav.push(<div key={`emptyFav-${i}`} className="w-full lg:w-[90%] aspect-square rounded-xl bg-[#7575A0] cursor-not-allowed"></div>);
    }
    setEmptyFavorite(emptyFav);
  }, [favCollections]);

  const [springs, api] = useSpring(() => ({
    from: { rotate: 0 },
  }));

  const handleSetProfilePicture = (id) => {
    setCurrentUser((prevState) => ({ ...prevState, ...{ avatar: `https://khuga.io/khuga-preview/${id}.webp`, avatar_id: id } }));
    internalAPIClient.post(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/nft-ownerships/${metamaskAddress}/profile-picture/${id}`).catch((e) => {
      console.log(e);
    });
  };

  const handleSelectCollection = (i) => {
    setSelectedFavCollection(i);
    api.start({
      from: {
        rotate: 0,
      },
      to: [{ rotate: -7 }, { rotate: 7 }, { rotate: 0 }],
      config: {
        duration: 250,
        easing: easings.linear,
      },
    });
  };

  return (
    <>
      <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/profile-box.webp`} alt="Profile" className="w-full" />
      {/* Sidebar */}
      <div
        className="absolute left-[6%] top-[5%] w-[20%] h-full bg-contain bg-no-repeat"
        style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/miawwhalla/profile-bar.webp')` }}
      >
        <div className="absolute top-[7%] left-[15%] lg:left-[19%] w-[60%] h-3/4">
          {/* <div className="space-y-3 mb-4 h-[94%] scrollbar-thin scrollbar-thumb-[#ADADF0] scrollbar-track-[#7575A0] scrollbar-rounded-lg ml-2"> */}
          <div className="space-y-1.5 lg:space-y-3 mb-1 lg:mb-4 h-[94%] overflow-hidden ml-2">
            {favCollections.length > 0 &&
              favCollections.map((i, idx) => (
                <div className="relative" key={`fav-${idx}`}>
                  {i.join_status === "JOINED" && i.disqualified_status !== 1 && (
                    <div className="absolute right-2.5 top-1.5 pointer-events-none">
                      <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/sword.webp`} alt="Sword" className="w-6 aspect-square" />
                    </div>
                  )}
                  <img
                    src={`https://khuga.io/khuga-preview/${i.metadata.id}.webp`}
                    alt={i.metadata.name}
                    // key={i.id}
                    className={`w-full lg:w-[90%] aspect-square rounded-md cursor-pointer hover:border-4 border-transparent hover:border-[#ADADF0] transition-all ${
                      i.id == selectedFavCollection?.id && "border-4 border-[#ADADF0]"
                    }`}
                    onClick={() => handleSelectCollection(i)}
                  />
                </div>
              ))}
            {emptyFavorite}
          </div>
          <button
            onClick={() => setShowModal(true)}
            className="ml-1 lg:ml-0 w-full font-cursive text-xs lg:text-sm bg-[#121F58] text-white rounded-md py-1 inline-block hover:scale-105 transition-all"
          >
            Show All
          </button>
        </div>
      </div>

      {/* Main Area */}
      <animated.div
        className="absolute top-[10%] left-[28%] w-[65%] origin-top"
        style={{
          ...springs,
        }}
      >
        {selectedFavCollection?.metadata ? (
          <>
            {selectedFavCollection.join_status === "JOINED" && selectedFavCollection.disqualified_status !== 1 && (
              <div className="absolute right-4 z-10 top-4 pointer-events-none w-[15%]">
                <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/sword.webp`} alt="Sword" className="w-full aspect-square" />
              </div>
            )}
            <div className={`w-full relative aspect-square overflow-hidden ${selectedFavCollection?.metadata?.legendary && "img-legendary"}`}>
              <img src={selectedFavCollection?.metadata?.image_url} alt={selectedFavCollection?.metadata?.name} className="w-full" />
            </div>

            {/* Experience Point */}
            <div className="absolute right-2 bottom-2 z-10">
              <ExperiencePoint className="w-56" id={selectedFavCollection?.nft_id} />
            </div>
          </>
        ) : (
          <div className="w-full aspect-square flex items-center justify-center">
            <div className="text-center">
              <h4 className="font-bold text-lg font-cursive mb-4">
                You don't have any
                <br />
                favorite collection
              </h4>
              <button
                onClick={() => setShowModal(true)}
                className="w-full font-cursive text-sm bg-[#121F58] text-white rounded-md px-4 py-2 inline-block hover:scale-105 transition-all"
              >
                Select your Collections
              </button>
            </div>
          </div>
        )}
      </animated.div>

      {/* Set as favorite */}
      {/* <div className="absolute top-0 right-4">
        <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/attr.webp`} alt="Attr" className="w-20" />

        <div
          className="absolute inset-0 flex justify-center items-center pt-3 cursor-pointer hover:scale-110 transition-all active:scale-100"
          onClick={() => handleSetProfilePicture(selectedFavCollection?.collection?.image)}
        >
          {currentUser?.avatar == selectedFavCollection?.collection?.image ? (
            <Icon icon="heroicons-solid:heart" className="w-10 h-10 text-red-600" />
          ) : (
            <Icon icon="heroicons-outline:heart" className="w-10 h-10" />
          )}
        </div>
      </div> */}

      <div className="absolute bottom-2 right-12">
        <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/aksen.webp`} className="w-2/3" alt="" />
      </div>

      {selectedFavCollection?.metadata?.name && (
        <div className="absolute top-0 right-20">
          <Menu as="div" className="relative">
            <Menu.Button
              className="relative z-10 cursor-pointer hover:scale-105 active:scale-100 transition-all w-40 h-10 lg:w-72 lg:h-14 bg-contain bg-no-repeat text-center flex items-center justify-center font-cursive text-xl pt-0"
              style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/miawwhalla/bg-name.webp')` }}
            >
              <h6 className="text-[#141F58] absolute top-2 lg:top-4 text-xs lg:text-base">{selectedFavCollection?.metadata?.name}</h6>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-y-0"
              enterTo="transform opacity-100 scale-y-100"
              leave="transition ease-in duration-150"
              leaveFrom="transform opacity-100 scale-y-100"
              leaveTo="transform opacity-0 scale-y-0"
            >
              <Menu.Items className="absolute z-40 top-8 lg:top-12 left-0 lg:left-4 w-48 lg:w-64 bg-white/60 backdrop-blur rounded-xl origin-top">
                <div className="px-4 py-3 lg:px-8 lg:py-6 space-y-1 text-[#141F58]">
                  {selectedFavCollection?.metadata?.race && (
                    <Menu.Item>
                      <div className="-space-y-1">
                        <h6 className="text-sm font-medium">Race:</h6>
                        <p className="font-bold">{selectedFavCollection?.metadata?.race}</p>
                      </div>
                    </Menu.Item>
                  )}
                  {selectedFavCollection?.metadata?.cloth && (
                    <Menu.Item>
                      <div className="-space-y-1">
                        <h6 className="text-sm font-medium">Cloth:</h6>
                        <p className="font-bold">{selectedFavCollection?.metadata?.cloth}</p>
                      </div>
                    </Menu.Item>
                  )}
                  {selectedFavCollection?.metadata?.eyes && (
                    <Menu.Item>
                      <div className="-space-y-1">
                        <h6 className="text-sm font-medium">Eyes:</h6>
                        <p className="font-bold">{selectedFavCollection?.metadata?.eyes}</p>
                      </div>
                    </Menu.Item>
                  )}
                  {selectedFavCollection?.metadata?.hand && (
                    <Menu.Item>
                      <div className="-space-y-1">
                        <h6 className="text-sm font-medium">Hand:</h6>
                        <p className="font-bold">{selectedFavCollection?.metadata?.hand}</p>
                      </div>
                    </Menu.Item>
                  )}
                  {selectedFavCollection?.metadata?.head && (
                    <Menu.Item>
                      <div className="-space-y-1">
                        <h6 className="text-sm font-medium">Head:</h6>
                        <p className="font-bold">{selectedFavCollection?.metadata?.head}</p>
                      </div>
                    </Menu.Item>
                  )}
                  {selectedFavCollection?.metadata?.mouth && (
                    <Menu.Item>
                      <div className="-space-y-1">
                        <h6 className="text-sm font-medium">Mouth:</h6>
                        <p className="font-bold">{selectedFavCollection?.metadata?.mouth}</p>
                      </div>
                    </Menu.Item>
                  )}
                  {selectedFavCollection?.metadata?.background && (
                    <Menu.Item>
                      <div className="-space-y-1">
                        <h6 className="text-sm font-medium">Background:</h6>
                        <p className="font-bold">{selectedFavCollection?.metadata?.background}</p>
                      </div>
                    </Menu.Item>
                  )}
                  {selectedFavCollection?.metadata?.legendary && (
                    <Menu.Item>
                      <div className="-space-y-1">
                        <h6 className="text-sm font-medium">Legendary:</h6>
                        <p className="font-bold">{selectedFavCollection?.metadata?.legendary}</p>
                      </div>
                    </Menu.Item>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <button
            className="absolute z-10 right-4 top-1.5 cursor-pointer hover:scale-110 transition-all active:scale-100"
            onClick={() => handleSetProfilePicture(selectedFavCollection?.metadata?.id)}
          >
            {currentUser?.avatar == `https://khuga.io/khuga-preview/${selectedFavCollection?.metadata?.id}.webp` ? (
              <Icon icon="heroicons-solid:heart" className="w-5 h-5 lg:w-10 lg:h-10 text-red-600" />
            ) : (
              <Icon icon="heroicons-outline:heart" className="w-5 h-5 lg:w-10 lg:h-10" />
            )}
          </button>
        </div>
      )}

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="absolute inset-0 bg-black/50 backdrop-blur"></div>
          <div className="relative w-full max-w-6xl mx-auto px-4 h-[calc(100vh_-_20%)]">
            <button
              className="absolute z-20 -right-1 -top-6 bg-red-500 p-3 rounded-full text-white shadow hover:shadow-xl transition-all"
              onClick={() => setShowModal(false)}
            >
              <Icon icon="heroicons:x-mark" className="w-7 h-7" />
            </button>
            <div className="bg-[#D2D5E2]/70 rounded-lg border border-gray-600 h-full">
              <MiawwhallaCollections />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProfileBox;
