import { Icon } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LayoutBase from "../../../layouts/LayoutBase";
import { Paper } from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useAuthenticatedHttpClient } from "../../../utils/authenticated-api-call";
import LoadingSpinner from "../../../partials/LoadingSpinner";
import moment from "moment";

function AdminTransactionDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [transaction, setTransaction] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    await internalAPIClient
      .get(`/transactions/${params?.id}`)
      .then((res) => {
        console.log(res?.data?.data);
        setTransaction(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occured");
      });
  }, []);

  return (
    <LayoutBase>
      {isLoading && <LoadingSpinner />}

      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-2 space-y-4">
            <Paper className="p-4">
              <h4 className="font-bold text-xl mb-4">Contact</h4>
              <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                <Data label="Name" desc={transaction?.contact?.name} />
                <Data label="Email" desc={transaction?.contact?.email} />
                <Data label="Phone" desc={transaction?.contact?.phone} />
                <Data label="Discord ID" desc={transaction?.contact?.discord_id} />
              </div>
            </Paper>
            <Paper className="p-4">
              <h4 className="font-bold text-xl mb-4">Items</h4>
              <div className="space-y-2">
                {transaction?.items.map((i) => (
                  <div className="border rounded p-4">
                    <h6 className="font-bold text-lg">Item {i.id}</h6>
                    <div className="flex items-center gap-2 text-sm">
                      <p>{i.amount} x {i.currency} {i.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Paper>
            <Paper className="p-4">
              <h4 className="font-bold text-xl mb-4">Shipping</h4>
              <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                <Data label="Country" desc={transaction?.shipping?.country_name} />
                <Data label="Province" desc={transaction?.shipping?.province_name} />
                <Data label="City" desc={transaction?.shipping?.city_name} />
                <Data label="Postal Code" desc={transaction?.shipping?.postal_code} />
                <div className="col-span-2">
                  <Data label="Address" desc={transaction?.shipping?.address} />
                </div>
                <Data label="Courier Code" desc={transaction?.shipping?.courier_code} />
                <Data label="Courier Package" desc={transaction?.shipping?.courier_package} />
                <Data label="Total Weight" desc={transaction?.shipping?.total_weight} />
              </div>
            </Paper>
          </div>
          <div>
            <Paper className="p-4 space-y-2">
              <Data label="Created at" desc={new Date(transaction?.createdAt).toLocaleString()} />
              <Data label="Status" desc={transaction?.status} />
              <Data label="Total Price Items" desc={transaction?.total_price_items} />
              <Data label="Total Price Shipping" desc={transaction?.total_price_shipping} />
              <Data label="Total Price Paid" desc={transaction?.total_price_paid} />
            </Paper>
          </div>
        </div>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default AdminTransactionDetail;

function Data({label, desc}) {
  return (
    <div>
      <p className="text-sm text-gray-500">{label}</p>
      <h6 className="font-semibold text-gray-700">{desc}</h6>
    </div>
  );
}
