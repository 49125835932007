import LayoutHome from "../layouts/LayoutHome";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { useRef } from "react";
import { useEffect } from "react";

const teams = [
  {
    name: "Master Wong",
    title: "Co-Founder",
    desc: 'Master Wong "the Shiro Demon" Kobayashi, a cat lover, one of the founders, and also the technical lead. I am a seasoned IT guy with 12+ years of experience in the field of Application Engineering, Project Management, and Disaster Recovery.',
    photo: "aji.webp",
  },
  {
    name: "G-Paw",
    title: "Co-Founder",
    desc: "Esth a.k.a G-Paw, a robot and a veteran gamer, one of the founders that lead socials, marketing strategy, and operational. Been 2 years in Crypto and NFT space as creator, collector, trader and 10+ years of experience on interactive digital media and video game industry.",
    photo: "g-paw.webp",
  },
  {
    name: "Alip",
    title: "Main Artist",
    desc: "Anime lovers, passionate with art and has been working for more than 9 years in the creative fields. Currently, I focus my energy on founding Khuga as my special project. I hope you love my art! Silence means work!",
    photo: "alip.webp",
  },
  {
    name: "KID",
    title: "Community Manager",
    desc: "Kid, a Psycho Cat Community Manager. Make some concepts for the sake of community. Engineering Manager for some years and professional programmer for 7+ years experience. I hate manual work, so I made Cyborg Khuga to help community grow.",
    photo: "kid.webp",
  },
  {
    name: "Kitten_Thrower",
    title: "Motion Graphic",
    desc: "Im the one who create some video, motion graphic, and a little bit animation in this team, experienced in creative industries in 10+ years and have been working with vary clients.",
    photo: "kitten-thrower.webp",
  },
  {
    name: "Ola",
    title: "Copywriter",
    desc: "I am a new cat on this team who help with copywriting. I'm a full-time lifelong learner. Find me playing video games, reading books, or crocheting after a busy day.",
    photo: "nyucat.webp",
  },
  {
    name: "Bar-Z",
    title: "Illustration Designer",
    desc: "Making some illustrations, design and layouting. Freelancing in graphic design and illustration for 4 years. Interested in graphic design, branding and marketing. I love cats very much. Nice to meet you!",
    photo: "bar-z.webp",
  },
  {
    name: "Gekido",
    title: "Motion Graphic",
    desc: "Part of crazy cat who has more than 13+ years expertise in graphic design, motion graphics and video editor and video game is my hobby, nice to meet you miaaaww...",
    photo: "gekido.webp",
  },
  {
    name: "Jim",
    title: "Animator",
    desc: "Part of Crazy Cats who helped the team to bring khugas alive through motion works. Experienced in traditional 2d animation field for 4+ years. I love music, books and anime, also a guitarist as a hobby.",
    photo: "jim.webp",
  },
  {
    name: "Tail",
    title: "Front-End Developer",
    desc: "Part of the crazy cats that makes websites look great. Experienced as Front-End Developer since 5 years ago. I love code and watching movie. Nice to meet you!",
    photo: "fecat.webp",
  },
  {
    name: "Oyrus",
    title: "Back-End Developer",
    desc: "Having a long background in the world of e-commerce, finance & insurtech, as time goes by and the trend of technological developments, interest in blockchain, crypto & nft technology is getting bigger and more challenging.",
    photo: "oyrus.webp",
  },
  {
    name: "keyboard_abuser",
    title: "Head of Technology",
    desc: "A tech prodigy who has been coding since the first day of his life. Has a computer as his second wife.",
    photo: "keyboard_abuser.webp",
  },
];

const characters = [
  {
    name: "shiro",
    desc: "Shiro is one of five races that live in Khugaverse. The 2h sword is their main melee weapon. Shiro is a kind hearted hero with a low-level superpower. However, they are a fighter you can rely on to protect you and your kingdom. Loyally and unconditionally.",
  },
  {
    name: "kuro",
    desc: "Kuro is a berserkers warrior with great strength, who fought on the battlefield with unlimited courage. However, Kuro is a type of opportunist fighter who will protect their owner in order to receive some reward in return.",
  },
  {
    name: "fatp",
    desc: "Fat-P generally likes to laze around in their pajamas. However, don't interrupt their precious leisure time if you don't want to get hurt. They can be very mad and don't hesitate to knock you down using a bazooka on the battlefield.",
  },
  {
    name: "spinx",
    desc: "Spin-X is an introvert. They have a lower drive to socialize. They like to collect some strange things to be utilized as a long-range weapon. They're attacking the enemy using poison attack with heavy magic damage that can be done from long range.",
  },
  {
    name: "saber",
    desc: "Saber is a samurai warrior whose skill with a sword-fighting to protect their lord. The strength of Katana greatly complements Saber's combat skills. Katana is the iconic sword with a curved blade that made it easier for Saber to slash their enemy slowly and silently.",
  },
];

function About() {
  const mainRef = useRef();
  const thumbRef = useRef();

  useEffect(() => {
    if (mainRef.current && thumbRef.current && thumbRef.current.splide) {
      mainRef.current.sync(thumbRef.current.splide);
    }
  }, []);

  return (
    <LayoutHome>
      <div id="about" className="relative bg-white">
        <div className="w-full h-[18.7rem] bg-cover bg-bottom" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about.svg)` }}></div>
        <img
          src={`${process.env.PUBLIC_URL}/images/logo/logo-green.webp`}
          alt="Logo"
          className="mx-auto h-32 md:h-44 lg:h-[15rem] -mt-16 md:mt-[-7.5rem]"
        />
        <div className="max-w-4xl mx-auto px-4 mb-20">
          <div className="mt-10 text-center font-light space-y-4 text-black">
            <p>
              Khuga is an NFT project where the 5,555 fighter cats unite in Khugaverse. They are coming on Ethereum and will divide into 5 elite races built for
              those who want to be part of The Miawwhalla, a place to walk on an adventurous journey, play some fun battles and quests, and get exciting rewards.
            </p>
            <p>
              We come with the tagline WAGFI (We’re All Gonna Fight It). It is an interpretation of the fighting spirit with in ourselves that is aligned with
              the current NFT and crypto market situation.
            </p>
          </div>
        </div>

        <div className="max-w-5xl mx-auto px-4 mb-20">
          <Splide
            className="thumbnail-container mb-8 md:mb-0 z-10 px-8 lg:px-12"
            ref={thumbRef}
            aria-label="Thumbnail"
            options={{
              type: "slide",
              rewind: true,
              gap: "0.25rem",
              pagination: false,
              fixedWidth: "4.5rem",
              fixedHeight: "4.5rem",
              focus: "left",
              isNavigation: true,
              breakpoints: {
                640: {
                  fixedWidth: "3.5rem",
                  fixedHeight: "3.5rem",
                },
              },
            }}
          >
            <SplideSlide className="thumbnail">
              <img src={`${process.env.PUBLIC_URL}/images/about/char-1.webp`} alt="Char 1" />
            </SplideSlide>
            <SplideSlide className="thumbnail">
              <img src={`${process.env.PUBLIC_URL}/images/about/char-2.webp`} alt="Char 2" />
            </SplideSlide>
            <SplideSlide className="thumbnail">
              <img src={`${process.env.PUBLIC_URL}/images/about/char-3.webp`} alt="Char 3" />
            </SplideSlide>
            <SplideSlide className="thumbnail">
              <img src={`${process.env.PUBLIC_URL}/images/about/char-4.webp`} alt="Char 4" />
            </SplideSlide>
            <SplideSlide className="thumbnail">
              <img src={`${process.env.PUBLIC_URL}/images/about/char-5.webp`} alt="Char 5" />
            </SplideSlide>
          </Splide>

          <Splide
            aria-label="Character"
            className="-mt-40 lg:-mt-32"
            ref={mainRef}
            options={{
              rewind: true,
            }}
            hasTrack={false}
          >
            <SplideTrack>
              {characters.map((item) => (
                <SplideSlide key={item.name}>
                  <div className="relative w-full h-[40rem] lg:h-[30rem]">
                    <div
                      className={`absolute inset-x-8 lg:inset-x-12 bottom-0 rounded-md h-[68%] lg:h-[60%] z-0 ${item.name == "shiro" && "bg-[#cc5c5c]"} ${
                        item.name == "kuro" && "bg-[#598ece]"
                      } ${item.name == "fatp" && "bg-[#ef9e56]"} ${item.name == "spinx" && "bg-[#b47dd8]"} ${item.name == "saber" && "bg-[#579e4f]"}`}
                    ></div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 z-10 relative items-center">
                      <div className="text-center pl-12 pr-12 lg:pl-20 lg:pr-10 pt-[9.5rem]">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/about/${item.name}-text.png`}
                          alt={item.name}
                          className="h-20 sm:h-28 lg:h-32 mx-auto mb-2 sm:mb-5"
                        />
                        <p className="text-black text-xs sm:text-sm md:text-[15px] font-light">{item.desc}</p>
                      </div>
                    </div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/about/${item.name}-char.webp`}
                      alt={item.name}
                      className="w-[full] sm:w-2/3 absolute right-0 sm:right-12 md:right-0 bottom-0 md:w-[57%]"
                    />
                  </div>
                </SplideSlide>
              ))}
            </SplideTrack>
            <div className="splide__arrows">
              <button className="splide__arrow splide__arrow--prev" style={{ background: "none", left: "-.5rem", top: "65%" }}>
                <img src="/images/about/left-arrow.svg" alt="Prev" />
              </button>
              <button className="splide__arrow splide__arrow--next" style={{ background: "none", right: "-.5rem", top: "65%" }}>
                <img src="/images/about/right-arrow.svg" alt="Next" />
              </button>
            </div>
          </Splide>
        </div>

        <div id="team" className="bg-gray-100 relative pb-24">
          <div className="absolute inset-0 -top-10">
            <img src={`${process.env.PUBLIC_URL}/images/bg/title-box.webp`} alt="Menu" className="h-16 md:h-20 mx-auto" />
            <span className="absolute top-0 w-full text-center mt-3 md:mt-5 text-white text-3xl md:text-4xl font-cursive">Teams</span>
          </div>

          <div className="max-w-6xl mx-auto px-4 pt-28">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-x-6 gap-y-10">
              {teams.map((item, key) => (
                <div className="text-center" key={key}>
                  <img src={`${process.env.PUBLIC_URL}/images/team/${item.photo}`} alt="" className="w-full rounded-lg" />
                  <div className="my-4">
                    <h6 className="font-bold text-xl">{item.name}</h6>
                    <span>{item.title}</span>
                  </div>
                  <p className="text-sm font-light text-black">{item.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* <footer className="relative w-full bg-black text-white">
        <img src={`${process.env.PUBLIC_URL}/images/bg/footer.webp`} alt="BG" className="hidden md:block absolute bottom-0 w-full inset-x-0 z-0" />
        <img src={`${process.env.PUBLIC_URL}/images/bg/footer-mobile.webp`} alt="BG" className="block md:hidden absolute bottom-0 w-full inset-x-0 z-0" />
        <div className="max-w-6xl mx-auto px-4 pb-2 sm:pb-4 relative z-10 text-center md:text-left">
          <span className="font-bold text-xs">©2023 Khuga by Khuga Labs</span>
        </div>
      </footer> */}
      <footer className="bg-white text-center py-5 text-sm">©2023 Khuga by Khuga Labs</footer>
    </LayoutHome>
  );
}

export default About;
