import React, { useContext } from "react";
import { CheckoutContext } from "../../pages/Checkout";

function Stepper({ items }) {
  const { activeStepIndex } = useContext(CheckoutContext);

  return (
    <div className="grid grid-cols-3 gap-6">
      {items.map((item, index) => (
        <div className="space-y-2" key={index}>
          <div className={`w-full h-1 rounded ${activeStepIndex === index ? "bg-primary-700" : "bg-gray-200"}`}></div>
          <div className="flex items-center space-x-2">
            <div
              className={`w-8 h-8 flex justify-center items-center font-bold rounded-full ${
                activeStepIndex === index ? "bg-primary-700 text-white" : "bg-gray-200 text-gray-300"
              }`}
            >
              {index + 1}
            </div>
            <span className={`${activeStepIndex === index ? "text-gray-800 font-semibold" : "text-gray-300"}`}>{item}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Stepper;
