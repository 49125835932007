import { useContext } from "react";
import { CheckoutContext } from "../../pages/Checkout";

function Step({ items }) {
  const { activeStepIndex } = useContext(CheckoutContext);

  return items[activeStepIndex];
}

export default Step;
