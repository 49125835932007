import TraitsIcon from "../icons/TraitsIcon";

export default function TraitInfo({id, name, value}) {
 return (
   <div className="bg-black/70 px-3 py-2 rounded flex items-center gap-4">
     <TraitsIcon name={id} className="text-white w-8 opacity-50" />
     <div>
       <span className="text-sm text-white/60">{name}</span>
       <h6 className="font-cursive text-white/80">{value}</h6>
     </div>
   </div>
 );
};