import moment from "moment";
import { Fragment, useEffect, useState, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import { countBy, filter, find, flatten, map, max, uniq } from "lodash";
import { Icon } from "@iconify/react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { Tooltip } from "react-tooltip";
import { Dialog, Transition } from "@headlessui/react";
import { EditText } from "react-edit-text";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-edit-text/dist/index.css";

export default function SelectSquadCollection({ event }) {
  const navigate = useNavigate();
  const mySquadRef = useRef();
  const metamaskAddress = localStorage.getItem("metamask_address");
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [assetCollections, setAssetCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [idleCollections, setIdleCollections] = useState([]);
  const [isStarted, setIsStarted] = useState(false);

  const [squads, setSquads] = useState([]);
  const [squadError, setSquadError] = useState([]);
  const [fillingSquad, setFillingSquad] = useState(null);
  const [filterRace, setFilterRace] = useState(null);
  const [squadChange, setSquadChange] = useState(false);
  const [squadChangeModal, setSquadChangeModal] = useState(false);

  useEffect(async () => {
    handleAddSquad();

    const getTime = moment.utc(event?.start_time).diff(moment.utc(moment.utc().local()));
    setIsStarted(getTime < 0);

    await internalAPIClient
      .get(`/missions/${event?.id}/assets/${metamaskAddress}`)
      .then((res) => {
        setAssetCollections(res?.data?.data?.event_datas);
        setIdleCollections(res?.data?.data?.event_datas?.filter((i) => i.join_status === "IDLE"));
        setSelectedCollections(res?.data?.data?.event_squads);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const getSquad = map(selectedCollections, (n, idx) => {
      const races = ["Shiro", "Kuro", "Fat-P", "Spin-X", "Saber"];

      let squadMember;
      let newSquad = [];
      races.map((i) => {
        const sm = find(n.squad_member, (o) => o.metadata.race == i);
        newSquad.push({ id: randomId(), tokenId: sm?.tokenId, race: i });
      });
      squadMember = newSquad;

      return {
        squad_name: n.squad_name,
        squad_member: squadMember,
      };
    });
    setSquads(getSquad);
  }, [selectedCollections]);

  const handleChangeSquadName = (index, value) => {
    setSquadChange(true);
    const newSquads = map(squads, (squad, idx) => {
      return {
        squad_name: index === idx ? value : squad.squad_name,
        squad_member: squad.squad_member,
      };
    });

    setSquads(newSquads);
  };

  const handleFillingSquad = (squad) => {
    if (isStarted) {
      return;
    }

    if (fillingSquad !== null && squad.id === fillingSquad.id) {
      setFillingSquad(null);
      setFilterRace(null);
    } else {
      setFillingSquad(squad);
      setFilterRace(squad.race);
    }
  };

  const handleResetSquad = (index) => {
    const resetSquad = map(squads, (sq, idx) => {
      return idx === index
        ? {
            squad_name: sq.squad_name,
            squad_member: map(sq.squad_member, (n) => ({ ...n, tokenId: undefined })),
          }
        : sq;
    });

    const getTokenId = map(squads[index].squad_member, "tokenId");
    const getDataColletion = filter(assetCollections, (o) => getTokenId.includes(o.tokenId));
    setIdleCollections([...idleCollections, ...getDataColletion]);

    setSquads(resetSquad);
    setSquadChange(true);
  };

  const handleResetAllSquad = () => {
    const resetSquad = map(squads, (sq, idx) => {
      return {
        squad_name: sq.squad_name,
        squad_member: map(sq.squad_member, (n) => ({ ...n, tokenId: undefined })),
      };
    });

    const getTokenId = map(flatten(map(squads, "squad_member")), "tokenId");
    const getDataColletion = filter(assetCollections, (o) => getTokenId.includes(o.tokenId));
    setIdleCollections([...idleCollections, ...getDataColletion]);
    setSquads(resetSquad);
    setSquadChange(true);
  };

  const handleDeleteSquad = (index) => {
    const lastSquad = [...squads];
    const removedItem = lastSquad.splice(index, 1);
    setSquads(lastSquad);

    const getTokenId = map(removedItem[0]?.squad_member, "tokenId");
    const getDataColletion = filter(assetCollections, (o) => getTokenId.includes(o.tokenId));
    setIdleCollections([...idleCollections, ...getDataColletion]);
    setSquadChange(true);
  };

  const handleDeleteSquadMember = (member) => {
    const newSquads = squads.map((squad) => {
      return {
        squad_name: squad.squad_name,
        squad_member: squad.squad_member.map((s) => {
          if (s.id === member.id) {
            return { ...s, tokenId: undefined };
          } else {
            return s;
          }
        }),
      };
    });

    setSquads(newSquads);

    const getDataColletion = filter(assetCollections, (o) => o.tokenId === member.tokenId);
    setIdleCollections([...idleCollections, ...getDataColletion]);
    setSquadChange(true);
  };

  const handleDeleteAllSquad = () => {
    setSquads([]);

    const getTokenId = map(flatten(map(squads, "squad_member")), "tokenId");
    const getDataColletion = filter(assetCollections, (o) => getTokenId.includes(o.tokenId));
    setIdleCollections([...idleCollections, ...getDataColletion]);
    setSquadChange(true);
  };

  // Handle when user add new squad
  const handleAddSquad = (total = 1) => {
    setSquadError([]);

    let lastSquad = squads.slice();
    for (let i = 1; i <= total; i++) {
      let newSquad = [
        { id: randomId(), race: "Shiro", tokenId: undefined },
        { id: randomId(), race: "Kuro", tokenId: undefined },
        { id: randomId(), race: "Fat-P", tokenId: undefined },
        { id: randomId(), race: "Spin-X", tokenId: undefined },
        { id: randomId(), race: "Saber", tokenId: undefined },
      ];
      lastSquad.push({
        squad_name: "Squad " + (squads.length + 1),
        squad_member: newSquad,
      });
    }
    setSquads(lastSquad);

    setTimeout(() => {
      mySquadRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300);
  };

  // Handle when user select khuga collection
  const handleSelectCollection = (id) => {
    if (isStarted) {
      return;
    }

    if (fillingSquad === null) {
      const nft = find(assetCollections, (o) => o.metadata.id == id);
      let pushed = false;
      let newSquads = [];
      for (let i = 0; i < squads.length; i++) {
        const squadName = squads[i].squad_name;
        const squadMember = squads[i].squad_member;
        let newSquad = [];
        for (let j = 0; j < squadMember.length; j++) {
          const s = squadMember[j];
          if (nft.metadata.race === s.race) {
            let filledSquad = { ...s, tokenId: nft.tokenId };
            if (s?.tokenId === null || (s?.tokenId === undefined && !pushed)) {
              pushed = true;
              newSquad.push(filledSquad);
              setIdleCollections(filter(idleCollections, (o) => o.metadata.id != id));
            } else {
              newSquad.push(s);
            }
          } else {
            newSquad.push(s);
          }
        }
        newSquads.push({
          squad_name: squadName,
          squad_member: newSquad,
        });
      }
      setSquads(newSquads);
    } else {
      let newSquads = [];
      for (let i = 0; i < squads.length; i++) {
        const squadName = squads[i].squad_name;
        const squadMember = squads[i].squad_member;
        let newSquad = [];
        for (let j = 0; j < squadMember.length; j++) {
          const s = squadMember[j];
          if (s.id === fillingSquad.id) {
            let filledSquad = { ...fillingSquad, tokenId: id };
            newSquad.push(filledSquad);

            if (s?.tokenId) {
              const getIdle = filter(idleCollections, (o) => o.metadata.id != id);

              let newIdle = [...getIdle, find(assetCollections, (o) => o.metadata.id == s?.tokenId)];
              setIdleCollections(newIdle);
            } else {
              setIdleCollections(filter(idleCollections, (o) => o.metadata.id != id));
            }
          } else {
            newSquad.push(s);
          }
        }
        newSquads.push({
          squad_name: squadName,
          squad_member: newSquad,
        });
      }

      setSquads(newSquads);
      setFillingSquad(null);
      setFilterRace(null);
    }
    setSquadChange(true);
  };

  const handleGenerateSquad = () => {
    setSquadError([]);
    // let squadsId = map(flatten(squads), "tokenId");
    // let newSquads = [];
    // for (let i = 0; i < squads.length; i++) {
    //   const squad = squads[i];
    //   let newSquad = [];
    //   for (let j = 0; j < squad.length; j++) {
    //     const s = squad[j];
    //     const nft = find(assetCollections, (o) => o.metadata.race == s.race && !squadsId.includes(o.tokenId));
    //     if (nft && (s?.tokenId === null || s?.tokenId === undefined)) {
    //       let filledSquad = { ...s, tokenId: nft.tokenId };
    //       squadsId.push(nft.tokenId);
    //       newSquad.push(filledSquad);
    //     } else {
    //       newSquad.push(s);
    //     }
    //   }
    //   newSquads.push(newSquad);
    // }
    // setSquads(newSquads);
    // setIdleCollections(filter(idleCollections, (o) => !squadsId.includes(o.metadata.id)));

    // Get count by Race
    const countByRace = countBy(map(flatten(assetCollections), "metadata.race"));
    const maxRace = Math.max(...Object.values(countByRace));

    let newSquads = [];
    let squadsId = map(flatten(map(squads, "squad_member")), "tokenId");
    let races = ["Shiro", "Kuro", "Fat-P", "Spin-X", "Saber"];

    for (let i = 0; i < maxRace; i++) {
      const squadName = squads[i]?.squad_name;
      const squadMember = squads[i]?.squad_member;
      let newSquad = [];

      if (squadMember === undefined) {
        for (let j = 0; j < races.length; j++) {
          const nft = find(assetCollections, (o) => o.metadata.race == races[j] && !squadsId.includes(o.tokenId));
          if (nft) {
            let filledSquad = { id: randomId(), race: races[j], tokenId: nft.tokenId };
            squadsId.push(nft.tokenId);
            newSquad.push(filledSquad);
          } else {
            let filledSquad = { id: randomId(), race: races[j], tokenId: undefined };
            newSquad.push(filledSquad);
          }
        }
      } else {
        for (let j = 0; j < squadMember.length; j++) {
          const s = squadMember[j];
          const nft = find(assetCollections, (o) => o.metadata.race == s.race && !squadsId.includes(o.tokenId));
          if (nft && (s?.tokenId === null || s?.tokenId === undefined)) {
            let filledSquad = { ...s, tokenId: nft.tokenId };
            squadsId.push(nft.tokenId);
            newSquad.push(filledSquad);
          } else {
            newSquad.push(s);
          }
        }
      }

      if (filter(newSquad, "tokenId").length > 0) {
        newSquads.push({
          squad_name: squadName ?? "Squad " + (i + 1),
          squad_member: newSquad,
        });
      }
    }

    setSquads(newSquads);
    setIdleCollections(filter(idleCollections, (o) => !squadsId.includes(o.metadata.id)));
    setSquadChange(true);
  };

  const validateSquad = () => {
    const allSquadError = [];
    map(squads, (n, idxSquads) => {
      map(n.squad_member, (i, idxMember) => {
        if (i?.tokenId === null || i?.tokenId === undefined) {
          allSquadError.push(idxSquads + 1);
        }
      });
    });

    const uniqSquadError = uniq(allSquadError);
    if (uniqSquadError.length > 0) {
      // toast.dismiss();
      // for (let i = 0; i < uniqSquadError.length; i++) {
      //   toast.error("Squad " + uniqSquadError[i] + " is not valid!");
      // }
      // toast.error('Squads incomplete, stashed as drafts');
      setSquadError(uniqSquadError);
      return uniqSquadError;
    }
    setSquadError([]);
    return [];
  };

  // Handle when user save squad
  const handleSaveData = async () => {
    if (squads.length < 1) {
      return toast.error("Please create at least 1 squad");
    }

    const validate = validateSquad();
    // if (!validate) {
    //   return;
    // }

    const assetData = map(squads, (sq, idx) => {
      return {
        squad_name: sq.squad_name,
        squad_member: map(sq.squad_member, (n) => ({ collection_id: "0", token_id: n.tokenId, race: n.race })),
      };
    });

    await internalAPIClient
      .post(`/missions/${event?.id}/assets/${metamaskAddress}`, assetData)
      .then((res) => {
        if (res?.data?.status == "success") {
          if (validate.length > 0) {
            toast.error("Squads incomplete, stashed as drafts");
          } else {
            toast.success("Success save data");
            setTimeout(() => {
              return navigate(`${process.env.PUBLIC_URL}/miawwhalla/event`);
            }, 1000);
          }
          setSquadChange(false);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="w-full h-full px-4">
      {!isStarted && squadChange && (
        <>
          <button className="absolute z-20 top-4 right-4 md:right-8 transition-all" onClick={() => setSquadChangeModal(true)}>
            <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/close.webp`} alt="" className="w-8 h-8 hover:scale-105" />
          </button>

          <Transition appear show={squadChangeModal} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => setSquadChangeModal(false)}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-50" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <button className="absolute z-20 top-4 right-4 md:right-8 transition-all" onClick={() => setSquadChangeModal(false)}>
                        <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/close.webp`} alt="" className="w-8 h-8 hover:scale-105" />
                      </button>

                      <Dialog.Title as="h3" className="text-xl font-medium font-cursive text-center leading-6 text-gray-900 mb-4">
                        Are you sure?
                      </Dialog.Title>

                      <div className="mt-2 text-center">
                        <p className="mb-4">You have unsaved changes. Are you sure you want to close without saving?</p>
                        <div className="flex items-center justify-center gap-2">
                          <button onClick={() => handleSaveData()} className="px-3 py-2 bg-green-500 text-white font-bold rounded text-sm">
                            Save Changes
                          </button>
                          <button onClick={() => window.location.reload()} className="px-3 py-2 bg-red-500 text-white font-bold rounded text-sm">
                            Discard Changes
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}

      <Tooltip id="my-tooltip" />

      <div className="hidden md:grid grid-cols-2 gap-4 h-16 items-center mt-6">
        <div className="flex items-center justify-between">
          <h2 className="font-cursive text-xl">My Khuga Collection ({idleCollections.length})</h2>
        </div>
        <div>
          <h2 className="font-cursive text-xl">
            {isStarted ? (
              <span>
                My Squad ({selectedCollections.filter((i) => i.squad_member.filter((s) => s.disqualified_status !== 1).length > 0).length}/{selectedCollections.length})
              </span>
            ) : (
              <span>Squad Setup ({squads.length})</span>
            )}
          </h2>
        </div>
      </div>
      <div className="md:grid md:grid-cols-2 gap-4 h-[calc(100%_-_6rem)] md:h-[calc(100%_-_9.5rem)] mt-8 md:mt-0">
        <div className="h-16 md:hidden flex items-center justify-between pt-6">
          <h2 className="font-cursive text-xl">My Khuga Collection ({idleCollections.length})</h2>
        </div>
        <div className="h-[calc(50%_-_4rem)] md:h-full rounded-lg py-4 overflow-auto scrollbar-thin scrollbar-thumb-[#ADADF0] scrollbar-track-[#7575A0] scrollbar-rounded-lg">
          {idleCollections.filter((i) => (filterRace !== null ? i.metadata.race === filterRace : true)).length < 1 ? (
            <div>
              <p>You don't have another Khuga collection</p>
              <div>
                <a
                  href="https://opensea.io/collection/khuga-by-khuga-labs"
                  target="_blank"
                  className="inline-flex items-center mt-2 bg-[#439583] text-white px-8 p-2 rounded-md font-cursive hover:contrast-125 hover:scale-[1.02] active:scale-[.98] transition-all"
                >
                  Get More
                </a>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-5 gap-4 mr-4">
              {idleCollections
                .filter((i) => (filterRace !== null ? i.metadata.race === filterRace : true))
                .map((i) => (
                  <div
                    className="text-center cursor-pointer group w-full relative group overflow-hidden"
                    onClick={() => handleSelectCollection(i?.metadata.id)}
                    key={i.id}
                  >
                    <div className="w-full aspect-square overflow-hidden rounded-xl group-hover:shadow-xl transition-all">
                      <div className="w-full aspect-square rounded-xl mb-1 group-hover:scale-110 transition-all duration-300">
                        <LazyLoadImage src={`https://khuga.io/khuga-preview/${i?.metadata.id}.webp`} alt="?" effect="blur" className="w-full" />
                      </div>
                    </div>
                    <div className="flex justify-center items-center mt-1">
                      <h3 className="text-xs text-black/80">
                        <span>{i?.metadata.name}</span>
                      </h3>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="absolute right-4 h-16 flex items-center justify-end md:hidden">
          {!isStarted && (
            <button
              onClick={() => {
                handleGenerateSquad();
              }}
              className="bg-[#439583] text-white px-4 text-xs p-2 rounded-md font-cursive block hover:contrast-125 hover:scale-[1.02] active:scale-[.98] transition-all"
            >
              <span>Generate Squad</span>
            </button>
          )}
        </div>
        <div className="md:hidden h-16 flex items-center">
          <h2 className="font-cursive text-xl">
            {isStarted ? (
              <span>
                My Squad ({selectedCollections.filter((i) => i.squad_member.filter((s) => s.disqualified_status !== 1).length > 0).length}/{selectedCollections.length})
              </span>
            ) : (
              <span>Squad Setup ({squads.length})</span>
            )}
          </h2>
        </div>
        <div className="h-[calc(50%_-_4rem)] md:h-full bg-white rounded-lg p-4 overflow-auto scrollbar-thin scrollbar-thumb-[#ADADF0] scrollbar-track-[#7575A0] scrollbar-rounded-lg">
          {squads.map((squad, index) => (
            <div ref={mySquadRef} className={`border p-3 rounded-md mb-3 ${squadError.includes(index + 1) && "border-red-500"}`} key={index}>
              <div className="flex items-center justify-between mb-1">
                <div className="font-bold text-sm mb-1">
                  <EditText
                    defaultValue={squad.squad_name}
                    value={squad.squad_name}
                    onChange={(e) => {
                      if (e.target.value.length <= 20) {
                        handleChangeSquadName(index, e.target.value);
                      } else {
                        toast.dismiss();
                        toast.error("Max length of squad name: 20");
                      }
                    }}
                    showEditButton
                    editButtonContent={<Icon icon="tabler:edit" className="w-4 h-4" />}
                  />
                </div>
                {!isStarted && (
                  <div className="flex items-center gap-1">
                    <button onClick={() => handleResetSquad(index)} className="text-xs font-medium px-1 py-px rounded bg-green-600 text-white">
                      Reset
                    </button>
                    <button onClick={() => handleDeleteSquad(index)} className="text-xs font-medium px-1 py-px rounded bg-red-600 text-white">
                      Delete
                    </button>
                  </div>
                )}
              </div>

              <div className="relative">
                <div className="w-full relative inset-0 grid grid-cols-5 gap-4 mr-4">
                  {squad.squad_member.map((i) => (
                    <div
                      key={i.id}
                      className={`w-full aspect-square rounded-md relative cursor-pointer group active:scale-95 transition-all`}
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={i.race}
                      onClick={() => handleFillingSquad(i)}
                    >
                      {i?.tokenId ? (
                        <>
                          {find(assetCollections, (o) => o.tokenId == i?.tokenId)?.disqualified_status === 1 && (
                            <div className="w-full aspect-square absolute z-10 inset-0 backdrop-grayscale flex items-center justify-center">
                              <Icon icon="ph:x-light" className="w-full h-full text-red-500" />
                            </div>
                          )}
                          <img
                            src={`https://khuga.io/khuga-preview/${i.tokenId}.webp`}
                            alt={i.race}
                            className={`rounded group-hover:opacity-100 transition-all ${
                              i.id === fillingSquad?.id ? " border-4 border-green-600 contrast-150" : "contrast-100"
                            }`}
                          />
                          {i.id === fillingSquad?.id && (
                            <>
                              <div className="absolute inset-x-0 top-0 aspect-square bg-green-600/60"></div>
                              <button
                                onClick={() => handleDeleteSquadMember(i)}
                                className="absolute top-1 right-1 w-6 h-6 rounded-full bg-red-500 text-white flex items-center justify-center"
                              >
                                <Icon icon="heroicons:x-mark-20-solid" />
                              </button>
                            </>
                          )}
                          <p className="text-xs text-center">Khuga #{i.tokenId}</p>
                        </>
                      ) : (
                        <>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/race/${i.race}.webp`}
                            alt={i.race}
                            className={`rounded group-hover:opacity-100 transition-all ${i.id === fillingSquad?.id ? "" : "grayscale opacity-50"}`}
                          />
                          <div className="absolute inset-0 text-white flex items-center justify-center">
                            <Icon
                              icon="heroicons-outline:plus"
                              className={`w-6 h-6 md:w-12 md:h-12 transition-all ${i.id === fillingSquad?.id ? "rotate-45" : "rotate-0"}`}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {/* <div className="w-full relative grid grid-cols-5 gap-4 mr-4">
                  <div className="bg-transparent w-full aspect-square"></div>
                </div> */}
              </div>
            </div>
          ))}
          {!isStarted && (
            <button onClick={() => handleAddSquad()} className="font-bold text-xs bg-primary-800 px-3 py-1 rounded-md mt-3 text-white">
              + Squad
            </button>
          )}
        </div>
      </div>
      <div className="h-16 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="h-16 md:flex items-center justify-end hidden">
          {!isStarted && (
            <button
              onClick={() => {
                handleGenerateSquad();
              }}
              className="bg-[#439583] text-white px-8 p-2 rounded-md font-cursive block hover:contrast-125 hover:scale-[1.02] active:scale-[.98] transition-all"
            >
              <span>Generate Squad</span>
            </button>
          )}
        </div>
        <div className="h-16 flex items-center justify-end gap-4">
          {isStarted ? (
            <button className="bg-[#439583] text-white px-4 md:px-8 py-2 text-xs sm:text-sm md:text-base rounded-md font-cursive block cursor-not-allowed transition-all grayscale">
              <span>Save My Squad</span>
            </button>
          ) : (
            <>
              <button onClick={() => handleResetAllSquad()} className="text-xs font-medium px-4 py-2 rounded bg-green-600 text-white">
                Reset All
              </button>
              <button onClick={() => handleDeleteAllSquad()} className="text-xs font-medium px-4 py-2 rounded bg-red-600 text-white">
                Delete All
              </button>
              <button
                onClick={() => handleSaveData()}
                className="bg-[#439583] text-white px-4 md:px-8 py-2 text-xs sm:text-sm md:text-base rounded-md font-cursive block hover:contrast-125 hover:scale-[1.02] active:scale-[.98] transition-all"
              >
                <span>Save My Squad</span>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function randomId() {
  return (Math.random() + 1).toString(36).substring(7);
}
