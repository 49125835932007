import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";

function TraitGroup({ id, title, icon, items, expand, onChange, resetTraits }) {
  const [expanded, setExpanded] = useState(expand);
  const [traits, setTraits] = useState(undefined);
  const [searchTrait, setSearchTrait] = useState("");

  const handleCheckboxChanges = (e) => {
    const prevTraits = traits ?? [];
    let newArray = [...prevTraits, e.target.id];
    if (prevTraits.includes(e.target.id)) {
      newArray = newArray.filter((t) => t !== e.target.id);
    }
    setTraits(newArray);
  };

  useEffect(() => {
    if (traits !== undefined) {
      onChange(id, traits);
    }
  }, [traits]);

  useEffect(() => {
    if (resetTraits?.id === id) {
      const prevTraits = traits ?? [];
      var index = prevTraits.indexOf(resetTraits.value);
      if (index !== -1) {
        prevTraits.splice(index, 1);
      }
      const newArray = [...prevTraits];
      setTraits(newArray);
    } else if (resetTraits?.id === "clear") {
      setTraits([]);
    }
  }, [resetTraits]);

  return (
    <div className="mt-4 pt-4">
      <div className="flex items-center justify-between mb-4 cursor-pointer" onClick={() => setExpanded((expanded) => !expanded)}>
        <div className={title === "Legendary" ? "flex items-center space-x-2 text-[#E07C00]" : "flex items-center space-x-2"}>
          {icon}
          <span className="font-bold">{title}</span>
        </div>
        <div className="flex items-center gap-2">
          {traits !== undefined && traits.length > 0 && <p className="text-xs font-bold px-2 py-1 bg-gray-300 rounded-md">{traits.length}</p>}
          <img
            src={expanded ? process.env.PUBLIC_URL + "/images/gallery/minus.webp" : process.env.PUBLIC_URL + "/images/gallery/plus.webp"}
            alt="Collapse"
            className="h-6"
          />
        </div>
      </div>
      <Transition
        show={expanded}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="space-y-2 pl-2">
          <div className="relative">
            <div className="absolute left-3.5 top-3.5">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="w-4 h-4" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m21 21l-6-6m2-5a7 7 0 1 1-14 0a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              className="w-full py-2 pl-9 pr-2 bg-transparent border border-gray-300"
              placeholder={`Search ${title}`}
              onChange={(e) => setSearchTrait(e.target.value)}
            />
          </div>
          {items
            .filter((f) => (searchTrait !== "" ? f.value.toUpperCase().includes(searchTrait.toUpperCase()) : true))
            .map((i) => (
              <div className="flex items-center justify-between" key={i.value}>
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id={i.value}
                    className="h-6 w-6 text-primary-800"
                    onChange={(e) => handleCheckboxChanges(e)}
                    checked={traits !== undefined && traits.includes(i.value)}
                    // defaultChecked={traits !== undefined && traits.includes(i.value)}
                  />
                  <label htmlFor={i.value} className="font-medium">
                    {i.value}
                  </label>
                </div>
                <span className="text-gray-500">{i.count}</span>
              </div>
            ))}
        </div>
      </Transition>
    </div>
  );
}

export default TraitGroup;
