import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { CheckoutContext } from "../../pages/Checkout";
import * as yup from "yup";
import { useWallet } from "@solana/wallet-adapter-react";

function PersonalInformation() {
  const wallet = useWallet();
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(CheckoutContext);

	const ValidationSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    discord: yup.string().required(),
  });

	const renderError = (message) => <p className="text-sm italic text-red-600">{message}</p>;

  return (
    <Formik
      initialValues={{
        name: formData.name || "",
        email: formData.email || "",
        phone: formData.phone || "",
        discord: formData.discord || "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        console.log(data);
        setActiveStepIndex(activeStepIndex + 1);
      }}
    >
      <Form className={`space-y-4`}>
        <div className="space-y-1">
          <label htmlFor="name">Name</label>
          <Field name="name" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
          <ErrorMessage name="name" render={renderError} />
        </div>
        <div className="space-y-1">
          <label htmlFor="phone">Phone</label>
          <Field name="phone" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
          <ErrorMessage name="phone" render={renderError} />
        </div>
        <div className="space-y-1">
          <label htmlFor="email">Email</label>
          <Field name="email" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
          <ErrorMessage name="email" render={renderError} />
        </div>
        <div className="space-y-1">
          <label htmlFor="discord">Discord ID</label>
          <Field name="discord" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
          <ErrorMessage name="discord" render={renderError} />
        </div>

        <button className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-cursive text-sm" type="submit">
          Next
        </button>
      </Form>
    </Formik>
  );
}

export default PersonalInformation;
