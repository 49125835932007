import { mainnet, goerli } from "wagmi/chains";
import { MetaMaskConnector } from "@wagmi/core/connectors/metaMask";
import { WalletConnectConnector } from "@wagmi/core/connectors/walletConnect";
import { createConfig, configureChains } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, goerli],
  [alchemyProvider({ apiKey: "BiTgzAqcFyHhXqAz7lNHDWygVofaOpqr" })]
);

const khugaMetamaskConnector = new MetaMaskConnector({
  chains,
});

const khugaWalletConnecConnector = new WalletConnectConnector({
  chains,
  options: {
    projectId: "faa7439b3c5d2632ad1a4eeb382466a7",
  },
});

const config = createConfig({
  logger: {
    warn: (message) => console.log(message),
  },
  autoConnect: true,
  connectors: [khugaMetamaskConnector, khugaWalletConnecConnector],
  publicClient,
  webSocketPublicClient,
});

export {
  config as khugaWagmiConfig,
  khugaMetamaskConnector,
  khugaWalletConnecConnector,
};
