import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import LayoutBase from "../../../layouts/LayoutBase";
import { Paper } from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";
import { useAuthenticatedHttpClient } from "../../../utils/authenticated-api-call";
import LoadingSpinner from "../../../partials/LoadingSpinner";

function AdminCreateEvent() {
  const navigate = useNavigate();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    season: yup.string().required(),
    name: yup.string().required(),
    category: yup.string().required(),
    mode: yup.string().required(),
    milestone_interval: yup.number().required(),
    start_block: yup.number().required(),
    description: yup.string().required(),
    event_mechanism: yup.string().required(),
    story: yup.string().required(),
    start_time: yup.string().required(),
    end_time: yup.string().required(),
    participant_limit: yup.number().required(),
    reward: yup.number().required(),
    reward_name: yup.string().required(),
    reward_description: yup.string().required(),
    banner_image: yup.string().required(),
    background_image: yup.string().required(),
    reward_image: yup.string().required(),
    title_url: yup.string().required(),
    animation_url: yup.string().required(),
    v_animation_url: yup.string().required(),
    reward_asset_url: yup.string().required(),
  });

  const renderError = (message) => <p className="text-sm italic text-red-600">{message}</p>;

  const handleSubmitEvent = async (values) => {
    setIsLoading(true);

    var formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }

    await internalAPIClient
      .post(`/cms/missions`, formData)
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Success create new event", { position: "top-right" });
          setTimeout(() => {
            return navigate(`${process.env.PUBLIC_URL}/admin/event`);
          }, 1500);
        } else {
          console.log(res);
          toast.error("Failed to create event");
        }
      })
      .catch(async (e) => {
        console.log(e);
        toast.error("Failed to create event");
      });

    setIsLoading(false);
  }

  return (
    <LayoutBase>
      {isLoading && <LoadingSpinner />}

      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div className="flex items-center justify-between">
          <h1 className="font-bold text-2xl mb-4 font-cursive">Create Event</h1>
          <Link to="/admin/event" className="px-5 py-2.5 bg-primary-800 text-white rounded hover:contrast-75 font-cursive text-xs mb-4 flex items-center gap-1">
            <Icon icon="uil:arrow-left" className="w-4 h-4" />
            <span>Back</span>
          </Link>
        </div>

        <div className="w-full">
          <Paper elevation={2} className="p-4 md:p-6">
            <Formik
              initialValues={{
                season: "",
                name: "",
                category: "",
                mode: "",
                milestone_interval: "",
                start_block: "",
                description: "",
                event_mechanism: "",
                story: "",
                start_time: "",
                end_time: "",
                participant_limit: "",
                reward: "",
                reward_name: "",
                reward_description: "",
                banner_image: "",
                background_image: "",
                reward_image: "",
                title_url: "",
                animation_url: "",
                v_animation_url: "",
                reward_asset_url: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmitEvent(values);
              }}
            >
              {(formik) => (
                <Form>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div className="space-y-1">
                      <label htmlFor="season">Season *</label>
                      <Field name="season" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="season" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="name">Name *</label>
                      <Field name="name" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="name" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="category">Category *</label>
                      <Field as="select" name="category" className="block w-full px-4 py-2 border border-gray-300 rounded">
                        <option value="main">Main</option>
                        <option value="side">Side</option>
                      </Field>
                      <ErrorMessage name="category" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="mode">Mode *</label>
                      <Field as="select" name="mode" className="block w-full px-4 py-2 border border-gray-300 rounded">
                        <option value="individual">Individual</option>
                        <option value="squad">Squad</option>
                      </Field>
                      <ErrorMessage name="mode" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="milestone_interval">Milestone Interval *</label>
                      <Field name="milestone_interval" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="milestone_interval" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="start_block">Start Block *</label>
                      <Field name="start_block" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="start_block" render={renderError} />
                    </div>
                    <div className="space-y-1 col-span-3">
                      <label htmlFor="description">Description *</label>
                      <Field as="textarea" name="description" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="description" render={renderError} />
                    </div>
                    <div className="space-y-1 col-span-3">
                      <label htmlFor="event_mechanism">Event Mechanism *</label>
                      <Field as="textarea" name="event_mechanism" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="event_mechanism" render={renderError} />
                    </div>
                    <div className="space-y-1 col-span-3">
                      <label htmlFor="story">Story *</label>
                      <Field as="textarea" name="story" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="story" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="start_time">Start Time *</label>
                      <Field type="datetime-local" name="start_time" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="start_time" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="end_time">End Time *</label>
                      <Field type="datetime-local" name="end_time" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="end_time" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="participant_limit">Participant Limit *</label>
                      <Field type="number" name="participant_limit" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="participant_limit" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="reward">Reward *</label>
                      <Field type="number" name="reward" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="reward" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="reward_name">Reward Name *</label>
                      <Field name="reward_name" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="reward_name" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="reward_description">Reward Description *</label>
                      <Field name="reward_description" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
                      <ErrorMessage name="reward_description" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="banner_image">Banner Image *</label>
                      <input
                        id="banner_image"
                        name="banner_image"
                        type="file"
                        className="block w-full px-4 py-2 border border-gray-300 rounded"
                        accept="image/*"
                        onChange={(event) => {
                          formik.setFieldValue("banner_image", event.currentTarget.files[0]);
                        }}
                      />
                      <ErrorMessage name="banner_image" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="background_image">Background Image *</label>
                      <input
                        id="background_image"
                        name="background_image"
                        type="file"
                        className="block w-full px-4 py-2 border border-gray-300 rounded"
                        accept="image/*"
                        onChange={(event) => {
                          formik.setFieldValue("background_image", event.currentTarget.files[0]);
                        }}
                      />
                      <ErrorMessage name="background_image" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="reward_image">Reward Image *</label>
                      <input
                        id="reward_image"
                        name="reward_image"
                        type="file"
                        className="block w-full px-4 py-2 border border-gray-300 rounded"
                        accept="image/*"
                        onChange={(event) => {
                          formik.setFieldValue("reward_image", event.currentTarget.files[0]);
                        }}
                      />
                      <ErrorMessage name="reward_image" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="reward_asset_url">Reward Asset *</label>
                      <input
                        id="reward_asset_url"
                        name="reward_asset_url"
                        type="file"
                        className="block w-full px-4 py-2 border border-gray-300 rounded"
                        accept="image/*"
                        onChange={(event) => {
                          formik.setFieldValue("reward_asset_url", event.currentTarget.files[0]);
                        }}
                      />
                      <ErrorMessage name="reward_asset_url" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="title_url">Title Image *</label>
                      <input
                        id="title_url"
                        name="title_url"
                        type="file"
                        className="block w-full px-4 py-2 border border-gray-300 rounded"
                        accept="image/*"
                        onChange={(event) => {
                          formik.setFieldValue("title_url", event.currentTarget.files[0]);
                        }}
                      />
                      <ErrorMessage name="title_url" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="animation_url">Animation (Lottie) *</label>
                      <input
                        id="animation_url"
                        name="animation_url"
                        type="file"
                        className="block w-full px-4 py-2 border border-gray-300 rounded"
                        onChange={(event) => {
                          formik.setFieldValue("animation_url", event.currentTarget.files[0]);
                        }}
                      />
                      <ErrorMessage name="animation_url" render={renderError} />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="v_animation_url">Vertical Animation (Lottie) *</label>
                      <input
                        id="v_animation_url"
                        name="v_animation_url"
                        type="file"
                        className="block w-full px-4 py-2 border border-gray-300 rounded"
                        onChange={(event) => {
                          formik.setFieldValue("v_animation_url", event.currentTarget.files[0]);
                        }}
                      />
                      <ErrorMessage name="v_animation_url" render={renderError} />
                    </div>
                  </div>
                  <hr className="my-4" />
                  <button type="submit" className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-cursive">
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </Paper>
        </div>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default AdminCreateEvent;
