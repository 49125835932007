import LayoutHome from "../layouts/LayoutHome";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import LoadingSpinner from "../partials/LoadingSpinner";
import toast, { Toaster } from "react-hot-toast";
import WhitelistChecker from "../partials/whitelist/WhitelistChecker";
import { TransformComponent, TransformWrapper, useControls } from "react-zoom-pan-pinch";
import { isMobile } from "react-device-detect";

function LandingPage() {
  const btnZoomInRef = useRef();
  const btnZoomOutRef = useRef();
  const [showDaddyShop, setShowDaddyShop] = useState(false);
  const [showMiawwhalla, setShowMiawwhalla] = useState(false);
  const [showPawLab, setShowPawLab] = useState(false);
  const [showSunTemple, setShowSunTemple] = useState(false);
  const [showPg, setShowPg] = useState(false);
  const [showFactory, setShowFactory] = useState(false);

  const hideAllPopover = () => {
    setShowDaddyShop(false);
    setShowMiawwhalla(false);
    setShowPawLab(false);
    setShowSunTemple(false);
    setShowPg(false);
    setShowFactory(false);
  };

  useEffect(() => {
    document.addEventListener(
      "keydown",
      (e) => {
        if (e.metaKey == true && (e.key == "-" || e.key == "+" || e.key == "_" || e.key == "=")) {
          if (e.key == "-" || e.key == "_") {
            btnZoomOutRef.current.click();
            e.preventDefault();
          }
          if (e.key == "+" || e.key == "=") {
            btnZoomInRef.current.click();
            e.preventDefault();
          }
        }
      },
      false
    );
  }, []);

  return (
    <LayoutHome>
      <div className="cursor-move relative h-screen">
        <TransformWrapper
          maxScale={3}
          disablePadding={true}
          // initialScale={1.5}
          // minScale={1}
          // centerOnInit
          initialPositionX={isMobile ? -420 : 0}
        >
          {({ zoomIn, zoomOut, resetTransform, zoomToElement, ...rest }) => (
            <>
              <div className="absolute bottom-4 left-4 z-10 rounded bg-white/50 backdrop-blur border border-white">
                <button ref={btnZoomInRef} className="p-2.5 hover:bg-white rounded" onClick={() => zoomIn(0.2)}>
                  <Icon icon="heroicons-outline:plus" className="w-3 h-3" />
                </button>
                <button ref={btnZoomOutRef} className="p-2.5 hover:bg-white rounded" onClick={() => zoomOut(0.2)}>
                  <Icon icon="heroicons-outline:minus" className="w-3 h-3" />
                </button>
                <button
                  className="p-2.5 hover:bg-white rounded"
                  onClick={() => {
                    resetTransform();
                    hideAllPopover();
                  }}
                >
                  <Icon icon="heroicons-outline:refresh" className="w-3 h-3" />
                </button>
              </div>
              <TransformComponent wrapperStyle={{ width: "100%", height: "100vh" }}>
                <img src="/images/khugaverse.webp" alt="Khugaverse" className="max-w-none lg:w-full h-screen lg:h-auto" />

                {/* PawLab */}
                <div
                  id="pawLab"
                  className="border-0 border-red-500 group z-10 cursor-pointer w-[10%] aspect-square absolute right-[25%] top-[18%]"
                  onClick={() => {
                    hideAllPopover();
                    zoomToElement("pawLab", 1.5);
                    setShowPawLab(true);
                  }}
                >
                  <button className="p-2 mx-auto block">
                    <Icon
                      icon="tabler:map-pin-filled"
                      className={`${
                        showPawLab ? "text-red-500" : "text-yellow-400"
                      } w-6 h-6 xl:w-8 xl:h-8 2xl:w-12 2xl:h-12 drop-shadow-2xl animate-pulse animate-bounce group-hover:animate-none group-hover:scale-110 group-hover:opacity-50 duration-500 transition-all`}
                    />
                  </button>
                </div>
                <Transition
                  show={showPawLab}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="z-20 absolute right-[20%] top-[28%] w-56 md:w-64 bg-white/50 backdrop-blur shadow-2xl rounded-lg p-4 text-xs">
                    <button className="absolute right-1 top-1" onClick={() => {
                      setShowPawLab(false);
                      zoomOut();
                    }}>
                      <Icon icon="heroicons-outline:x" className="w-3 h-3 text-red-500" />
                    </button>
                    <h4 className="font-bold text-base mb-2">P.A.W LAB</h4>
                    <p className="mb-2">
                      A bustling center of breakthroughs and creativity where brilliant minds gather to shatter boundaries and unlock the hidden gems of the
                      Khugaverse.
                    </p>
                    <p>They bring projects to life with service products, showcasing the Web3 wonders through Miawwhalla, video games, and beyond.</p>
                  </div>
                </Transition>

                {/* Miawwhalla */}
                <div
                  id="miawwhalla"
                  className="border-0 border-red-500 group z-10 cursor-pointer w-[7%] aspect-square absolute right-[42%] top-[42%]"
                  onClick={() => {
                    hideAllPopover();
                    zoomToElement("miawwhalla", 1.5);
                    setShowMiawwhalla(true);
                  }}
                >
                  <button className="p-2 mx-auto block">
                    <Icon
                      icon="tabler:map-pin-filled"
                      className={`${
                        showMiawwhalla ? "text-red-500" : "text-yellow-400"
                      } w-6 h-6 xl:w-8 xl:h-8 2xl:w-12 2xl:h-12 drop-shadow-2xl animate-pulse animate-bounce group-hover:animate-none group-hover:scale-110 group-hover:opacity-50 duration-500 transition-all`}
                    />
                  </button>
                </div>
                <Transition
                  show={showMiawwhalla}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="z-20 absolute left-[45%] top-[52%] w-56 md:w-64 bg-white/50 backdrop-blur shadow-2xl rounded-lg p-4 text-xs">
                    <button className="absolute right-1 top-1" onClick={() => {
                      setShowMiawwhalla(false);
                      zoomOut();
                    }}>
                      <Icon icon="heroicons-outline:x" className="w-3 h-3 text-red-500" />
                    </button>
                    <h4 className="font-bold text-base mb-2">MIAWWHALLA</h4>
                    <p className="mb-2">The play arena. A seasonal gamification platform that Khuga warriors can't resist.</p>
                    <p>
                      Win battles, level up, and discover a treasure troves of armory. It's all seamlessly integrated within the game and other Khuga
                      adventures.
                    </p>
                  </div>
                </Transition>

                {/* Sun Temple */}
                <div
                  id="sunTemple"
                  className="border-0 border-red-500 group z-10 cursor-pointer w-[6%] aspect-[1/2] absolute left-[41%] top-[14%]"
                  onClick={() => {
                    hideAllPopover();
                    zoomToElement("sunTemple", 1.5);
                    setShowSunTemple(true);
                  }}
                >
                  <button className="p-2 mx-auto block">
                    <Icon
                      icon="tabler:map-pin-filled"
                      className={`${
                        showSunTemple ? "text-red-500" : "text-yellow-400"
                      } w-6 h-6 xl:w-8 xl:h-8 2xl:w-12 2xl:h-12 drop-shadow-2xl animate-pulse animate-bounce group-hover:animate-none group-hover:scale-110 group-hover:opacity-50 duration-500 transition-all`}
                    />
                  </button>
                </div>
                <Transition
                  show={showSunTemple}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="z-20 absolute left-[41%] top-[25%] w-56 md:w-64 bg-white/50 backdrop-blur shadow-2xl rounded-lg p-4 text-xs">
                    <button className="absolute right-1 top-1" onClick={() => {
                      setShowSunTemple(false);
                      zoomOut();
                    }}>
                      <Icon icon="heroicons-outline:x" className="w-3 h-3 text-red-500" />
                    </button>
                    <h4 className="font-bold text-base mb-2">SUN TEMPLE</h4>
                    <p className="mb-2">The sacred abode of Alip, the art deity behind the Khugaverse's creation.</p>
                    <p>From his lofty perch, he finds solace. And as soon as he shows up, oh, you know exciting things are brewing in the Khugaverse!</p>
                  </div>
                </Transition>

                {/* Daddy Shop */}
                <div
                  id="daddyShop"
                  className="border-0 border-red-500 group z-10 cursor-pointer w-[12%] aspect-square absolute left-[22%] top-[22%]"
                  onClick={() => {
                    hideAllPopover();
                    zoomToElement("daddyShop", 1.5);
                    setShowDaddyShop(true);
                  }}
                >
                  <button className="p-2 mx-auto block">
                    <Icon
                      icon="tabler:map-pin-filled"
                      className={`${
                        showDaddyShop ? "text-red-500" : "text-yellow-400"
                      } w-6 h-6 xl:w-8 xl:h-8 2xl:w-12 2xl:h-12 drop-shadow-2xl animate-pulse animate-bounce group-hover:animate-none group-hover:scale-110 group-hover:opacity-50 duration-500 transition-all`}
                    />
                  </button>
                </div>
                <Transition
                  show={showDaddyShop}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="z-20 absolute left-[22%] top-[38%] w-56 md:w-64 bg-white/50 backdrop-blur shadow-2xl rounded-lg p-4 text-xs">
                    <button className="absolute right-1 top-1" onClick={() => {
                      setShowDaddyShop(false);
                      zoomOut();
                    }}>
                      <Icon icon="heroicons-outline:x" className="w-3 h-3 text-red-500" />
                    </button>
                    <h4 className="font-bold text-base mb-2">DADDY SHARP SHOP </h4>
                    <p className="mb-2">Daddy Sharp travels the world to find the finest products.</p>
                    <p>
                      The shop is a reliable store where Khugas indulge in merch hunts, trade K-Gears and other on-chain goodies using Khuga coins, and redeem
                      tickets for thrilling prizes.
                    </p>
                  </div>
                </Transition>

                {/* Play Ground */}
                <div
                  id="playGround"
                  className="border-0 border-red-500 group z-10 cursor-pointer w-[15%] aspect-square absolute left-[5%] top-[60%]"
                  onClick={() => {
                    hideAllPopover();
                    zoomToElement("playGround", 1.5);
                    setShowPg(true);
                  }}
                >
                  <button className="p-2 mx-auto block">
                    <Icon
                      icon="tabler:map-pin-filled"
                      className={`${
                        showPg ? "text-red-500" : "text-yellow-400"
                      } w-6 h-6 xl:w-8 xl:h-8 2xl:w-12 2xl:h-12 drop-shadow-2xl animate-pulse animate-bounce group-hover:animate-none group-hover:scale-110 group-hover:opacity-50 duration-500 transition-all`}
                    />
                  </button>
                </div>
                <Transition
                  show={showPg}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="z-20 absolute left-[5%] top-[70%] w-56 md:w-64 bg-white/50 backdrop-blur shadow-2xl rounded-lg p-4 text-xs">
                    <button className="absolute right-1 top-1" onClick={() => {
                      setShowPg(false);
                      zoomOut();
                    }}>
                      <Icon icon="heroicons-outline:x" className="w-3 h-3 text-red-500" />
                    </button>
                    <h4 className="font-bold text-base mb-2">PLAYGROUND</h4>
                    <p className="mb-2">The Khuga holder's play arena. Here, creativity runs wild, collaborations are born, and dreams come true.</p>
                    <p>It's our favorite spot where we sprinkle creative ideas with love, and our IP blossoms into magic.</p>
                  </div>
                </Transition>

                {/* Factory */}
                <div
                  id="factory"
                  className="border-0 border-red-500 group z-10 cursor-pointer w-[15%] aspect-square absolute right-[4%] bottom-[8%]"
                  onClick={() => {
                    hideAllPopover();
                    zoomToElement("factory", 1.5);
                    setShowFactory(true);
                  }}
                >
                  <button className="p-2 mx-auto block">
                    <Icon
                      icon="tabler:map-pin-filled"
                      className={`${
                        showFactory ? "text-red-500" : "text-yellow-400"
                      } w-6 h-6 xl:w-8 xl:h-8 2xl:w-12 2xl:h-12 drop-shadow-2xl animate-pulse animate-bounce group-hover:animate-none group-hover:scale-110 group-hover:opacity-50 duration-500 transition-all`}
                    />
                  </button>
                </div>
                <Transition
                  show={showFactory}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="z-20 absolute right-[4%] bottom-[4%] w-56 md:w-64 bg-white/50 backdrop-blur shadow-2xl rounded-lg p-4 text-xs">
                    <button className="absolute right-1 top-1" onClick={() => {
                      setShowFactory(false);
                      zoomOut();
                    }}>
                      <Icon icon="heroicons-outline:x" className="w-3 h-3 text-red-500" />
                    </button>
                    <h4 className="font-bold text-base mb-2">FACTORY</h4>
                    <p>
                      The very own merch factory of Khugaverse, where imagination takes shape. We're on the go to create toys, apparel, sneakers, and loads of
                      'Whoa, that's rad!' moments.
                    </p>
                  </div>
                </Transition>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </div>
    </LayoutHome>
  );
}

export default LandingPage;
