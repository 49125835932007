import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { getEnsName } from "../../web3/eth/ens";

function User({ className }) {
  const [currentUser] = useContext(UserContext);
  const [ensAddress, setEnsAddress] = useState();
  const metamaskAddress = localStorage.getItem("metamask_address");

  useEffect(() => {
    getEnsName(metamaskAddress).then((res) => setEnsAddress(res));
  }, []);

  const handleDisconnect = () => {
    localStorage.removeItem("metamask_address");
    localStorage.removeItem("x-auth-khuga");
    localStorage.removeItem("x-user-khuga");
    window.location.reload();
  };

  return (
    <Menu as="div" className="relative">
      <Menu.Button className={`flex cursor-pointer items-center space-x-4 ${className}`}>
        <div className="w-10 2xl:w-16 aspect-square bg-white rounded-full">
          {currentUser?.avatar && <img src={currentUser?.avatar} alt={currentUser?.name} className="w-full rounded-full" />}
        </div>
        <div className="text-white font-cursive text-base xl:text-lg 2xl:tex-xl" title={metamaskAddress}>
          {ensAddress ?? metamaskAddress?.substr(0, 4) + "..." + metamaskAddress?.substr(-4)}
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-20 left-0 top-16 w-96 p-4 rounded-lg bg-white/50 backdrop-blur border border-white">
          <div className="px-1 py-1 ">
            <Menu.Item>
              <h6 className="text-sm font-bold">{metamaskAddress}</h6>
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button onClick={handleDisconnect} className="mt-2 bg-red-500 text-white font-bold px-2 py-1 text-sm rounded-md">
                  Disconnect
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default User;
