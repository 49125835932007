import { createContext } from "react";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { TezosToolkit } from "@taquito/taquito";
import { Tzip16Module } from "@taquito/tzip16";
import { default as TezosNft } from "../tezos/nft";
import { default as TezosUtil } from "./utils";

const KhugaTezosContext =  createContext({});

const Tezos = new TezosToolkit('https://mainnet.api.tez.ie');
Tezos.addExtension(new Tzip16Module());
const tezosOptions = {
  name: "KHUGA-MIU"
};
export const TezosWallet = new BeaconWallet(tezosOptions);

export const KhugaTezosNft = TezosNft({ TezosInstance: Tezos, TezosWallet });
export const KhugaTezosUtils = TezosUtil({ TezosInstance: Tezos, TezosWallet });